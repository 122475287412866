//#region IMPORT

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { WebAddressConstant } from "src/app/constants/webaddress.constant";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { HandshakeModel } from "src/app/models/handshake.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { CustomerService } from "src/app/services/customer.service";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "../bases/basepublic.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-signin",
		templateUrl: "./signin.component.html",
		styleUrls: ["./signin.component.sass"]
	}
)

//#endregion



//#region CLASS

export class SigninComponent extends BasePublicComponent implements OnInit
{
	//#region DECLARATION

	public _modelUserPublic: UserModel;

	public _booleanFormSignInEmail: boolean;
	public _booleanShowButton: boolean;
	public _booleanShowButtonVerify: boolean;
	public _booleanInputOTP: boolean;

	public _stringConstant = StringConstant;

	public _stringDownloadCategory: string = "";

	public _numberMinutesSendOTP: any;
	public _numberSecondsSendOTP: any;
	public _numberMinutesResendOTP: any;
	public _numberSecondsResendOTP: any;
	public _numberMinutesVerifyOTP: any;
	public _numberSecondsVerifyOTP: any;
	public _numberDateTimeNow: any;

	public _arrayStringURL: Array<string> = [];
	public _arrayNumberEmailToken: Array<number | null> = [null, null, null, null, null , null];

	public _intervalIDCountDown: any;
	public _intervalIDCountDown2: any;
	public _intervalIDCountDown3: any;

	public _elementHTMLDivButtonContainerResendOTP: HTMLElement | any;
	public _elementHTMLDivButtonContainerVerifyOTP: HTMLElement | any;
	public _elementHTMLDivInputOTP: HTMLElement | any;

	private _arrayElementInputEmailToken: NodeListOf<HTMLElement> | null = null;

	private _functionCryptography: CryptographyFunction;

	private _stringURL: string = "";

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceCustomer: CustomerService, serviceSession: SessionService, router: Router, private _routeActivated: ActivatedRoute)
	{
		super(serviceSession, router);

		this._modelUserPublic = new UserModel();

		this._functionCryptography = new CryptographyFunction();

		this._booleanFormSignInEmail = true;
		this._booleanShowButton = true;
		this._booleanShowButtonVerify = true;
		this._booleanInputOTP = false;

		this._numberMinutesSendOTP = 0;
		this._numberSecondsSendOTP = 0;
		this._numberMinutesResendOTP = 0;
		this._numberSecondsResendOTP = 0;
		this._numberMinutesVerifyOTP = 0;
		this._numberSecondsVerifyOTP = 0;
		this._numberDateTimeNow = new Date().getTime();

		this._elementHTMLDivButtonContainerResendOTP = document.getElementById("divButtonContainerResendOTP");
		this._elementHTMLDivButtonContainerVerifyOTP = document.getElementById("divButtonContainerVerifyOTP");
		this._elementHTMLDivInputOTP = document.getElementById("divInputOTP");
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this._arrayElementInputEmailToken = document.querySelectorAll(".InputToken");

		this._stringURL = atob(this.getParameterFromRouter() ?? "");

		this._elementHTMLDivButtonContainerResendOTP = document.getElementById("divButtonContainerResendOTP");
		this._elementHTMLDivButtonContainerVerifyOTP = document.getElementById("divButtonContainerVerifyOTP");
		this._elementHTMLDivInputOTP = document.getElementById("divInputOTP");
	}

	ngOnDestroy(): void
	{
		clearInterval(this._intervalIDCountDown);
		clearInterval(this._intervalIDCountDown2);
		clearInterval(this._intervalIDCountDown3);
	}

	//#endregion


	//#region WEB SERVICE

	callSelectCustomerByEmail(booleanResend: boolean, stringOTP: string | null): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.Email = this._modelUserPublic.Email;
		// modelUser.Token = this.getTokenFromURLParameter();
		modelUser.Culture = "id-ID";
		const modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;

			this._serviceCustomer.registerCustomerByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInEmail = booleanResend;
							componentCurrent.setIntervalDateTimeResendOTP(componentCurrent._intervalIDCountDown2, componentCurrent._numberDateTimeNow, componentCurrent._numberDateTimeNow + 60000);
						});
					}
					else if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_REGISTERCUSTOMERBYEMAIL_HOLDONEMINUTE)
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (stringOTP === componentCurrent._stringConstant.STRING_VALUE_SEND_OTP)
							{
								//set interval 1 minute
								componentCurrent.setIntervalDateTimeFirstSendOTP(componentCurrent._intervalIDCountDown, componentCurrent._numberDateTimeNow, componentCurrent._numberDateTimeNow + 60000);
								componentCurrent._booleanShowButton = false;
							}
							else if (stringOTP === componentCurrent._stringConstant.STRING_VALUE_RESEND_OTP)
							{
								//set interval 1 minute
								componentCurrent.setIntervalDateTimeResendOTP(componentCurrent._intervalIDCountDown2, componentCurrent._numberDateTimeNow, componentCurrent._numberDateTimeNow + 60000);
								componentCurrent._booleanShowButton = false;
							}
							componentCurrent._functionUserInterface.updateLoadingProgress();
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectCustomerByEmail(!booleanResend, stringOTP); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	private callVerifyCustomerByEmail(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.EmailToken = this.getStringEmailTokenFromForm();
		modelUser.Email = this._modelUserPublic.Email;
		let modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateEmailToken();
		}
		else
		{

		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;
			modelUser.EmailToken = this._functionCryptography.hashWithSHA256((this.getStringEmailTokenFromForm()));

			this._serviceCustomer.verifyCustomerByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === "030107")
					{
						modelResponse.MessageTitle = "Akun tidak ditemukan.";
						modelResponse.MessageContent = "Akun Anda belum terdaftar, silakan lakukan registrasi terlebih dahulu.";
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToBRINSWebsite());
					}

					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);
						modelUserResponse.modelDivision = undefined;

						if (modelUserResponse != null)
						{
							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelHandshake !== undefined)
							{
								const modelHandshake: HandshakeModel = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);

							if (componentCurrent.getParameterFromRouter() != null)
							{
								if (componentCurrent._stringDownloadCategory.includes("downloadAttachment"))
								{
									componentCurrent._router.navigate(["/downloadAttachment", componentCurrent.getParameterFromRouter()]);
								}
								else
								{
									if (componentCurrent._stringURL !== "" && componentCurrent._stringURL !== undefined && componentCurrent._stringURL !== null)
									{
										componentCurrent._router.navigate(componentCurrent._stringURL.split("/"));
									}
									else
									{
										componentCurrent._router.navigate(["/home"]);
									}
								}
							}
							else
							{

								componentCurrent._router.navigate(["/home"]);
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_VERIFYCUSTOMEREMAIL_HOLDFIVEMINUTE)
							{
								//set interval 5 minute

								componentCurrent._elementHTMLDivButtonContainerResendOTP = document.getElementById("divButtonContainerResendOTP");
								componentCurrent._elementHTMLDivButtonContainerResendOTP.style.display = "none";
								componentCurrent._elementHTMLDivButtonContainerVerifyOTP = document.getElementById("divButtonContainerVerifyOTP");
								componentCurrent._elementHTMLDivButtonContainerVerifyOTP.style.display = "block";

								componentCurrent._booleanInputOTP = true;
								componentCurrent.setIntervalDateTimeVerifyOTP(componentCurrent._intervalIDCountDown3, componentCurrent._numberDateTimeNow, componentCurrent._numberDateTimeNow + 300000);
								componentCurrent._booleanShowButtonVerify = false;
								componentCurrent._arrayNumberEmailToken = [];

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
						});
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callVerifyCustomerByEmail(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	//#endregion


	//region GETTER

	private getStringEmailTokenFromForm(): string
	{
		let stringEmailToken: string = "";

		for (const numberEmailToken of this._arrayNumberEmailToken)
		{
			if (numberEmailToken !== null && numberEmailToken !== undefined)
			{
				stringEmailToken += numberEmailToken.toString();
			}
			else
			{

			}
		}

		return stringEmailToken;
	}

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	private getTokenFromURLParamter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];
		return stringToken;
	}

	//endregion


	//#region LISTENER

	clickInputToken(numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			this._arrayElementInputEmailToken[numberIndex].focus();
			(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	listenInputToken(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputEmailToken[numberIndex - 1].focus();
					(this._arrayElementInputEmailToken[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputEmailToken.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputEmailToken[numberIndex + 1].focus();
						(this._arrayElementInputEmailToken[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyCustomerByEmail();
				}
			}
		}
		else
		{

		}
	}

	//#endregion


	//#region FUNCTION

	getTokenFromURLParameter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];

		return stringToken;
	}

	protected goToBRINSWebsite(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB, "_blank");
	}

	checkIntervalForFirstSendOTP(): void
	{

		if(this._numberMinutesSendOTP.toString() === "00" && this._numberSecondsSendOTP.toString() === "00")
		{
			this._booleanShowButton = true;
		}
		else
		{
			this._booleanShowButton = false;
		}
	}

	checkIntervalForResendOTP(): void
	{
		if(this._numberMinutesResendOTP.toString() === "00" && this._numberSecondsResendOTP.toString() === "00")
		{
			this._booleanShowButton = true;
		}
		else
		{
			this._booleanShowButton = false;
		}
	}

	checkIntervalForVerifyOTP(): void
	{
		if(this._numberMinutesVerifyOTP.toString() === "00" && this._numberSecondsVerifyOTP.toString() === "00")
		{
			this._booleanInputOTP = false;
			this._booleanShowButton = true;

			this._elementHTMLDivButtonContainerVerifyOTP = document.getElementById("divButtonContainerVerifyOTP");
			this._elementHTMLDivButtonContainerVerifyOTP.style.display = "none";

			this._elementHTMLDivButtonContainerResendOTP = document.getElementById("divButtonContainerResendOTP");
			this._elementHTMLDivButtonContainerResendOTP.style.display = "block";
		}
		else
		{
			this._booleanShowButtonVerify = false;
		}
	}

	//#endregion


	//#region SETTER

	private setIntervalDateTimeFirstSendOTP(intervalID: any, numberDateTimeDistance: number, numberExpired: number)
	{
		const componentCurrent: SigninComponent = this;

		intervalID = setInterval(() =>
		{
			numberDateTimeDistance = numberExpired - componentCurrent._numberDateTimeNow;

			componentCurrent._numberMinutesSendOTP = Math.floor((numberDateTimeDistance % (1000 * 60 * 60)) / (1000 * 60));
			if (componentCurrent._numberMinutesSendOTP.toString().length === 1)
			{
				componentCurrent._numberMinutesSendOTP = "" + "0" + componentCurrent._numberMinutesSendOTP;
			}
			else
			{

			}

			componentCurrent._numberSecondsSendOTP = Math.floor((numberDateTimeDistance % (1000 * 60)) / 1000);
			if (componentCurrent._numberSecondsSendOTP.toString().length === 1)
			{
				componentCurrent._numberSecondsSendOTP = "" + "0" + componentCurrent._numberSecondsSendOTP;
			}
			else
			{

			}

			if (componentCurrent._numberDateTimeNow > numberExpired)
			{
				componentCurrent._numberMinutesSendOTP = "00";
				componentCurrent._numberSecondsSendOTP = "00";
			}
			else
			{

			}

			componentCurrent._numberDateTimeNow += 1000;

			if (numberDateTimeDistance <= 0)
			{
				clearInterval(intervalID);
			}
			else
			{

			}

			this.checkIntervalForFirstSendOTP();

		}, NumberConstant.NUMBER_INTERVAL_ONE_SECOND);
	}

	private setIntervalDateTimeResendOTP(intervalID: any, numberDateTimeDistance: number, numberExpired: number)
	{
		const componentCurrent: SigninComponent = this;

		intervalID = setInterval(() =>
		{
			numberDateTimeDistance = numberExpired - componentCurrent._numberDateTimeNow;

			componentCurrent._numberMinutesResendOTP = Math.floor((numberDateTimeDistance % (1000 * 60 * 60)) / (1000 * 60));
			if (componentCurrent._numberMinutesResendOTP.toString().length === 1)
			{
				componentCurrent._numberMinutesResendOTP = "" + "0" + componentCurrent._numberMinutesResendOTP;
			}
			else
			{

			}

			componentCurrent._numberSecondsResendOTP = Math.floor((numberDateTimeDistance % (1000 * 60)) / 1000);
			if (componentCurrent._numberSecondsResendOTP.toString().length === 1)
			{
				componentCurrent._numberSecondsResendOTP = "" + "0" + componentCurrent._numberSecondsResendOTP;
			}
			else
			{

			}

			if (componentCurrent._numberDateTimeNow > numberExpired)
			{
				componentCurrent._numberMinutesResendOTP = "00";
				componentCurrent._numberSecondsResendOTP = "00";
			}
			else
			{

			}

			componentCurrent._numberDateTimeNow += 1000;

			if (numberDateTimeDistance <= 0)
			{
				clearInterval(intervalID);
			}
			else
			{

			}

			if((this._numberMinutesVerifyOTP.toString() !== "0" && this._numberSecondsVerifyOTP.toString() !== "0") || (this._numberMinutesVerifyOTP.toString() === "04" && this._numberSecondsVerifyOTP.toString() <= "59"))
			{
				clearInterval(intervalID);
			}
			else if(this._numberMinutesVerifyOTP.toString() === "0" && this._numberSecondsVerifyOTP.toString() === "0")
			{
				this.checkIntervalForResendOTP();
			}
			else
			{

			}

		}, NumberConstant.NUMBER_INTERVAL_ONE_SECOND);
	}

	private setIntervalDateTimeVerifyOTP(intervalID: any, numberDateTimeDistance: number, numberExpired: number)
	{
		const componentCurrent: SigninComponent = this;

		intervalID = setInterval(() =>
		{
			numberDateTimeDistance = numberExpired - componentCurrent._numberDateTimeNow;

			componentCurrent._numberMinutesVerifyOTP = Math.floor((numberDateTimeDistance % (1000 * 60 * 60)) / (1000 * 60));
			if (componentCurrent._numberMinutesVerifyOTP.toString().length === 1)
			{
				componentCurrent._numberMinutesVerifyOTP = "" + "0" + componentCurrent._numberMinutesVerifyOTP;
			}
			else
			{

			}

			componentCurrent._numberSecondsVerifyOTP = Math.floor((numberDateTimeDistance % (1000 * 60)) / 1000);
			if (componentCurrent._numberSecondsVerifyOTP.toString().length === 1)
			{
				componentCurrent._numberSecondsVerifyOTP = "" + "0" + componentCurrent._numberSecondsVerifyOTP;
			}
			else
			{

			}

			if (componentCurrent._numberDateTimeNow > numberExpired)
			{
				componentCurrent._numberMinutesVerifyOTP = "00";
				componentCurrent._numberSecondsVerifyOTP = "00";
			}
			else
			{

			}

			componentCurrent._numberDateTimeNow += 1000;

			if (numberDateTimeDistance <= 0)
			{
				clearInterval(intervalID);
				// this.callSelectCustomerByEmail(false, this._stringConstant.STRING_VALUE_RESEND_OTP);
			}
			else
			{

			}

			this.checkIntervalForVerifyOTP();

		}, NumberConstant.NUMBER_INTERVAL_ONE_SECOND);
	}

	//#endregion

}

//#endregion