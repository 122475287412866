//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { NumberConstant } from "../constants/number.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview, CurrencyValue } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ProductBicycleGalleryModel } from "./productbicyclegallery.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class ProductBicycleModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product asmik my house.
		Author: Eka Saputra.
		Created on : Wednesday, 08 June 2022. 				Updated on : Wednesday, 08 June 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:0.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;
	Disclaimer?: boolean;
	SurveyBicycleID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	ReferenceNumber?: string;
	PremiumTotalAmount?: string;
	Status?: ENUM_SPPA_STATUS;

	/* POLICYHOLDER - END */


	/* VEHICLE DETAIL - START */

	BicyclePrice?: number;
	EquipmentNonStandardPrice?: number;
	BicycleRegionCode?: string;
	BicycleRegionName?: string;
	BicycleProductionYearCode?: string;
	BicycleProductionYearName?: string;

	BicycleBrandID?: number;
	BicycleBrandCode?: string;
	BicycleBrandName?: string;
	BicycleModelID?: number;
	BicycleModelCode?: string;
	BicycleModelName?: string;
	BicycleSubModelID?: number;
	BicycleSubModelCode?: string;
	BicycleSubModelName?: string;
	BicycleTypeID?: number;
	BicycleTypeCode?: string;
	BicycleTypeName?: string;
	BicycleColorID?: number;
	BicycleColorCode?: string;
	BicycleColorName?: string;
	BicycleFrameNumber?: string;
	BicycleFunctionID?: number;
	BicycleFunctionCode?: string;
	BicycleFunctionName?: string;
	BicycleSeatCapacity?: string;
	BicycleLocationID?: number;
	BicycleLocationCode?: string;
	BicycleLocationName?: string;
	BicycleInvoice?: string;
	BicycleSerie?: string;

	AuthorizedWorkshop?: boolean;

	/* VEHICLE DETAIL - END */

	/* INSURED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelProductBicycleGallery?: Array<ProductBicycleGalleryModel>;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelProductBicycleGallery = [];
	}

	//#endregion


	//#region VALIDATION

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductBicycleGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateUpdateInsuredProduct(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk Sepeda";

		if (this.VehiclePrice == null || this.VehiclePrice === undefined)
		{
			modelResponse.MessageContent = "Nilai santunan tidak boleh kosong.";
		}
		else if (this.PostalCode == null || this.PostalCode === undefined || this.PostalCode === "")
		{
			modelResponse.MessageContent = "Kode pos tidak boleh kosong.";
		}
		else if (this.PostalCode.length < RuleConstant.NUMBER_POSTALCODE_LENGTH || this.PostalCode.length > RuleConstant.NUMBER_POSTALCODE_LENGTH)
		{
			modelResponse.MessageContent = "Kode pos harus "+RuleConstant.NUMBER_POSTALCODE_LENGTH+" digit.";
		}
		else if (this.PostalName == null || this.PostalName === undefined || this.BuildingPostalName === "")
		{
			modelResponse.MessageContent = "Kode pos tidak ditemukan.";
		}
		else if (this.VehicleBrandCode == null || this.VehicleBrandName === undefined || this.VehicleBrandName === "")
		{
			modelResponse.MessageContent = "Merek kendaraan tidak boleh kosong.";
		}
		else if (this.VehicleModelName == null || this.VehicleModelName === undefined || this.VehicleModelName === "")
		{
			modelResponse.MessageContent = "Model kendaraan tidak boleh kosong.";
		}
		else if (this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined)
		{
			modelResponse.MessageContent = "Tahun kendaraan tidak boleh kosong.";
		}
		else if (this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "")
		{
			modelResponse.MessageContent = "Awalan plat kendaraan tidak boleh kosong.";
		}
		else if (this.LicensePlateInfix == null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "")
		{
			modelResponse.MessageContent = "Nomor plat kendaraan tidak boleh kosong.";
		}
		else if (this.LicensePlateSuffix == null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "")
		{
			modelResponse.MessageContent = "Akhiran plat kendaraan tidak boleh kosong.";
		}
		else if(this.VehicleManufactureYearCode == null || this.VehicleManufactureYearCode === undefined || this.VehicleManufactureYearCode === "")
		{
			modelResponse.MessageContent = "Tahun produksi tidak boleh kosong.";
		}
		else if(this.VehicleChasisNumber == null || this.VehicleChasisNumber === undefined || this.VehicleChasisNumber === "")
		{
			modelResponse.MessageContent = "Nomor rangka kendaraan tidak boleh kosong.";
		}
		else if(this.VehicleChasisNumber.length > NumberConstant.NUMBER_VALUE_FORM_CHASISNUMBER)
		{
			modelResponse.MessageContent = "Nomor rangka kendaraan tidak boleh lebih dari "+NumberConstant.NUMBER_VALUE_FORM_CHASISNUMBER+".";
		}
		else if(this.VehicleChasisNumber.length < NumberConstant.NUMBER_VALUE_FORM_CHASISNUMBER)
		{
			modelResponse.MessageContent = "Nomor rangka kendaraan tidak boleh kurang dari "+NumberConstant.NUMBER_VALUE_FORM_CHASISNUMBER+".";
		}
		else if(this.VehicleEngineNumber == null || this.VehicleEngineNumber === undefined || this.VehicleEngineNumber === "")
		{
			modelResponse.MessageContent = "Nomor mesin kendaraan tidak boleh kosong.";
		}
		else if(this.VehicleEngineNumber.length > NumberConstant.NUMBER_VALUE_FORM_ENGINENUMBER)
		{
			modelResponse.MessageContent = "Nomor mesin kendaraan tidak boleh lebih dari "+NumberConstant.NUMBER_VALUE_FORM_ENGINENUMBER+".";
		}
		else if(this.VehicleEngineNumber.length < NumberConstant.NUMBER_VALUE_FORM_ENGINENUMBER)
		{
			modelResponse.MessageContent = "Nomor mesin kendaraan tidak boleh kurang dari "+NumberConstant.NUMBER_VALUE_FORM_ENGINENUMBER+".";
		}
		else if(this.VehicleColorName == null || this.VehicleColorName === undefined || this.VehicleColorName === "")
		{
			modelResponse.MessageContent = "Warna kendaraan tidak boleh kosong";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Sepeda");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Sepeda");

		if(this.listModelProductBicycleGallery == null || this.listModelProductBicycleGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductBicycleGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductBicyleGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductBicycleGallery of this.listModelProductBicycleGallery ?? [])
			{
				modelResponseProductBicyleGallery = modelProductBicycleGallery.validateAdd();

				if (modelResponseProductBicyleGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductBicyleGallery;
				}
				else
				{
					numberSize += modelProductBicycleGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTBICYCLE_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTBICYCLE_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductBicycleGallery?.filter(modelProductBicycleGallery => modelProductBicycleGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	validateBicyclePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Sepeda");
		const currencyValue: CurrencyValue = new CurrencyValue();

		if (this.BicyclePrice == null || this.BicyclePrice === undefined)
		{
			modelResponse.MessageContent = "Harga tidak boleh kosong.";
		}
		else if (this.BicyclePrice < NumberConstant.NUMBER_VALIDATION_PRODUCTBICYCLE_PRICEMINIMUM)
		{
			modelResponse.MessageContent = "Harga tidak boleh kurang dari Rp. "+ currencyValue.transform(NumberConstant.NUMBER_VALIDATION_PRODUCTBICYCLE_PRICEMINIMUM);
		}
		else if (this.BicyclePrice > NumberConstant.NUMBER_VALIDATION_PRODUCTBICYCLE_PRICEMAXIMUM)
		{
			modelResponse.MessageContent = "Harga tidak boleh lebih dari Rp. "+ currencyValue.transform(NumberConstant.NUMBER_VALIDATION_PRODUCTBICYCLE_PRICEMAXIMUM);
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateforUpdateBicycle(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Sepeda");

		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI);

		if (this.BicycleBrandName == null || this.BicycleBrandName === undefined || this.BicycleBrandName === "")
		{
			modelResponse.MessageContent = "Merek sepeda tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.BicycleBrandName))
		{
			modelResponse.MessageContent = "Format input Merek Sepeda mengandung karakter yang tidak diizinkan.";
			this.BicycleBrandName = undefined;
		}
		else if (this.BicycleTypeName == null || this.BicycleTypeName === undefined || this.BicycleTypeName === "")
		{
			modelResponse.MessageContent = "Tipe sepeda tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.BicycleTypeName))
		{
			modelResponse.MessageContent = "Format input Tipe Sepeda mengandung karakter yang tidak diizinkan.";
			this.BicycleTypeName = undefined;
		}
		else if (this.BicycleModelName == null || this.BicycleModelName === undefined || this.BicycleModelName === "")
		{
			modelResponse.MessageContent = "Model sepeda tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.BicycleModelName))
		{
			modelResponse.MessageContent = "Format input Model Sepeda mengandung karakter yang tidak diizinkan.";
			this.BicycleModelName = undefined;
		}
		else if (this.BicycleSerie == null || this.BicycleSerie === undefined || this.BicycleSerie === "")
		{
			modelResponse.MessageContent = "Serie sepeda tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.BicycleSerie))
		{
			modelResponse.MessageContent = "Format input Seri Sepeda mengandung karakter yang tidak diizinkan.";
			this.BicycleSerie = undefined;
		}
		else if (this.BicycleColorName == null || this.BicycleColorName === undefined || this.BicycleColorName === "")
		{
			modelResponse.MessageContent = "Warna sepeda tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.BicycleColorName))
		{
			modelResponse.MessageContent = "Format input Warna Sepeda mengandung karakter yang tidak diizinkan.";
			this.BicycleColorName = undefined;
		}
		else if (this.BicycleFrameNumber == null || this.BicycleFrameNumber === undefined || this.BicycleFrameNumber === "")
		{
			modelResponse.MessageContent = "Nomor rangka sepeda tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.BicycleFrameNumber))
		{
			modelResponse.MessageContent = "Format input Nomor Rangka Sepeda mengandung karakter yang tidak diizinkan.";
			this.BicycleFrameNumber = undefined;
		}
		else if (this.BicycleProductionYearName == null || this.BicycleProductionYearName === undefined || this.BicycleProductionYearName === "")
		{
			modelResponse.MessageContent = "Tahun produksi sepeda tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.BicycleProductionYearName))
		{
			modelResponse.MessageContent = "Format input Tahun Produksi Sepeda mengandung karakter yang tidak diizinkan.";
			this.BicycleProductionYearName = undefined;
		}
		// else if (this.BicycleInvoice == null || this.BicycleInvoice === undefined || this.BicycleInvoice === "")
		// {
		// 	this.BicycleInvoice = "0";
		// }
		else if (this.BicycleInvoice !== undefined && !regularExpression.test(this.BicycleInvoice))
		{
			modelResponse.MessageContent = "Format input Invoice mengandung karakter yang tidak diizinkan.";
			this.BicycleInvoice = undefined;
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setCoverageCodeYearly(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_MYMOTORCYCLE;
		this.Comprehensive = false;
		this.DriverPersonalAccident = false;
		this.EarthquakesTsunamisVolcanicEruptions = false;
		this.FloodTyphoonHurricaneHailLandslide = false;
		this.PassengerPersonalAccident = false;
		this.Riots = false;
		this.TerrorismAndSabotage = false;
		this.ThirdPartyLegal = false;
		this.ThirdPartyLegalValue = 0;
		this.TotalLossOnly = false;
		this.listModelBeneficiariesMyMotorcycle = undefined;
		this.listModelProductMyMotorcycleGallery = undefined;
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: ENUM_PHOTO_TYPE.KTP,
				Name : "KTP"
			},
			{
				Type: ENUM_PHOTO_TYPE.BicycleInvoice,
				Name : "Invoice Pembelian"
			},
			{
				Type: ENUM_PHOTO_TYPE.BicycleFront,
				Name : "Tampak Depan"
			},
			{
				Type: ENUM_PHOTO_TYPE.BicycleLeft,
				Name : "Tampak Kiri"
			},
			{
				Type: ENUM_PHOTO_TYPE.BicycleRight,
				Name : "Tampak Kanan"
			},
			{
				Type: ENUM_PHOTO_TYPE.BicycleBack,
				Name : "Tampak Belakang"
			},
		];

		return arrayModelGalleryModel;
	}

	// setForUpdateInsuredDamageOfBusinessPlaceForm(modelProductDamageOfBusinessPlace: ProductDamageOfBusinessPlaceModel): void
	// {
	// 	this.BuildingAddress = modelProductDamageOfBusinessPlace.BuildingAddress;
	// 	this.BuildingPostalCode = modelProductDamageOfBusinessPlace.BuildingPostalCode;
	// 	this.LocationInformation = modelProductDamageOfBusinessPlace.LocationInformation;
	// 	this.OwnershipInformation = modelProductDamageOfBusinessPlace.OwnershipInformation;
	// 	this.SumInsuredDefault = modelProductDamageOfBusinessPlace.SumInsuredDefault;
	// 	this.Token = modelProductDamageOfBusinessPlace.Token;

	// 	if(modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace != null || modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace !== undefined)
	// 	{
	// 		modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace.forEach(modelBeneficiariesDamageOfBusinessPlace =>
	// 		{
	// 			this.listModelBeneficiariesDamageOfBusinessPlace?.push(modelBeneficiariesDamageOfBusinessPlace);
	// 		});
	// 	}
	// }

	//#endregion


	//#region SETTER

	setProductBicycleSilver(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_BICYCLESILVER;
	}

	setBicycleProductionYear(): void
	{
		this.BicycleProductionYearCode = this.BicycleProductionYearName;
	}

	setProgressforDraf(): number
	{
		let numberProgress: number = 0;
		if(this.BicycleFrameNumber == null || this.BicycleFrameNumber === undefined)
		{
			numberProgress = 25;
		}
		else if(this.listModelProductBicycleGallery === undefined || this.listModelProductBicycleGallery.length === 0)
		{
			numberProgress = 50;
		}
		else if(this.listModelProductBicycleGallery !== undefined && this.listModelProductBicycleGallery.length > 0)
		{
			numberProgress = 75;
		}
		else
		{
			numberProgress = 90;
		}

		return numberProgress;
	}

	//#endregion


	//#region CLEAN

	cleanVehicleModel(): void
	{
		this.VehicleModelCode = undefined;
		this.VehicleModelName = undefined;
	}

	cleanVehicleBrand(): void
	{
		this.VehicleBrandCode = undefined;
		this.VehicleBrandName = undefined;
	}

	//#endregion


	//#region GENERATE

	generateManufactureYear(numberManufactureYearCoverage: number): Array<number>
	{
		// deepcode ignore ArrayConstructor: <please specify a reason of ignoring this>
		const arrayNumberManufactureYear: Array<number> = new Array<number>();
		const numberYear: number = new Date().getFullYear();
		const numberMinimunYear: number = numberYear - numberManufactureYearCoverage;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberManufactureYear.push(numberYearCalc);
		}

		return arrayNumberManufactureYear;
	}

	//#endregion

}
//#endregion