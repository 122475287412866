//#region IMPORT

import { Component } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ENUM_DEVICE_TYPE, ENUM_HEADER_TYPE, ENUM_PERIOD_TYPE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ContentModel } from "src/app/models/content.model";
import { HeaderContentModel } from "src/app/models/headercontent.model";
import { HeaderContentGalleryModel } from "src/app/models/headercontentgallery.model";
import { LandingContentModel } from "src/app/models/landingcontent.model";
import { ResponseModel } from "src/app/models/response.model";
import { HeaderService } from "src/app/services/header.service";
import { LandingService } from "src/app/services/landing.service";
import { SessionService } from "src/app/services/session.service";
import { LandingContentGalleryModel } from "src/app/models/landingcontentgallery.model";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-landing",
		templateUrl: "./landing.component.html",
		styleUrls: ["./landing.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class LandingComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelLandingContent: LandingContentModel;
	public _modelLandingContentRequest: LandingContentModel;
	public _modelHeaderContent: HeaderContentModel;
	public _modelHeaderContentRequest: HeaderContentModel;

	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;
	public _stringImageContentHeader: string;
	public _stringConstant = StringConstant;

	public _arrayModelProductAnnually: Array<ContentModel>;
	public _arrayModelProductMonthly: Array<ContentModel>;
	public _arrayModelProductDaily: Array<ContentModel>;
	public _arrayModelProduct: Array<ContentModel>;
	public _arrayModelProductAsmikAnnually: Array<ContentModel>;
	public _arrayModelProductAsmik: Array<ContentModel>;
	public _arraySocialMedia: Array<ContentModel>;
	public _arrayModelLandingContent: Array<LandingContentModel>;
	public _arrayModelLandingContentGallery: Array<LandingContentGalleryModel>;
	public _arrayModelHeaderContentGallery: Array<HeaderContentGalleryModel>;

	public _numberPriodeProduct: ENUM_PERIOD_TYPE;

	public _enumPeriodeProduct = ENUM_PERIOD_TYPE;

	public _booleanModalVideo: boolean;

	public _stringURLAboutBRINS: string;
	public _stringURLAboutBRINSEmbed: SafeResourceUrl;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router, private _serviceLandingContent: LandingService, private _serviceHeaderContent: HeaderService)
	{
		super(serviceSession, router);

		this._modelLandingContent = new LandingContentModel();
		this._modelLandingContentRequest = new LandingContentModel();
		this._modelHeaderContent = new HeaderContentModel();
		this._modelHeaderContentRequest = new HeaderContentModel();

		this._booleanModalVideo = false;

		// this._stringImageMainHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_landing_insurtech.jpg");
		// this._stringImageMainHeaderMobile = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_landing_insurtech_mobile.jpg");
		// this._stringImageContentHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/content_landing_insurtech.jpg");
		// this._stringURLAboutBRINS = "https://www.youtube.com/embed/dGtDlnMttH8";
		// this._stringURLAboutBRINSEmbed = "https://www.youtube.com/embed/dGtDlnMttH8";

		this._stringImageMainHeader = "";
		this._stringImageMainHeaderMobile = "";
		this._stringImageContentHeader = "";
		this._stringURLAboutBRINS = "";
		this._stringURLAboutBRINSEmbed = "";

		this._arrayModelProductAnnually =
		[
			{
				Name: "OTO",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_OTO,
				Path: "../../../assets/icons/sakina/product/icon_oto.svg"
			},
			{
				Name: "ASRI",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY,
				Path: "../../../assets/icons/sakina/product/icon_asri.svg"
			},
			{
				Name: "DIRI",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT,
				Path: "../../../assets/icons/sakina/product/icon_diri.svg"
			},
			{
				Name: "SEPEDA",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_BICYCLE,
				Path: "../../../assets/icons/sakina/product/icon_sepeda.svg"
			},
			{
				Name: "RUKO",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_SHOPHOUSE,
				Path: "../../../assets/icons/sakina/product/icon_shophouse.svg"
			},
			{
				Name: "APARTEMEN",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_APARTMENT,
				Path: "../../../assets/icons/sakina/product/icon_apartment.svg"
			},
		];
		this._arrayModelProductMonthly =
		[
			{
				Name: "OTO",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_OTO,
				Path: "../../../assets/icons/sakina/product/icon_oto_custom.svg"
			},
			{
				Name: "ASRI",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY,
				Path: "../../../assets/icons/sakina/product/icon_asri_custom.svg"
			},
			{
				Name: "DIRI",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT,
				Path: "../../../assets/icons/sakina/product/icon_diri_custom.svg"
			}
		];
		this._arrayModelProductDaily =
		[
			{
				Name: "OTO",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_OTO,
				Path: "../../../assets/icons/sakina/product/icon_oto_daily.svg"
			},
			{
				Name: "DIRI",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT,
				Path: "../../../assets/icons/sakina/product/icon_diri_daily.svg"
			}
		];
		this._arrayModelProductAsmikAnnually =
		[
			{
				Name: "ASMIK MOTORKU",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE,
				Path: "../../../assets/icons/sakina/product/icon_asmik_motorku.svg"
			},
			{
				Name: "ASMIK RUMAHKU",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_MYHOUSE,
				Path: "../../../assets/icons/sakina/product/icon_asmik_rumahku.svg"
			},
			{
				Name: "ASMIK KTU",
				Code: this._stringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE,
				Path: "../../../assets/icons/sakina/product/icon_asmik_ktu.svg"
			},
		];
		this._arraySocialMedia =
		[
			{
				Name: "Instagram",
				Path: "../../../assets/logos/sakina/logo_instagram.svg",
				Link: "https://www.instagram.com/brinsurtech"
			},
			{
				Name: "Facebook",
				Path: "../../../assets/logos/sakina/logo_facebook.svg",
				Link: "https://www.facebook.com/bri.insurance"
			},
			{
				Name: "Youtube",
				Path: "../../../assets/logos/sakina/logo_youtube.svg",
				Link: "https://www.youtube.com/channel/UCdwtS_j2DKMiVSDaD2SCLwQ/featured"
			},
			{
				Name: "Twitter",
				Path: "../../../assets/logos/sakina/logo_twitter.svg",
				Link: "https://twitter.com/brins_insurance"
			},
			{
				Name: "TikTok",
				Path: "../../../assets/logos/sakina/logo_tiktok.svg",
				Link: "https://www.tiktok.com/@brinsurtech"
			},
			{
				Name: "LinkedIn",
				Path: "../../../assets/logos/sakina/logo_linkedin.svg",
				Link: "https://id.linkedin.com/company/pt-bri-asuransi-indonesia"
			},
		];
		this._arrayModelProduct = this._arrayModelProductAnnually;
		this._arrayModelProductAsmik = this._arrayModelProductAsmikAnnually;
		this._arrayModelLandingContent = [];
		this._arrayModelLandingContentGallery = [];
		this._arrayModelHeaderContentGallery = [];

		this._numberPriodeProduct = ENUM_PERIOD_TYPE.Yearly;
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		if (localStorage.getItem("ProductAsri"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductAsri");
			localStorage.removeItem("DiscountAsri");
			localStorage.removeItem("UserPolicyHolderAsri");
		}
		else
		{

		}

		if (localStorage.getItem("ProductOto"))
		{
			/* Remove request model oto from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductOto");
		}
		else
		{

		}

		if (localStorage.getItem("ProductPersonalAccident"))
		{
			/* Remove request model personal accident from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("DiscountPersonalAccident");
			localStorage.removeItem("ProductPersonalAccident");
			localStorage.removeItem("UserPolicyHolderPersonalAccident");
		}
		else
		{

		}

		if (localStorage.getItem("ProductBicycle"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("DiscountBicycle");
			localStorage.removeItem("ProductBicycle");
			localStorage.removeItem("UserPolicyHolderBicycle");
		}
		else
		{

		}

		if (localStorage.getItem("ProductMyHouse"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductMyHouse");
			localStorage.removeItem("UserPolicyHolderMyHouse");
		}
		else
		{

		}

		if (localStorage.getItem("ProductDamageOfBusinessPlace"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductDamageOfBusinessPlace");
			localStorage.removeItem("UserPolicyHolderDamageOfBusinessPlace");
		}
		else
		{

		}

		if (localStorage.getItem("ProductMyMotorcycle"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductMyMotorcycle");
			localStorage.removeItem("UserPolicyHolderMyMotorcycle");
		}
		else
		{

		}

		if (localStorage.getItem("ProductShophouse"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductShophouse");
			localStorage.removeItem("UserPolicyHolderShophouse");
		}
		else
		{

		}

		if (localStorage.getItem("ProductApartment"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductApartment");
			localStorage.removeItem("UserPolicyHolderApartment");
		}
		else
		{

		}

		if(localStorage.getItem("ProductGeneral"))
		{
			/* Remove request model general from localstorage */
			localStorage.removeItem("ProductGeneral");
		}
		else
		{

		}

		//call service
		this.callSelectHeaderContentByAttributesForCustomer(this);
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region WEB SERVICE

	public callSelectHeaderContentByAttributesForCustomer(componentCurrent: LandingComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelHeaderContentRequest.DeviceType = ENUM_DEVICE_TYPE.Website;
		componentCurrent._modelHeaderContentRequest.HeaderType = ENUM_HEADER_TYPE.Landing;

		componentCurrent._serviceHeaderContent.selectHeaderContentByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelHeaderContent.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelHeaderContent.listModelHeaderGallery !== undefined)
						{
							for (const modelHeaderContentGallery of componentCurrent._modelHeaderContent.listModelHeaderGallery)
							{
								modelHeaderContentGallery.URL = componentCurrent._pipeConvertToBroadcastURL.transform(modelHeaderContentGallery.URL);
								componentCurrent._stringImageMainHeader = modelHeaderContentGallery.URL;
								componentCurrent._stringImageMainHeaderMobile = modelHeaderContentGallery.URL;
							}
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent.callSelectLandingContentByAttributesForCustomer(componentCurrent);
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHeaderContentByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelHeaderContentRequest);
	}

	public callSelectLandingContentByAttributesForCustomer(componentCurrent: LandingComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._serviceLandingContent.selectLandingContentByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._arrayModelLandingContent = [];

						let arrayModelLandingContentTemp: Array<LandingContentModel> = [];
						arrayModelLandingContentTemp = JSON.parse(modelResponse.Data);

						let modelLandingContent: LandingContentModel;
						for(const modelLandingContentTemp of arrayModelLandingContentTemp)
						{
							modelLandingContent = new LandingContentModel();
							modelLandingContent.setModelFromObject(modelLandingContentTemp);

							if (modelLandingContent.URLVideo)
							{
								componentCurrent._stringURLAboutBRINS = modelLandingContent.URLVideo;
								componentCurrent._stringURLAboutBRINSEmbed = modelLandingContent.URLVideo;
							}

							if (modelLandingContent.listModelLandingGallery !== undefined)
							{
								for (const modelLandingContentGallery of modelLandingContent.listModelLandingGallery)
								{
									modelLandingContentGallery.URL = componentCurrent._pipeConvertToBroadcastURL.transform(modelLandingContentGallery.URL);
									componentCurrent._stringImageContentHeader = modelLandingContentGallery.URL;
								}
							}

							componentCurrent._arrayModelLandingContent.push(modelLandingContent);
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectLandingContentByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelLandingContentRequest);
	}

	//#endregion


	//#region FUNCTION

	public showProductPeriode(enumProductType: ENUM_PERIOD_TYPE): boolean
	{
		if(enumProductType === this._numberPriodeProduct)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	public setProductPeriode(enumProductType: ENUM_PERIOD_TYPE): void
	{
		if(enumProductType !== undefined)
		{
			this._numberPriodeProduct = enumProductType;
			this._arrayModelProduct = [];
		}

		if(enumProductType === ENUM_PERIOD_TYPE.Daily)
		{
			this._arrayModelProduct = this._arrayModelProductDaily;
			this._arrayModelProductAsmik = [];
		}
		else if(enumProductType === ENUM_PERIOD_TYPE.Monthly)
		{
			this._arrayModelProduct = this._arrayModelProductMonthly;
			this._arrayModelProductAsmik = [];
		}
		else if(enumProductType === ENUM_PERIOD_TYPE.Yearly)
		{
			this._arrayModelProduct = this._arrayModelProductAnnually;
			this._arrayModelProductAsmik = this._arrayModelProductAsmikAnnually;
		}
		else
		{

		}
	}

	public setBooleanVideo(booleanModalVideo: boolean): void
	{
		this._booleanModalVideo = booleanModalVideo;
		if(this._booleanModalVideo)
		{
			this._functionUserInterface.showCurtainLoading();
			this._stringURLAboutBRINSEmbed = this._stringURLAboutBRINS+this._stringConstant.STRING_IFRAME_EMBEDVIDEO_AUTOPLAY;
		}
		else
		{
			this._functionUserInterface.hideCurtainLoading();
			this._stringURLAboutBRINSEmbed = this._stringURLAboutBRINS+this._stringConstant.STRING_IFRAME_EMBEDVIDEO_PAUSE;
		}
	}

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}

	getImageBrinsDigital(): any
	{
		if(this._stringImageContentHeader === null && this._stringImageContentHeader === undefined && this._stringImageContentHeader === "")
		{
			return {};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageContentHeader + ")"};
		}
	}

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	showImageFromURL(modelLandingContent: LandingContentModel): string
	{
		if((modelLandingContent.listModelLandingGallery != null || modelLandingContent.listModelLandingGallery !== undefined) && modelLandingContent.listModelLandingGallery?.length !== 0)
		{

			const modelLandingContentGalleryModel: LandingContentGalleryModel = modelLandingContent.listModelLandingGallery[0];
			if(modelLandingContentGalleryModel.URL)
			{
				return this._pipeConvertToBroadcastURL.transform(modelLandingContentGalleryModel.URL);
			}
			else
			{
				return this._stringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return this._stringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	//#endregion


	//#region NAVIGATION

	goToPackage(stringProductCode: string, stringProductName: string): void
	{
		if(this._numberPriodeProduct === this._enumPeriodeProduct.Yearly)
		{
			if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_OTO)
			{
				this._router.navigate(["home", "product", "oto", "yearly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY)
			{
				this._router.navigate(["home", "product", "asri", "yearly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
			{
				this._router.navigate(["home", "product", "personalaccident", "yearly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_BICYCLE)
			{
				this._router.navigate(["home", "product", "bicycle", "yearly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_SHOPHOUSE && stringProductName === this._stringConstant.STRING_PRODUCT_NAME_SHOPHOUSE)
			{
				this._router.navigate(["home", "product", "shophouse", "yearly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_APARTMENT && stringProductName === this._stringConstant.STRING_PRODUCT_NAME_APARTMENT)
			{
				this._router.navigate(["home", "product", "apartment", "yearly"]);
			}
		}
		else if(this._numberPriodeProduct === this._enumPeriodeProduct.Monthly)
		{
			if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_OTO)
			{
				this._router.navigate(["home", "product", "oto", "monthly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY)
			{
				this._router.navigate(["home", "product", "asri", "monthly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
			{
				this._router.navigate(["home", "product", "personalaccident", "monthly"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_BICYCLE)
			{
				this._router.navigate(["home", "product", "bicycle", "monthly"]);
			}
		}
		else if(this._numberPriodeProduct === this._enumPeriodeProduct.Daily)
		{
			if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_OTO)
			{
				this._router.navigate(["home", "product", "oto", "daily"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
			{
				this._router.navigate(["home", "product", "personalaccident", "daily"]);
			}
			else if(stringProductCode === this._stringConstant.STRING_VALUE_PRODUCT_BICYCLE)
			{
				this._router.navigate(["home", "product", "bicycle", "daily"]);
			}
		}
		else
		{

		}
	}

	goToSignIn(): void
	{
		this._router.navigate(["signin"]);
	}

	goToSNS(stringURL: string): void
	{
		window.open(stringURL);
	}

	goToProductAsmik(stringProductCode: string): void
	{
		if(stringProductCode === StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE)
		{
			this._router.navigate(["home", "productasmik", "damageofbusinessplace", "yearly"]);
		}
		else if(stringProductCode === StringConstant.STRING_VALUE_PRODUCT_MYHOUSE)
		{
			this._router.navigate(["home", "productasmik", "myhouse", "yearly"]);
		}
		else if(stringProductCode === StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE)
		{
			this._router.navigate(["home", "productasmik", "mymotorcycle", "yearly"]);
		}
		else
		{

		}
	}

	//#endregion

}
