//#region IMPORT

import { NumberConstant } from "../constants/number.constant";
import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_PERIOD_TYPE, ENUM_PROJECT_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";
import { LeadModel } from "./lead.model";
import { ProductAsriModel } from "./productasri.model";
import { ProductOtoModel } from "./productoto.model";
import { ProductPersonalAccidentModel } from "./productpersonalaccident.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";
import { VirtualAccountModel } from "./virtualaccount.model";
import { ThirdPartyPaymentModel } from "./thirdpartypayment.model";
import { ProductDamageOfBusinessPlaceModel } from "./productdamageofbusinessplace.model";
import { ProductMyHouseModel } from "./productmyhouse.model";
import { ProductMyMotorcycleModel } from "./productmymotorcycle.model";
import { ProductBicycleModel } from "./productbicycle.model";
import { ProductShophouseModel } from "./productshophouse.model";
import { ProductApartmentModel } from "./productapartment.model";

//#endregion


//#region CLASS

export class ProductGeneralModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for product general model, used for contain general information to cover specific product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.		    	Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	ReferencePolicyNumber?: string;
	PolicyNumber?: string;
	PolicyID?: number;
	Status?: ENUM_SPPA_STATUS;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	PolicyPeriod?: number;
	PolicyPeriodType?: ENUM_PERIOD_TYPE;
	LeadToken?: string;

	CustomerID?: number;
	RelationshipManagerID?: number;
	Token?: string;

	Disclaimer?: boolean;
	Note?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;
	Progress?: number;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;
	PolicyholderBranchID?: number;
	PolicyholderBranchCode?: string;
	PolicyholderBranchName?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderProvinceID?: number;
	PolicyholderCityID?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: string;
	PolicyholderPostalName?: string;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;
	PolicyholderPotentialAmount?: number;

	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: number;

	ReferralCode?: string;

	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: string;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommmisionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;
	PremiumTotalAdministrationAmount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	CoverageCode?: string;
	PaymentAccountID?: number;

	VirtualAccountID?: number;
	CreditCardNumber?: string;
	CreditCardExpMonth?: string;
	CreditCardExpYear?: string;
	CreditCardCVV?: string;

	/* PAYMENT - END */

	/* TRACKING - START */

	SavedBy?: number;
	SavedOn?: Date;
	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;

	/* TRACKING - END */

	/* CARE TECH - START */

	ANO?: string;
	INO?: string;
	SourceID?: string;

	/* CARE TECH - END */

	/* NOT MAPPED - START */

	modelUser?: UserModel;
	modelProductAsri?: ProductAsriModel;
	modelProductOto?: ProductOtoModel;
	modelProductPersonalAccident?: ProductPersonalAccidentModel;
	modelProductDamageOfBusinessPlace?: ProductDamageOfBusinessPlaceModel;
	modelProductMyHouse?: ProductMyHouseModel;
	modelProductMyMotorcycle?: ProductMyMotorcycleModel;
	modelProductBicycle?: ProductBicycleModel;
	modelProductShophouse?: ProductShophouseModel;
	modelProductApartment?: ProductApartmentModel;
	modelLead?: LeadModel;
	modelVirtualAccount?: VirtualAccountModel;
	modelThirdPartyPayment?: ThirdPartyPaymentModel;

	stringBirthDate?: string;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelProductAsri = new ProductAsriModel();
		this.modelProductOto = new ProductOtoModel();
		this.modelProductPersonalAccident = new ProductPersonalAccidentModel();
		this.modelProductBicycle = new ProductBicycleModel();
		this.modelProductMyHouse = new ProductMyHouseModel();
		this.modelProductMyMotorcycle = new ProductMyMotorcycleModel();
		this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
		this.modelProductShophouse = new ProductShophouseModel();
		this.modelProductApartment = new ProductApartmentModel();
	}

	//#endregion


	//#region FUNCTION

	getClearCalculate(stringCurrency: string = StringConstant.STRING_CURRENCY_IDR_CODE): void
	{
		this.PremiumAmount = 0;
		this.AdministrationAmount = 0;
		this.StampAmount = 0;
		this.PremiumTotalAmount = 0;
		this.DiscountAmount = 0;
		this.PremiumCurrency = stringCurrency;
		this.DiscountCurrency = stringCurrency;
	}

	getName(): string
	{
		if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined || this.PolicyholderFirstName === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = this.PolicyholderFirstName;

			if (this.PolicyholderMiddleName == null || this.PolicyholderMiddleName === undefined || this.PolicyholderMiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.PolicyholderMiddleName;
			}

			if (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined || this.PolicyholderLastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.PolicyholderLastName;
			}

			return stringName;
		}
	}

	premiumTotalAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined && this.PremiumAmount !== undefined && this.DiscountAmount !== undefined)
		{
			this.PremiumTotalAmount = Math.round((this.AdministrationAmount + this.StampAmount + this.PremiumAmount) - this.DiscountAmount);
		}
		else
		{

		}
	}

	premiumTotalAdministrationAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined)
		{
			this.PremiumTotalAdministrationAmount = this.AdministrationAmount + this.StampAmount;
		}
		else
		{

		}
	}

	//#endregion


	//#region VALIDATE

	validateForProduct(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.VehicleBrandCode == null || this.VehicleBrandCode === undefined || this.VehicleBrandCode === "")
		{
			modelResponse.MessageContent = "Model Vehicle Code can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			return modelResponse;
		}
	}

	validatePolicyholderEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Masukkan email yang valid.";
		}

		return modelResponse;
	}

	validateForASRICalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductAsri == null || this.modelProductAsri === undefined)
		{
			modelResponse.MessageContent = "Produk ASRI tidak boleh kosong. Silakan kontak pengembang!";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForOTOCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductOto == null || this.modelProductOto === undefined)
		{
			modelResponse.MessageContent = "Produk OTO tidak boleh kosong. Silakan kontak pengembang!";
		}
		else if(this.modelProductOto.CoverageCode == null || this.modelProductOto.CoverageCode === "" || this.modelProductOto.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong. Silakan kontak pengembang!";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForPersonalAccidentCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductPersonalAccident == null)
		{
			modelResponse.MessageContent = "Produk diri tidak boleh kosong.";
		}
		else if(this.modelProductPersonalAccident.CoverageCode == null || this.modelProductPersonalAccident.CoverageCode === "" || this.modelProductPersonalAccident.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		}
		else if((this.modelProductPersonalAccident.SumInsuredDefault !== undefined && this.modelProductPersonalAccident.SumInsuredDefault < 10000000) || (this.modelProductPersonalAccident.SumInsuredDefault !== undefined && this.modelProductPersonalAccident.SumInsuredDefault > 1000000000))
		{
			modelResponse.MessageContent = "Nilai pertanggungan tidak boleh diluar jarak yang ditentukan.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForBicycleCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Sepeda");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductBicycle == null)
		{
			modelResponse.MessageContent = "Produk Sepeda tidak boleh kosong.";
		}
		else if(this.modelProductBicycle.CoverageCode == null || this.modelProductBicycle.CoverageCode === "" || this.modelProductBicycle.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForShophouseCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductShophouse?.CoverageCode == null || this.modelProductShophouse?.CoverageCode === "" || this.modelProductShophouse?.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		}
		else if(this.modelProductShophouse == null)
		{
			modelResponse.MessageContent = "Produk Ruko tidak boleh kosong.";
		}
		else if(this.modelProductShophouse?.ObjectOccupationCode == null || this.modelProductShophouse?.ObjectOccupationCode === undefined
			|| this.modelProductShophouse?.ObjectOccupationName == null || this.modelProductShophouse?.ObjectOccupationName === undefined
		)
		{
			modelResponse.MessageContent = "Okupasi Objek tidak boleh kosong";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForApartmentCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductApartment?.CoverageCode == null || this.modelProductApartment?.CoverageCode === "" || this.modelProductApartment?.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		}
		else if(this.modelProductApartment == null)
		{
			modelResponse.MessageContent = "Produk Apartemen tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForDamageOfBusinessPlaceCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk KTU");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductDamageOfBusinessPlace == null)
		{
			modelResponse.MessageContent = "Produk KTU tidak boleh kosong.";
		}
		else if(this.modelProductDamageOfBusinessPlace.CoverageCode == null || this.modelProductDamageOfBusinessPlace.CoverageCode === "" || this.modelProductDamageOfBusinessPlace.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateFoMyHouseCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Rumahku");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductMyHouse == null)
		{
			modelResponse.MessageContent = "Produk Rumahku tidak boleh kosong.";
		}
		else if(this.modelProductMyHouse.CoverageCode == null || this.modelProductMyHouse.CoverageCode === "" || this.modelProductMyHouse.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateFoMyMotorcycleCalculatePremium(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Motorku");

		if(this.ProductCode == null || this.ProductCode === undefined || this.ProductCode === "")
		{
			modelResponse.MessageContent = "Kode produk tidak boleh kosong.";
		}
		else if(this.modelProductMyMotorcycle == null)
		{
			modelResponse.MessageContent = "Produk Motorku tidak boleh kosong.";
		}
		else if(this.modelProductMyMotorcycle.CoverageCode == null || this.modelProductMyMotorcycle.CoverageCode === "" || this.modelProductMyMotorcycle.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateforPeriode(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk";

		if(this.PolicyPeriod == null || this.PolicyPeriod === undefined)
		{
			modelResponse.MessageContent = "Periode tidak boleh kosong.";
			return modelResponse;
		}
		else if(this.PolicyPeriod < NumberConstant.NUMBER_COMPARE_VALUE_ONE )
		{
			modelResponse.MessageContent = "Periode tidak boleh kurang dari "+NumberConstant.NUMBER_COMPARE_VALUE_ONE;
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			return modelResponse;
		}
	}

	validateforUpdateInsuredProductDamageOfBusinessPlace(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk KTU";

		if (this.PolicyStartDate == null || this.PolicyStartDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal mulai asuransi tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateforUpdateInsuredProductMyHouse(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk Rumahku";

		if (this.PolicyStartDate == null || this.PolicyStartDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal mulai asuransi tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateforUpdateInsuredProductMyMotorcycle(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk Motorku";

		if (this.PolicyStartDate == null || this.PolicyStartDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal mulai asuransi tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	//#endregion


	//#region CLEAN

	cleanForProductASRIYearly(): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
		if (this.modelProductAsri !== null && this.modelProductAsri !== undefined)
		{
			this.modelProductAsri.EarthquakesTsunamisVolcanicEruptions = false;
			this.modelProductAsri.FloodsHurricanesStormHailLandslides = false;
			this.modelProductAsri.Riots = false;
			this.modelProductAsri.TerrorismAndSabotage = false;
		}
	}

	cleanForProductPAYearly(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductOto = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductOTOYearly(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
		// this.Comprehensive = false;
		this.DriverPersonalAccident = false;
		this.EarthquakesTsunamisVolcanicEruptions = false;
		this.FloodTyphoonHurricaneHailLandslide = false;
		this.PassengerPersonalAccident = false;
		this.Riots = false;
		this.TerrorismAndSabotage = false;
		this.ThirdPartyLegal = false;
		// this.TotalLossOnly = false;
		this.ThirdPartyLegalValue = 0.0;
	}

	cleanForProductOTO(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductPersonalAccident(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductOto = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductASRI(): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductDamageOfBusinessPlace(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductMyHouse(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductMyMotorcycle(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductBicycle(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductShophouse(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductApartment = undefined;
	}

	cleanForProductShophouseWithAdditional(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductApartment = undefined;

		if ((this.modelProductShophouse !== undefined)
			&& ((this.modelProductShophouse.EarthquakeTsunamiVolcanicEruption === null || this.modelProductShophouse.EarthquakeTsunamiVolcanicEruption === undefined)
			&& (this.modelProductShophouse.FloodTyphoonStormWaterDamage === null || this.modelProductShophouse.FloodTyphoonStormWaterDamage === undefined)
			&& (this.modelProductShophouse.Riots === null || this.modelProductShophouse.Riots === undefined)
			&& (this.modelProductShophouse.DamageCausedByVehicles === null || this.modelProductShophouse.DamageCausedByVehicles === undefined)))
		{
			this.modelProductShophouse.EarthquakeTsunamiVolcanicEruption = false;
			this.modelProductShophouse.FloodTyphoonStormWaterDamage = false;
			this.modelProductShophouse.Riots = false;
			this.modelProductShophouse.DamageCausedByVehicles = false;
		}
	}

	cleanForProductApartment(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
	}

	cleanForProductApartmentWithAdditional(): void
	{
		this.modelProductOto = undefined;
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;

		if ((this.modelProductApartment !== undefined)
			&& ((this.modelProductApartment.EarthquakeTsunamiVolcanicEruption === null || this.modelProductApartment.EarthquakeTsunamiVolcanicEruption === undefined)
			&& (this.modelProductApartment.FloodTyphoonStormWaterDamage === null || this.modelProductApartment.FloodTyphoonStormWaterDamage === undefined)
			&& (this.modelProductApartment.Riots === null || this.modelProductApartment.Riots === undefined)
			&& (this.modelProductApartment.DamageCausedByVehicles === null || this.modelProductApartment.DamageCausedByVehicles === undefined)))
		{
			this.modelProductApartment.EarthquakeTsunamiVolcanicEruption = false;
			this.modelProductApartment.FloodTyphoonStormWaterDamage = false;
			this.modelProductApartment.Riots = false;
			this.modelProductApartment.DamageCausedByVehicles = false;
		}
	}

	cleanForGeneratePayment(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;
		this.modelProductShophouse = undefined;
		this.modelProductApartment = undefined;
	}

	//#endregion


	//#region SET

	setProductCodeOto(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_OTO;
	}

	setProductOtoDaily(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY;
		this.ProductName = StringConstant.STRING_TITLE_OTO;
		this.PolicyPeriodType = NumberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this.PolicyStartDate = new Date();
		this.DiscountCurrency = "IDR";
	}

	setProductOtoMonthly(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductBicycle = undefined;
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY;
		this.ProductName = StringConstant.STRING_TITLE_OTO;
		this.PolicyPeriodType = NumberConstant.NUMBER_COMPARE_VALUE_ONE;
		this.PolicyStartDate = new Date();
		this.DiscountCurrency = "IDR";
	}

	setProductPAYearly(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT;
		this.ProductName = StringConstant.STRING_TITLE_PERSONALACCIDENT;
	}

	setProductBicycleYearly(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_BICYCLE;
		this.ProductName = StringConstant.STRING_TITLE_BICYCLE;
	}

	setProductPersonalAccidentDaily(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT;
		this.ProductName = StringConstant.STRING_TITLE_PERSONALACCIDENT;
		this.PolicyPeriodType = ENUM_PERIOD_TYPE.Daily;
		this.PolicyPeriod = NumberConstant.NUMBER_COMPARE_VALUE_ONE;
		this.PolicyStartDate = new Date();
		this.DiscountCurrency = "IDR";
	}

	setProductPersonalAccidentMonthly(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT;
		this.ProductName = StringConstant.STRING_TITLE_PERSONALACCIDENT;
		this.PolicyPeriodType = NumberConstant.NUMBER_COMPARE_VALUE_ONE;
		this.PolicyPeriod = NumberConstant.NUMBER_COMPARE_VALUE_ONE;
		this.PolicyStartDate = new Date();
		this.DiscountCurrency = "IDR";
	}

	setProductASMIKDamageOfBusinessPlace(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE;
		this.ProductName = StringConstant.STRING_TITLE_DAMAGEOFBUSINESSPLACE;
	}

	setProductASMIKMyHouse(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_MYHOUSE;
		this.ProductName = StringConstant.STRING_TITLE_MYHOUSE;
		this.PremiumTotalAmount = 50000;
		this.modelProductMyHouse?.setCoverageCodeYearly();
	}

	setProductASMIKMyMotorcycle(): void
	{
		this.ProductCode = StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE;
		this.ProductName = StringConstant.STRING_TITLE_MYMOTORCYCLE;
		this.StampAmount = 0;
	}


	//#endregion


	//#region SETTER

	setProductGeneralPolicyHolderFromUserModelSignIn(modelUser: UserModel): void
	{
		this.PolicyholderAddress = modelUser.Address;
		this.PolicyholderBirthDate = modelUser.BirthDate;
		this.PolicyholderBirthPlace = modelUser.BirthPlace;
		this.PolicyholderEmail = modelUser.Email;
		this.PolicyholderFirstName = modelUser.FirstName;
		this.PolicyholderLastName = modelUser.LastName;
		this.PolicyholderMiddleName = modelUser.MiddleName;
		this.PolicyholderMobilePhone = modelUser.MobilePhone;
		this.PolicyholderGender = modelUser.Gender;
		this.PolicyholderIdentificationNumber = modelUser.IdentificationNumber;
		this.PolicyholderIdentificationType = modelUser.IdentificationType;
		this.PolicyholderPotentialAmount = this.PremiumTotalAmount;

		if (modelUser.modelBranch !== undefined)
		{
			this.PolicyholderBranchID = modelUser.modelBranch.ID;
			this.PolicyholderBranchCode = modelUser.modelBranch.Code;
			this.PolicyholderBranchName = modelUser.modelBranch.Name;
		}
		else
		{

		}

	}

	setModelProductAsri(): void
	{
		const stringModelProductAsri: string = JSON.stringify(this.modelProductAsri);
		this.modelProductAsri = new ProductAsriModel();
		this.modelProductAsri.setModelFromString(stringModelProductAsri);
	}

	setModelProductOto(): void
	{
		const stringModelProductOto: string = JSON.stringify(this.modelProductOto);
		this.modelProductOto = new ProductOtoModel();
		this.modelProductOto.setModelFromString(stringModelProductOto);
	}

	setModelProductPersonalAccident(): void
	{
		const stringModelProductPersonalAccident: string = JSON.stringify(this.modelProductPersonalAccident);
		this.modelProductPersonalAccident = new ProductPersonalAccidentModel();
		this.modelProductPersonalAccident.setModelFromString(stringModelProductPersonalAccident);
	}

	setModelProductBicycle(): void
	{
		const stringModelProductBicycle: string = JSON.stringify(this.modelProductBicycle);
		this.modelProductBicycle = new ProductBicycleModel();
		this.modelProductBicycle.setModelFromString(stringModelProductBicycle);
	}

	setModelProductShophouse(): void
	{
		const stringModelProductShophouse: string = JSON.stringify(this.modelProductShophouse);
		this.modelProductShophouse = new ProductShophouseModel();
		this.modelProductShophouse.setModelFromString(stringModelProductShophouse);
	}

	setModelProductApartment(): void
	{
		const stringModelProductApartment: string = JSON.stringify(this.modelProductApartment);
		this.modelProductApartment = new ProductApartmentModel();
		this.modelProductApartment.setModelFromString(stringModelProductApartment);
	}

	setModelProductDamageOfBusinessPlace(): void
	{
		const stringModelProductDamageOfBusinessPlace: string = JSON.stringify(this.modelProductDamageOfBusinessPlace);
		this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
		this.modelProductDamageOfBusinessPlace.setModelFromString(stringModelProductDamageOfBusinessPlace);
	}

	setModelProductMyHouse(): void
	{
		const stringModelProductMyHouse: string = JSON.stringify(this.modelProductMyHouse);
		this.modelProductMyHouse = new ProductMyHouseModel();
		this.modelProductMyHouse.setModelFromString(stringModelProductMyHouse);
	}

	setModelProductMyMotorcycle(): void
	{
		const stringModelProductMyMotorcycle: string = JSON.stringify(this.modelProductMyMotorcycle);
		this.modelProductMyMotorcycle = new ProductMyMotorcycleModel();
		this.modelProductMyMotorcycle.setModelFromString(stringModelProductMyMotorcycle);
	}

	setModelVirtualAccount(): void
	{
		const stringModelVirtualAccount: string = JSON.stringify(this.modelVirtualAccount);
		this.modelVirtualAccount = new VirtualAccountModel();
		this.modelVirtualAccount.setModelFromString(stringModelVirtualAccount);
	}

	setModelThirdPartyPayment(): void
	{
		const stringModelThirdPartyPayment: string = JSON.stringify(this.modelThirdPartyPayment);
		this.modelThirdPartyPayment = new ThirdPartyPaymentModel();
		this.modelThirdPartyPayment.setModelFromString(stringModelThirdPartyPayment);
	}

	setForSaveAsriSPPA(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;

		this.PolicyholderEmail = modelProductGeneral.PolicyholderEmail;
		this.ReferralCode = modelProductGeneral.ReferralCode;
		this.Token = modelProductGeneral.Token;

		if(modelProductGeneral.modelProductAsri !== null && modelProductGeneral.modelProductAsri !== undefined)
		{
			this.modelProductAsri = new ProductAsriModel();
			this.modelProductAsri.BuildingAddress = modelProductGeneral.modelProductAsri.BuildingAddress;
			this.modelProductAsri.BuildingCityName = modelProductGeneral.modelProductAsri.BuildingCityName;
			this.modelProductAsri.BuildingNearMarket = modelProductGeneral.modelProductAsri.BuildingNearMarket;
			this.modelProductAsri.BuildingPostalCode = modelProductGeneral.modelProductAsri.BuildingPostalCode;
			this.modelProductAsri.BuildingPostalID = modelProductGeneral.modelProductAsri.BuildingPostalID;
			this.modelProductAsri.BuildingPostalName = modelProductGeneral.modelProductAsri.BuildingPostalName;
			this.modelProductAsri.BuildingProvinceName = modelProductGeneral.modelProductAsri.BuildingProvinceName;
			this.modelProductAsri.BuildingWorkPlace = modelProductGeneral.modelProductAsri.BuildingWorkPlace;
			this.modelProductAsri.EarthquakesTsunamisVolcanicEruptions = modelProductGeneral.modelProductAsri.EarthquakesTsunamisVolcanicEruptions;
			this.modelProductAsri.FloodsHurricanesStormHailLandslides = modelProductGeneral.modelProductAsri.FloodsHurricanesStormHailLandslides;
			this.modelProductAsri.Riots = modelProductGeneral.modelProductAsri.Riots;
			this.modelProductAsri.TerrorismAndSabotage = modelProductGeneral.modelProductAsri.TerrorismAndSabotage;
			this.modelProductAsri.Token = modelProductGeneral.modelProductAsri.Token;
		}
		else
		{

		}
	}

	setForSaveProductDamageOfBusinessPlaceSPPA(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;

		this.Token = modelProductGeneral.Token;
		this.PolicyStartDate = modelProductGeneral.PolicyStartDate;
		this.Status = 1;
		this.PolicyholderEmail = modelProductGeneral.PolicyholderEmail;

		if(modelProductGeneral.ReferralCode == null || modelProductGeneral.ReferralCode === undefined || modelProductGeneral.ReferralCode === "")
		{
			this.ReferralCode = "";
		}
		else
		{
			this.ReferralCode = modelProductGeneral.ReferralCode;
		}

		if(modelProductGeneral.modelProductDamageOfBusinessPlace != null || modelProductGeneral.modelProductDamageOfBusinessPlace !== undefined )
		{
			this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
			this.modelProductDamageOfBusinessPlace.BuildingAddress = modelProductGeneral.modelProductDamageOfBusinessPlace?.BuildingAddress;
			this.modelProductDamageOfBusinessPlace.BuildingPostalCode = modelProductGeneral.modelProductDamageOfBusinessPlace?.BuildingPostalCode;
			this.modelProductDamageOfBusinessPlace.LocationInformation = modelProductGeneral.modelProductDamageOfBusinessPlace?.LocationInformation;
			this.modelProductDamageOfBusinessPlace.OwnershipInformation = modelProductGeneral.modelProductDamageOfBusinessPlace?.OwnershipInformation;
			this.modelProductDamageOfBusinessPlace.SumInsuredDefault = modelProductGeneral.modelProductDamageOfBusinessPlace?.SumInsuredDefault;
			this.modelProductDamageOfBusinessPlace.Token = modelProductGeneral.modelProductDamageOfBusinessPlace?.Token;

			if(modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace != null || modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace !== undefined)
			{
				this.modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace = modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace;
			}
			else
			{
				this.modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace = undefined;
			}

			this.modelProductDamageOfBusinessPlace.listModelProductDamageOfBusinessPlaceGallery = undefined;
		}
	}

	setForSaveProductMyHouse(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductAsri = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;

		this.Token = modelProductGeneral.Token;
		this.PolicyStartDate = modelProductGeneral.PolicyStartDate;
		this.Status = 1;
		this.PolicyholderEmail = modelProductGeneral.PolicyholderEmail;

		if(modelProductGeneral.ReferralCode == null || modelProductGeneral.ReferralCode === undefined || modelProductGeneral.ReferralCode === "")
		{
			this.ReferralCode = "";
		}
		else
		{
			this.ReferralCode = modelProductGeneral.ReferralCode;
		}

		if(modelProductGeneral.modelProductMyHouse != null || modelProductGeneral.modelProductMyHouse !== undefined )
		{
			this.modelProductMyHouse = new ProductMyHouseModel();
			this.modelProductMyHouse.BuildingAddress = modelProductGeneral.modelProductMyHouse?.BuildingAddress;
			this.modelProductMyHouse.BuildingPostalCode = modelProductGeneral.modelProductMyHouse?.BuildingPostalCode;
			this.modelProductMyHouse.LocationInformation = modelProductGeneral.modelProductMyHouse?.LocationInformation;
			this.modelProductMyHouse.OwnershipInformation = modelProductGeneral.modelProductMyHouse?.OwnershipInformation;
			this.modelProductMyHouse.SumInsuredDefault = modelProductGeneral.modelProductMyHouse?.SumInsuredDefault;
			this.modelProductMyHouse.Token = modelProductGeneral.modelProductMyHouse?.Token;

			if(modelProductGeneral.modelProductMyHouse?.listModelBeneficiariesMyHouse != null || modelProductGeneral.modelProductMyHouse?.listModelBeneficiariesMyHouse !== undefined)
			{
				this.modelProductMyHouse.listModelBeneficiariesMyHouse = modelProductGeneral.modelProductMyHouse?.listModelBeneficiariesMyHouse;
			}
			else
			{
				this.modelProductMyHouse.listModelBeneficiariesMyHouse = undefined;
			}

			this.modelProductMyHouse.listModelProductMyHouseGallery = undefined;
		}
	}

	setForSaveProductMyMotorcycle(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductAsri = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;

		this.Token = modelProductGeneral.Token;
		this.PolicyStartDate = modelProductGeneral.PolicyStartDate;
		this.Status = 1;
		this.PolicyholderEmail = modelProductGeneral.PolicyholderEmail;

		if(modelProductGeneral.ReferralCode == null || modelProductGeneral.ReferralCode === undefined || modelProductGeneral.ReferralCode === "")
		{
			this.ReferralCode = "";
		}
		else
		{
			this.ReferralCode = modelProductGeneral.ReferralCode;
		}

		if(modelProductGeneral.modelProductMyMotorcycle != null || modelProductGeneral.modelProductMyMotorcycle !== undefined )
		{
			this.modelProductMyMotorcycle = new ProductMyMotorcycleModel();
			this.modelProductMyMotorcycle.Token = modelProductGeneral.modelProductMyMotorcycle?.Token;

			this.modelProductMyMotorcycle.listModelProductMyMotorcycleGallery = undefined;
			this.modelProductMyMotorcycle.listModelBeneficiariesMyMotorcycle = undefined;
		}
	}

	setForUpdateInsuredDamageOfBusinessPlace(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;

		this.Token = modelProductGeneral.Token;
		this.PolicyStartDate = modelProductGeneral.PolicyStartDate;

		if(modelProductGeneral.modelProductDamageOfBusinessPlace != null || modelProductGeneral.modelProductDamageOfBusinessPlace !== undefined )
		{
			this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
			this.modelProductDamageOfBusinessPlace.BuildingAddress = modelProductGeneral.modelProductDamageOfBusinessPlace?.BuildingAddress;
			this.modelProductDamageOfBusinessPlace.BuildingPostalCode = modelProductGeneral.modelProductDamageOfBusinessPlace?.BuildingPostalCode;
			this.modelProductDamageOfBusinessPlace.BuildingPostalName = modelProductGeneral.modelProductDamageOfBusinessPlace?.BuildingPostalName;
			this.modelProductDamageOfBusinessPlace.LocationInformation = modelProductGeneral.modelProductDamageOfBusinessPlace?.LocationInformation;
			this.modelProductDamageOfBusinessPlace.OwnershipInformation = modelProductGeneral.modelProductDamageOfBusinessPlace?.OwnershipInformation;
			this.modelProductDamageOfBusinessPlace.CompensationValue = modelProductGeneral.modelProductDamageOfBusinessPlace?.CompensationValue;
			this.modelProductDamageOfBusinessPlace.Token = modelProductGeneral.modelProductDamageOfBusinessPlace?.Token;
			this.modelProductDamageOfBusinessPlace.listModelProductDamageOfBusinessPlaceGallery = undefined;
			this.modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace = undefined;
		}
	}

	setForUpdateInsuredMyHouse(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;

		this.Token = modelProductGeneral.Token;
		this.PolicyStartDate = modelProductGeneral.PolicyStartDate;

		if(modelProductGeneral.ReferralCode == null || modelProductGeneral.ReferralCode === undefined || modelProductGeneral.ReferralCode === "")
		{
			this.ReferralCode = "";
		}
		else
		{
			this.ReferralCode = modelProductGeneral.ReferralCode;
		}

		if(modelProductGeneral.modelProductMyHouse != null || modelProductGeneral.modelProductMyHouse !== undefined )
		{
			this.modelProductMyHouse = new ProductMyHouseModel();
			this.modelProductMyHouse.BuildingAddress = modelProductGeneral.modelProductMyHouse?.BuildingAddress;
			this.modelProductMyHouse.BuildingPostalCode = modelProductGeneral.modelProductMyHouse?.BuildingPostalCode;
			this.modelProductMyHouse.BuildingPostalName = modelProductGeneral.modelProductMyHouse?.BuildingPostalName;
			this.modelProductMyHouse.LocationInformation = modelProductGeneral.modelProductMyHouse?.LocationInformation;
			this.modelProductMyHouse.OwnershipInformation = modelProductGeneral.modelProductMyHouse?.OwnershipInformation;
			this.modelProductMyHouse.SumInsuredDefault = modelProductGeneral.modelProductMyHouse?.SumInsuredDefault;
			this.modelProductMyHouse.Token = modelProductGeneral.modelProductMyHouse?.Token;
			this.modelProductMyHouse.listModelBeneficiariesMyHouse = undefined;
			this.modelProductMyHouse.listModelProductMyHouseGallery = undefined;
		}
	}

	setForUpdateInsuredMyMotorcycle(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;

		this.Token = modelProductGeneral.Token;
		this.PolicyStartDate = modelProductGeneral.PolicyStartDate;

		if(modelProductGeneral.ReferralCode == null || modelProductGeneral.ReferralCode === undefined || modelProductGeneral.ReferralCode === "")
		{
			this.ReferralCode = "";
		}
		else
		{
			this.ReferralCode = modelProductGeneral.ReferralCode;
		}

		if(modelProductGeneral.modelProductMyMotorcycle != null || modelProductGeneral.modelProductMyMotorcycle !== undefined )
		{
			this.modelProductMyMotorcycle = new ProductMyMotorcycleModel();
			this.modelProductMyMotorcycle.PartialLossVehicle = modelProductGeneral.modelProductMyMotorcycle?.PartialLossVehicle;
			this.modelProductMyMotorcycle.PersonalAccidentDriver = modelProductGeneral.modelProductMyMotorcycle?.PersonalAccidentDriver;
			this.modelProductMyMotorcycle.ThirdPartyLiability = modelProductGeneral.modelProductMyMotorcycle?.ThirdPartyLiability;
			this.modelProductMyMotorcycle.CityCode = modelProductGeneral.modelProductMyMotorcycle?.CityCode;
			this.modelProductMyMotorcycle.CityName = modelProductGeneral.modelProductMyMotorcycle?.CityName;
			this.modelProductMyMotorcycle.PostalCode = modelProductGeneral.modelProductMyMotorcycle?.PostalCode;
			this.modelProductMyMotorcycle.PostalName = modelProductGeneral.modelProductMyMotorcycle?.PostalName;
			this.modelProductMyMotorcycle.ProvinceCode = modelProductGeneral.modelProductMyMotorcycle?.ProvinceCode;
			this.modelProductMyMotorcycle.ProvinceName = modelProductGeneral.modelProductMyMotorcycle?.ProvinceName;
			this.modelProductMyMotorcycle.SumInsuredDefault = modelProductGeneral.modelProductMyMotorcycle?.SumInsuredDefault;
			this.modelProductMyMotorcycle.VehicleBrandCode = modelProductGeneral.modelProductMyMotorcycle?.VehicleBrandCode;
			this.modelProductMyMotorcycle.VehicleBrandName = modelProductGeneral.modelProductMyMotorcycle?.VehicleBrandName;
			this.modelProductMyMotorcycle.VehicleModelCode = modelProductGeneral.modelProductMyMotorcycle?.VehicleModelCode;
			this.modelProductMyMotorcycle.VehicleModelName = modelProductGeneral.modelProductMyMotorcycle?.VehicleModelName;
			this.modelProductMyMotorcycle.VehicleColorCode = modelProductGeneral.modelProductMyMotorcycle?.VehicleColorCode;
			this.modelProductMyMotorcycle.VehicleColorName = modelProductGeneral.modelProductMyMotorcycle?.VehicleColorName;
			this.modelProductMyMotorcycle.VehicleLocationCode = modelProductGeneral.modelProductMyMotorcycle?.VehicleLocationCode;
			this.modelProductMyMotorcycle.VehicleLocationName = modelProductGeneral.modelProductMyMotorcycle?.VehicleLocationName;
			this.modelProductMyMotorcycle.VehicleSubModelCode = modelProductGeneral.modelProductMyMotorcycle?.VehicleSubModelCode;
			this.modelProductMyMotorcycle.VehicleSubModelName = modelProductGeneral.modelProductMyMotorcycle?.VehicleSubModelName;
			this.modelProductMyMotorcycle.VehicleManufactureYearCode = modelProductGeneral.modelProductMyMotorcycle?.VehicleManufactureYearCode;
			this.modelProductMyMotorcycle.VehicleManufactureYearName = modelProductGeneral.modelProductMyMotorcycle?.VehicleManufactureYearCode;
			this.modelProductMyMotorcycle.VehicleManufactureYearCode = this.modelProductMyMotorcycle.VehicleManufactureYearCode?.toString();
			this.modelProductMyMotorcycle.VehicleManufactureYearName = this.modelProductMyMotorcycle.VehicleManufactureYearName?.toString();
			this.modelProductMyMotorcycle.VehiclePrice = modelProductGeneral.modelProductMyMotorcycle?.VehiclePrice;
			this.modelProductMyMotorcycle.LicensePlatePrefixCode = modelProductGeneral.modelProductMyMotorcycle?.LicensePlatePrefixCode;
			this.modelProductMyMotorcycle.LicensePlateInfix = modelProductGeneral.modelProductMyMotorcycle?.LicensePlateInfix;
			this.modelProductMyMotorcycle.LicensePlateSuffix = modelProductGeneral.modelProductMyMotorcycle?.LicensePlateSuffix;
			this.modelProductMyMotorcycle.VehicleChasisNumber = modelProductGeneral.modelProductMyMotorcycle?.VehicleChasisNumber;
			this.modelProductMyMotorcycle.VehicleEngineNumber = modelProductGeneral.modelProductMyMotorcycle?.VehicleEngineNumber;
			this.modelProductMyMotorcycle.Token = modelProductGeneral.modelProductMyMotorcycle?.Token;
			this.modelProductMyMotorcycle.listModelBeneficiariesMyMotorcycle = undefined;
			this.modelProductMyMotorcycle.listModelProductMyMotorcycleGallery = undefined;
		}
	}

	setForUpdateBeneficiariesMyHouse(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductBicycle = undefined;

		if (modelProductGeneral.modelProductMyHouse !== null || modelProductGeneral.modelProductMyHouse !== undefined)
		{
			this.modelProductMyHouse = new ProductMyHouseModel();
			this.modelProductMyHouse.listModelBeneficiariesMyHouse = modelProductGeneral.modelProductMyHouse?.listModelBeneficiariesMyHouse;
		}
		this.Token = modelProductGeneral.Token;
	}

	setForUpdateBeneficiariesMyMotorcycle(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductDamageOfBusinessPlace = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;

		if (modelProductGeneral.modelProductMyMotorcycle !== null || modelProductGeneral.modelProductMyMotorcycle !== undefined)
		{
			this.modelProductMyMotorcycle = new ProductMyMotorcycleModel();
			this.modelProductMyMotorcycle.listModelBeneficiariesMyHouse = modelProductGeneral.modelProductMyMotorcycle?.listModelBeneficiariesMyHouse;
		}
		this.Token = modelProductGeneral.Token;
	}

	setForUpdateBeneficiariesDamageOfBusinessPlace(modelProductGeneral: ProductGeneralModel): void
	{
		this.modelProductOto = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductMyMotorcycle = undefined;
		this.modelProductAsri = undefined;
		this.modelProductMyHouse = undefined;
		this.modelProductBicycle = undefined;

		if (modelProductGeneral.modelProductDamageOfBusinessPlace !== null || modelProductGeneral.modelProductDamageOfBusinessPlace !== undefined)
		{
			this.modelProductDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
			this.modelProductDamageOfBusinessPlace.listModelBeneficiariesDamageOfBusinessPlace = modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace;
			this.modelProductDamageOfBusinessPlace.listModelProductDamageOfBusinessPlaceGallery = undefined;
		}
		this.Token = modelProductGeneral.Token;
	}

	setProgressforProductDraft(): void
	{
		if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY || this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLY)
		{
			this.Progress = this.modelProductAsri?.setProgressforDraf();
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTO || this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY)
		{
			this.Progress = this.modelProductOto?.setProgressforDraf();
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
		{
			this.Progress = this.modelProductPersonalAccident?.setProgressforDraf();
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE)
		{
			this.Progress = this.modelProductDamageOfBusinessPlace?.setProgressforDraf();
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYHOUSE)
		{
			this.Progress = this.modelProductMyHouse?.setProgressforDraf();
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE)
		{
			this.Progress = this.modelProductMyMotorcycle?.setProgressforDraf();
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_BICYCLE)
		{
			this.Progress = this.modelProductBicycle?.setProgressforDraf();
		}
		else
		{

		}
	}

	getProductName(): string
	{
		if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY || this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLY)
		{
			return StringConstant.STRING_TITLE_ASRI;
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTO || this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY)
		{
			return StringConstant.STRING_TITLE_OTO;
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
		{
			return StringConstant.STRING_TITLE_PERSONALACCIDENT;
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE)
		{
			return StringConstant.STRING_TITLE_DAMAGEOFBUSINESSPLACE;
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYHOUSE)
		{
			return StringConstant.STRING_TITLE_MYHOUSE;
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE)
		{
			return StringConstant.STRING_TITLE_MYMOTORCYCLE;
		}
		else if (this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_BICYCLE)
		{
			return StringConstant.STRING_TITLE_BICYCLE;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getProductPeriode(): string
	{
		if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY)
		{
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return this.modelProductAsri?.CoverageName?.toLocaleLowerCase()!;
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLY)
		{
			return StringConstant.STRING_CONTENT_SUBTITTLE_MONTHLY_LOWERCASE;
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTO)
		{
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return this.modelProductOto?.CoverageName?.toLocaleLowerCase()!;
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY)
		{
			if(this.modelProductOto != null || this.modelProductOto !== undefined)
			{
				if(this.modelProductOto.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_COMPREHENSIVE || this.modelProductOto.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_TLO)
				{
					return StringConstant.STRING_CONTENT_SUBTITTLE_MONTHLY_LOWERCASE;
				}
				else if(this.modelProductOto.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_DAILY_OTO)
				{
					return StringConstant.STRING_CONTENT_SUBTITTLE_DAILY_LOWERCASE;
				}
				else
				{
					return StringConstant.STRING_CHARACTER_EMPTY;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
		{
			if(this.modelProductPersonalAccident != null || this.modelProductPersonalAccident !== undefined)
			{
				if(this.modelProductPersonalAccident.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_PERSONALACCIDENT)
				{
					return StringConstant.STRING_CONTENT_SUBTITTLE_YEARLY_LOWERCASE;
				}
				else if(this.modelProductPersonalAccident.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_PERSONALACCIDENT)
				{
					return StringConstant.STRING_CONTENT_SUBTITTLE_MONTHLY_LOWERCASE;
				}
				else if(this.modelProductPersonalAccident.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY)
				{
					return StringConstant.STRING_CONTENT_SUBTITTLE_DAILY_LOWERCASE;
				}
				else
				{
					return StringConstant.STRING_CHARACTER_EMPTY;
				}
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_BICYCLE)
		{
			return StringConstant.STRING_CONTENT_SUBTITTLE_YEARLY_LOWERCASE;
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE)
		{
			return StringConstant.STRING_TITLE_DAMAGEOFBUSINESSPLACE;
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYHOUSE)
		{
			return StringConstant.STRING_TITLE_MYHOUSE;
		}
		else if(this.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE)
		{
			return StringConstant.STRING_TITLE_MYMOTORCYCLE;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	//#endregion
}

//#endregion