<div id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
		<img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormVerticalContainer DivSiteContainer">
			<div class="DivProduct">
				<h2 class="FontSoftColor"> {{ _modelHeaderContent.TitleIDN}} </h2>
			</div>
		</div>
	</section>

	<section class="SectionSecondary" id="sectionPartner">
		<div class="DivSiteContainer">
            <h1>{{ this._stringConstant.STRING_INFORMATION_TITLE_PARTNER }}</h1>

			<div class="DivContentImageGallery">
                <div class="DivButtonNavigate">
                    <a class="ButtonPreviousGallery" (click)="showContentPrevious()"><img src="../../assets/icons/sakina/icon_prev.svg"></a>
                </div>
                <section id="sectionImageGallery">
					<ng-container *ngFor="let modelPartner of _arrayModelPartner | slice:0:5">
						<label for="inputImageGallery0" id="labelImageGallery0" class="LabelImageNonActive" [ngClass]="{'LabelImageActive': _numberIndexPartner === 0}">
							<img [src]="modelPartner.listModelPartnerGallery![0].URL" [alt]="modelPartner.Name">
							<p>{{ modelPartner.Name }}</p>
						</label>
					</ng-container>
                </section>
                <div class="DivButtonNavigate">
                    <a class="ButtonNextGallery" (click)="showContentNext()"><img src="../../assets/icons/sakina/icon_next.svg"></a>
                </div>
            </div>
		</div>
	</section>
</div>
