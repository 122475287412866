//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { LocationModel } from "./location.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class ProductShophouseModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product Shophouse (Ruko) model, used for SPPA and policy.
		Author: Dimas Dandy.
		Created on : Monday, 27 May 2024.				Updated on : .
		Created by : Dimas Dandy.						Updated by : .
		Version : 1.0:6.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;
	ReferenceNumber?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	/* POLICYHOLEDR - END */

	/* INSURED - START */

	BuildingAddress?: string;
	BuildingCountryID?: number;
	BuildingCountryName?: string;
	BuildingProvinceID?: number;
	BuildingProvinceName?: string;
	BuildingCityID?: number;
	BuildingCityName?: string;
	BuildingCityCode?: string;
	BuildingSubDistrictID?: number;
	BuildingVillageID?: number;
	BuildingHamletCode?: string;
	BuildingNeighbourhoodCode?: string;
	BuildingPostalID?: number;
	BuildingPostalName?: string;
	BuildingPostalCode?: string;
	BuildingLatitude?: number;
	BuildingLongitude?: number;

	ObjectOccupationName?: string;
	ObjectOccupationCode?: string;
	BuildingPrice?: number;
	InteriorPrice?: number;
	StockPrice?: number;
	FrontRiskName?: string;
	FrontRiskCode?: string;
	BackRiskName?: string;
	BackRiskCode?: string;
	LeftRiskName?: string;
	LeftRiskCode?: string;
	RightRiskName?: string;
	RightRiskCode?: string;

	ExcludeFlammableMaterials?: boolean;
	ExcludeJeweleryLiveStockMobilePhoneVoucher?: boolean;
	ExcludeElectronicGoodsStock?: boolean;
	BuildingNearMarket?: boolean;
	BuildingWorkPlace?: boolean;

	Construction?: string;
	RiskOccupation?: number;
	RiskCategory?: string;
	ZoneCategory?: string;
	FloodZone?: string;

	/* INSURED - END */

	/* PREMIUM - START */

	PremiumTotalAmount?: number;
	Status?: ENUM_SPPA_STATUS;

	/* PREMIUM - END */

	/* DISASTER AND RIOT - START */

	EarthquakeTsunamiVolcanicEruption?: boolean;
	FloodTyphoonStormWaterDamage?: boolean;
	Riots?: boolean;
	DamageCausedByVehicles?: boolean;

	/* DISASTER AND RIOT - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelProductShophouseGallery?: Array<ProductBaseGalleryModel>;
	modelProductShophousePayment?: ProductBaseGalleryModel;

	/* NOT MAPPED - END */

	/* Attribute - END */

	constructor()
	{
		super();
		this.listModelProductShophouseGallery = [];
	}


	//#region VALIDATE

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Shophouse");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUploadDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateUpload();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelProductShophouseGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Dokumen tidak boleh kosong, harap lampirkan file.";
			}
		}
		else
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseProductShophouseGallery: ResponseModel;
			let numberSize: number = 0;

			for (const modelProductShophouseGallery of this.listModelProductShophouseGallery ?? [])
			{
				modelResponseProductShophouseGallery = modelProductShophouseGallery.validateAdd();

				if (modelResponseProductShophouseGallery.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseProductShophouseGallery;
				}
				else
				{
					numberSize += modelProductShophouseGallery.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimal total lampiran adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_PRODUCTOTO_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;
			let booleanBreak: boolean = false;

			const arrayModelSupportingDocument: Array<SupportingDocumentModel> = this.setSupportingDocument();

			arrayModelSupportingDocument.forEach(modelSupportingDocument =>
			{
				const arrayDocument = this.listModelProductShophouseGallery?.filter(modelProductShophouseGallery => modelProductShophouseGallery.Type === modelSupportingDocument.Type);

				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					if(booleanBreak === false)
					{
						booleanBreak = true;
						modelResponse.MessageContent = "Dokumen "+ modelSupportingDocument.Name +" tidak boleh kosong.";
						numberDocumentFailed = numberDocumentFailed + 1;
					}
				}

			});

			if(numberDocumentSuccess === arrayModelSupportingDocument.length)
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Shophouse");

		if(this.listModelProductShophouseGallery == null || this.listModelProductShophouseGallery === undefined)
		{
			modelResponse.MessageContent = "Terdapat kesalahan pada daftar lampiran.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	validateForCoverage(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		if (this.CoverageCode == null || this.CoverageCode === undefined || this.CoverageCode === "")
		{
			modelResponse.MessageContent = "Kode cakupan tidak boleh kosong. Silakan kontak pengembang!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateForBuildingPrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		modelResponse.setForValidation("Harga Bangunan");

		if (this.BuildingPrice == null || this.BuildingPrice === undefined || this.BuildingPrice === 0)
		{
			modelResponse.MessageContent = "Harga bangunan tidak boleh kosong.";
		}
		else if(this.BuildingPrice < RuleConstant.NUMBER_BUILDINGPRICE_VALUEMINIMUM)
		{
			modelResponse.MessageContent = "Harga bangunan minimal 1 Juta Rupiah!";
		}
		else if (this.BuildingPrice > RuleConstant.NUMBER_BUILDINGPRICESHOPHOUSE_VALUEMAXIMUM)
		{
			modelResponse.MessageContent = "Harga bangunan maksimal atau sama dengan 5 Miliar Rupiah";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateForInteriorPricePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Harga Perlengkapan & Stock");

		if (this.BuildingPrice == null || this.BuildingPrice === undefined || this.BuildingPrice === 0)
		{
			modelResponse.MessageContent = "Harga bangunan tidak boleh kosong.";
		}
		else if (this.InteriorPrice == null || this.InteriorPrice === undefined || this.InteriorPrice === 0)
		{
			modelResponse.MessageContent = "Harga Perlengkapan tidak boleh kosong.";
		}
		else if ((this.InteriorPrice !== null && this.InteriorPrice !== undefined) && this.InteriorPrice < RuleConstant.NUMBER_INTERIORPRICE_VALUEMINIMUM)
		{
			modelResponse.MessageContent = "Minimal harga perlengkapan bangunan Rp. 100.000";
		}
		else if ((this.InteriorPrice !== undefined && this.BuildingPrice !== undefined) && (this.InteriorPrice > (this.BuildingPrice * 0.1 )))
		{
			modelResponse.MessageContent = "harga perlengkapan tidak boleh > 10% dari Harga Bangunan.";
		}
		else if (this.StockPrice == null || this.StockPrice === undefined || this.StockPrice === 0)
		{
			modelResponse.MessageContent = "Harga Stock tidak boleh kosong.";
		}
		else if (this.BuildingPrice !== undefined &&
			(this.StockPrice > ((this.BuildingPrice + this.InteriorPrice + this.StockPrice) * 0.5))
		)
		{
			modelResponse.MessageContent = "Harga Stock tidak boleh lebih dari harga bangunan dan interior";
		}
		else if (this.BuildingPrice !== undefined &&
			((this.BuildingPrice + this.InteriorPrice + this.StockPrice) > RuleConstant.NUMBER_BUILDINGPRICESHOPHOUSE_VALUEMAXIMUM)
		)
		{
			modelResponse.MessageContent = "Harga Total maksimal atau sama dengan 5 Miliar Rupiah";
		}
		else if(this.FrontRiskCode == null || this.FrontRiskCode === undefined
			|| this.FrontRiskName == null || this.FrontRiskName === undefined
			|| this.BackRiskCode == null || this.BackRiskCode === undefined
			|| this.BackRiskName == null || this.BackRiskName === undefined
			|| this.LeftRiskCode == null || this.LeftRiskCode === undefined
			|| this.LeftRiskName == null || this.LeftRiskName === undefined
			|| this.RightRiskCode == null || this.RightRiskCode === undefined
			|| this.RightRiskName == null || this.RightRiskName === undefined
		)
		{
			modelResponse.MessageContent = "Risiko Sekitar tidak boleh kosong";
		}
		else if(this.BuildingPostalCode == null || this.BuildingPostalCode === undefined)
		{
			modelResponse.MessageContent = "Kode Pos tidak boleh kosong";
		}
		else if(this.BuildingProvinceName == null || this.BuildingProvinceName === undefined)
		{
			modelResponse.MessageContent = "Provinsi tidak boleh kosong";
		}
		else if(this.BuildingCityName == null || this.BuildingCityName === undefined)
		{
			modelResponse.MessageContent = "Kota tidak boleh kosong";
		}
		else if(this.BuildingPostalName == null || this.BuildingPostalName === undefined)
		{
			modelResponse.MessageContent = "Lokasi tidak boleh kosong";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateCheckForCalculatePremiumShophouse(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateForCoverage();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForBuildingPrice();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForInteriorPricePrice();
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckForObjectInformationShophouse(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHHYPEN);
		const regularExpressionForAddress: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTTAGEMOJI);

		modelResponse.setForValidation("Informasi Objek");

		if (this.BuildingAddress == null || this.BuildingAddress === undefined || this.BuildingAddress === "")
		{
			modelResponse.MessageContent = "Alamat bangunan yang diasuransikan tidak boleh kosong!";
		}
		else if (this.BuildingAddress.length < RuleConstant.NUMBER_BUILDINGADDRESS_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Alamat bangunan yang diasuransikan minimal terdiri dari 10 karakter!";
		}
		else if (this.BuildingAddress !== undefined && !regularExpressionForAddress.test(this.BuildingAddress))
		{
			modelResponse.MessageContent = "Format input Alamat bangunan yang diasuransikan mengandung karakter yang tidak diizinkan.";
			this.BuildingAddress = undefined;
		}
		else if (this.BuildingPostalCode == null || this.BuildingPostalCode === undefined || this.BuildingPostalCode === "")
		{
			modelResponse.MessageContent = "Kode pos tidak boleh kosong!";
		}
		else if (!regularExpression.test(this.BuildingPostalCode))
		{
			modelResponse.MessageContent = "Kode pos hanya boleh berisi angka, huruf, atau strip!";
			this.BuildingPostalCode = undefined;
		}
		else if (
			(this.ExcludeFlammableMaterials === false || this.ExcludeFlammableMaterials === undefined || this.ExcludeFlammableMaterials === null)
			|| (this.ExcludeElectronicGoodsStock === false || this.ExcludeElectronicGoodsStock === undefined || this.ExcludeElectronicGoodsStock === null)
			|| (this.ExcludeJeweleryLiveStockMobilePhoneVoucher === false || this.ExcludeJeweleryLiveStockMobilePhoneVoucher === undefined || this.ExcludeJeweleryLiveStockMobilePhoneVoucher === null)
		)
		{
			modelResponse.MessageContent = "Silakan pilih kotak centang untuk melanjutkan kelangkah berikutnya.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelProductShophouseGallery !== null && this.listModelProductShophouseGallery !== undefined)
		{
			if (this.listModelProductShophouseGallery.length > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{

			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setBuildingLocationFromPostal(modelLocation: LocationModel): void
	{
		this.BuildingCityCode = modelLocation.CityCode;
		this.BuildingCityName = modelLocation.CityName;
		this.BuildingCountryName = modelLocation.CountryName;
		this.BuildingPostalID = modelLocation.PostalID;
		this.BuildingPostalName = modelLocation.PostalName;
		this.BuildingProvinceName = modelLocation.ProvinceName;
	}

	setSupportingDocument(): Array<SupportingDocumentModel>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayModelGalleryModel: Array<SupportingDocumentModel> =
		[
			{
				Type: enumPhotoType.BuildingFront,
				Name : "Tampak Depan*"
			},
			{
				Type: enumPhotoType.BuildingLeft,
				Name : "Tampak Kiri*"
			},
			{
				Type: enumPhotoType.BuildingRight,
				Name : "Tampak Kanan*"
			},
			{
				Type: enumPhotoType.BuildingBack,
				Name : "Tampak Belakang*"
			},
			{
				Type: enumPhotoType.StockInBuilding,
				Name : "Stock*"
			},
			{
				Type: enumPhotoType.BuildingInterior,
				Name : "Interior*"
			},
		];

		return arrayModelGalleryModel;
	}

	//#endregion


	//#region GENERATE

	setInterfaceDocument(): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();
		arrayModelGalleryModel =
		[
			enumPhotoType.BuildingFront,
			enumPhotoType.BuildingLeft,
			enumPhotoType.BuildingRight,
			enumPhotoType.BuildingBack,
		];

		return arrayModelGalleryModel;
	}

	setProgressforDraf(): number
	{
		let numberProgress: number = 0;
		if(this.BuildingPostalCode == null || this.BuildingPostalCode === undefined) // PolicyholderFirstName
		{
			numberProgress = 25;
		}
		else if(this.listModelProductShophouseGallery === undefined || this.listModelProductShophouseGallery.length === 0) //Postalcode harus keisi
		{
			numberProgress = 50;
		}
		else if(this.listModelProductShophouseGallery !== undefined && this.listModelProductShophouseGallery.length > 0)
		{
			numberProgress = 75;
		}
		else
		{
			numberProgress = 90;
		}

		return numberProgress;
	}

	//#endregion


	//#region CLEAN

	cleanForProductShophouse()
	{
		this.ExcludeElectronicGoodsStock = false;
		this.ExcludeFlammableMaterials = false;
		this.ExcludeJeweleryLiveStockMobilePhoneVoucher = false;
		this.EarthquakeTsunamiVolcanicEruption = false;
		this.FloodTyphoonStormWaterDamage = false;
		this.Riots = false;
		this.DamageCausedByVehicles = false;
	}

	//#endregion

}

//#endregion