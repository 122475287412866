//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductShophouseModel } from "../models/productshophouse.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductShophouseService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region CALCULATE

	calculatePremiumForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_CALCULATEPREMIUMFORCUSTOMER);
	}

	//#endregion


	//#region INSERT

	insertProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_INSERTPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region UPDATE

	updateInsuredForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_UPDATEINSUREDFORCUSTOMER);

	}

	//#endregion


	//#region UPLOAD

	uploadProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductShophouse: ProductShophouseModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductShophouse), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_UPLOADPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region SELECT

	selectProductByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductShophouse: ProductShophouseModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductShophouse), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_SELECRPRODUCTBYTOKENFORCUSTOMER);

	}

	//#endregion


	//#region SAVE

	saveProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_SAVEPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region SEND

	sendSPPAByEmailForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_SENDSPPABYEMAILFORCUSTOMER);

	}

	//#endregion


	//#region PAYMENT

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductShophouse: ProductShophouseModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductShophouse), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_CONFIRMPAYMENT);

	}

	sharePaymentForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_SHAREPAYMENTFORCUSTOMER);
	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_GENERATEPAYMENTBRIVA);
	}

	generatePaymentBNI(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_GENERATEPAYMENTBNI);
	}

	generatePaymentBCA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_GENERATEPAYMENTBCA);
	}

	generatePaymentMandiri(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_GENERATEPAYMENTMANDIRI);
	}

	generatePaymentPermata(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_GENERATEPAYMENTPERMATA);
	}

	generatePaymentQRIS(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTSHOPHOUSE_GENERATEPAYMENTQRIS);
	}

	generatePaymentIndomart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTINDOMARET);
	}

	generatePaymentAlfamart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTALFAMART);
	}

	generatePaymentShopeePay(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTSHOPEEPAY);
	}

	generatePaymentCreditCard(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTCREDITCARD);
	}

	//#endregion
}

//#endregion
