//#region IMPORT

import { ENUM_RESPONSE_STATE, ENUM_SEVERITY_TYPE, ENUM_TICKET_STATUS, ENUM_TOPIC_TYPE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { DivisionModel } from "./division.model";
import { ResponseModel } from "./response.model";
import { TicketConversationModel } from "./ticketconversation.model";
import { TicketParticipantModel } from "./ticketparticipant.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class TicketModel extends BaseModel
{
	ID?: number;
	CustomerUserID?: number; // null
	Token?: string;
	TicketNumber?: string;

	Title?: string;
	Topic?: ENUM_TOPIC_TYPE;
	Description?: string;
	Status?: ENUM_TICKET_STATUS;
	SeverityType?: ENUM_SEVERITY_TYPE;
	Age?: number;
	Publicity?: boolean;
	DivisionID?: number;
	DivisionCode?: string;
	DivisionName?: string;
	CustomerName?: string;
	CustomerEmail?: string;
	CustomerPhone?: string;
	CustomerCoreID?: string;
	URL?: string;

	listModelTicketParticipant?: Array<TicketParticipantModel>;
	listModelTicketConversation?: Array<TicketConversationModel>;

	modelUser?: UserModel;
	modelCustomerUser?: UserModel;
	modelDivision?: DivisionModel;
	modelTicketParticipant?: TicketParticipantModel;
	modelTicketConversation?: TicketConversationModel;


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelCustomerUser = new UserModel();
		this.modelTicketConversation = new TicketConversationModel();
		this.listModelTicketParticipant = [];
		this.listModelTicketConversation = [];
	}

	//#endregion


	//#region VALIDATION

	validateTicketPublic(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regEXEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		modelResponse.setForValidation("Ticket");

		if (this.CustomerName == null || this.CustomerName === undefined || this.CustomerName === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_NAME;
		}
		else if (this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_TITLE;
		}
		else if (this.Topic == null || this.Topic === undefined )
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_TOPIC;
		}
		else if (this.CustomerEmail == null || this.CustomerEmail === undefined || this.CustomerEmail === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_EMAIL;
		}
		else if (this.CustomerPhone == null || this.CustomerPhone === undefined || this.CustomerPhone === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_PHONENUMBER;
		}
		else if (this.Description == null || this.Description === undefined || this.Description === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_DESCRIPTION;
		}
		else
		{
			if (!regEXEmail.test(this.CustomerEmail))
			{
				modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_INCORRECT_EMAIL;
			}
			else
			{
				modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_FULLY_FILLED;
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateTicket(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket");

		if (this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = "Title can't be empty, please fill in the blanks!";
		}
		else if (this.Description == null || this.Description === undefined || this.Description === "")
		{
			modelResponse.MessageContent = "Description can't be empty, please fill in the blanks";
		}
		else if (this.SeverityType == null || this.SeverityType === undefined)
		{
			modelResponse.MessageContent = "Severity type can't be empty, please fill severity type!";
		}
		else if (this.Publicity == null || this.Publicity === undefined)
		{
			modelResponse.MessageContent = "Publicity type can't be empty, please fill publicity type!";
		}
		else if (this.listModelTicketParticipant == null || this.listModelTicketParticipant === undefined)
		{
			modelResponse.MessageContent = "Model Participant type can't be empty, please contact developer!";
		}
		else if (this.listModelTicketParticipant.length <= 0)
		{
			modelResponse.MessageContent = "Participant type can't be empty, please select participant!";
		}
		else
		{
			modelResponse.MessageContent = "Ticket is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateTicketNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket");

		if (this.TicketNumber == null || this.TicketNumber === undefined || this.TicketNumber === "" )
		{
			modelResponse.MessageContent = "Ticket number can't be empty, please fill ticket number!";
		}
		else
		{
			modelResponse.MessageContent = "Ticket is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateTicketForCustomer(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateTicket();
		const regexEmail: RegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if (this.CustomerName == null || this.CustomerName === undefined || this.CustomerName === "")
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				modelResponse.MessageContent = "Customer name can't be empty, please fill in the blanks!";
			}
			else if (this.CustomerPhone == null || this.CustomerPhone === undefined || this.CustomerPhone === "")
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				modelResponse.MessageContent = "Customer phone can't be empty, please fill in the blanks!";
			}
			else if (this.CustomerPhone.search(/^[-+]?[0-9]+$/))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				modelResponse.MessageContent = "Customer phone must be number, please fill corectly!";
			}
			else if (this.CustomerPhone.charAt(0) === "0")
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				modelResponse.MessageContent = "Customer phone must be start with 8, please fill corectly!";
			}
			else if (this.CustomerEmail == null || this.CustomerEmail === undefined || this.CustomerEmail === "")
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				modelResponse.MessageContent = "Customer email can't be empty, please fill in the blanks!";
			}
			else if (!regexEmail.test(this.CustomerEmail.toLowerCase()))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				modelResponse.MessageContent = "Customer email must be email format, please fill corectly!";
			}
			else
			{
				modelResponse.MessageContent = "Ticket is filled correctly";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateAddForTicketConversation(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket General");

		if (this.modelTicketConversation == null || this.modelTicketConversation === undefined)
		{
			modelResponse.MessageContent = "Error occured on ticket, model conversation is empty!";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket");

		if (this.modelTicketConversation == null || this.modelTicketConversation === undefined)
		{
			modelResponse.MessageContent = "Model ticket conversation can't be empty!";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token can't be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region GETTER

	getSeverityTypeIconPath(): string
	{
		if (this.SeverityType == null || this.SeverityType === undefined)
		{
			return "icon_severity_low";
		}
		else
		{
			if (this.SeverityType === ENUM_SEVERITY_TYPE.Urgent)
			{
				return "icon_severity_urgent";
			}
			else if (this.SeverityType === ENUM_SEVERITY_TYPE.High)
			{
				return "icon_severity_high";
			}
			else if (this.SeverityType === ENUM_SEVERITY_TYPE.Medium)
			{
				return "icon_severity_medium";
			}
			else
			{
				return "icon_severity_low";
			}
		}
	}

	getStatus(): string
	{
		if (this.Status == null || this.Status === undefined)
		{
			return "Pending";
		}
		else
		{
			return ENUM_TICKET_STATUS[this.Status];
		}
	}

	getPublicity(): string
	{
		if (this.Publicity == null || this.Publicity === undefined)
		{
			return "Publicity Not Set";
		}
		else if (this.Publicity === true)
		{
			return "Shared";
		}
		else if (this.Publicity === false)
		{
			return "Not Shared";
		}
		else
		{
			return "Publicity Not Set";
		}
	}

	//#endregion


	//#region SETTER

	setModelCustomerUser(): void
	{
		const stringModelCustomerUser: string = JSON.stringify(this.modelCustomerUser);
		this.modelCustomerUser = new UserModel();
		this.modelCustomerUser.setModelFromString(stringModelCustomerUser);
	}

	setCustomerPhone(stringCountryCode: string): void
	{
		if ( this.CustomerPhone?.charAt(0) === "0" )
		{
			this.CustomerPhone = this.CustomerPhone.slice(1);
			this.CustomerPhone = stringCountryCode + this.CustomerPhone;
		}
		else
		{
			this.CustomerPhone = stringCountryCode + this.CustomerPhone;
		}
	}

	//#endregion


	//#region FUNCTION

	cleanForInsert(): void
	{
		this.listModelTicketConversation = undefined;
		this.modelCustomerUser = undefined;
		this.modelTicketConversation = undefined;
		this.modelDivision = undefined;
	}

	cleanForUpdate(): void
	{
		this.listModelTicketConversation = undefined;
		this.listModelTicketParticipant = undefined;
		this.modelCustomerUser = undefined;
		this.modelTicketParticipant = undefined;
		this.modelDivision = undefined;
		this.modelUser = undefined;
	}

	cleanForInsertCustomer(): void
	{
		this.listModelTicketConversationDocument = undefined;
		this.listModelTicketConversation = undefined;
		this.modelCustomerUser = undefined;
		this.modelTicketConversation = undefined;
		this.modelDivision = undefined;
	}

	cleanForPublicity(): void
	{
		this.TicketNumber = undefined;
		this.Title = undefined;
		this.Description = undefined;
		this.listModelTicketParticipant = undefined;
		this.CreatedOn = undefined;
		this.CustomerName = undefined;
		this.listModelTicketConversation = undefined;
	}

	cleanForConversation(): void
	{
		this.modelCustomerUser = undefined;
		this.modelTicketConversation = undefined;
		this.listModelTicketConversationDocument = undefined;
		this.listModelTicketConversation = undefined;
		this.listModelTicketParticipant = undefined;
	}

	//#endregion
}

//#endregion