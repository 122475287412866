//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { ENUM_DEVICE_TYPE, ENUM_HEADER_TYPE, ENUM_PERIOD_TYPE, ENUM_PRODUCTCONTENT_TYPE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { HeaderContentModel } from "src/app/models/headercontent.model";
import { ProductContentModel } from "src/app/models/productcontent.model";
import { ProductContentGalleryModel } from "src/app/models/productcontentgallery.model";
import { ResponseModel } from "src/app/models/response.model";
import { HeaderService } from "src/app/services/header.service";
import { ProductcontentService } from "src/app/services/productcontent.service";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-productlist",
		templateUrl: "./productlist.component.html",
		styleUrls: ["./productlist.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class ProductListComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelHeaderContent: HeaderContentModel;
	public _modelHeaderContentRequest: HeaderContentModel;
	public _modelProductContent: ProductContentModel;
	public _modelProductContentRequest: ProductContentModel;

	public _stringConstant = StringConstant;
	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;

	public _arrayModelProductContent: Array<ProductContentModel>;
	public _arrayModelProductContentGallery: Array<ProductContentGalleryModel>;

	public _enumPeriodType = ENUM_PERIOD_TYPE;
	public _enumProductContentType = ENUM_PRODUCTCONTENT_TYPE;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router, private _serviceHeaderContent: HeaderService, private _serviceProductContent: ProductcontentService)
	{
		super(serviceSession, router);

		this._modelHeaderContent = new HeaderContentModel();
		this._modelHeaderContentRequest = new HeaderContentModel();
		this._modelProductContent = new ProductContentModel();
		this._modelProductContentRequest = new ProductContentModel();

		// this._stringImageMainHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_produk_insurtech.jpg");
		// this._stringImageMainHeaderMobile = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_produk_insurtech_mobile.jpg");

		this._stringImageMainHeader = "";
		this._stringImageMainHeaderMobile = "";

		this._arrayModelProductContent = [];
		this._arrayModelProductContentGallery = [];
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		if (localStorage.getItem("ProductAsri"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductAsri");
			localStorage.removeItem("DiscountAsri");
			localStorage.removeItem("UserPolicyHolderAsri");
		}
		else
		{

		}

		if (localStorage.getItem("ProductOto"))
		{
			/* Remove request model oto from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductOto");
		}
		else
		{

		}

		if (localStorage.getItem("ProductPersonalAccident"))
		{
			/* Remove request model personal accident from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("DiscountPersonalAccident");
			localStorage.removeItem("ProductPersonalAccident");
			localStorage.removeItem("UserPolicyHolderPersonalAccident");
		}
		else
		{

		}

		if (localStorage.getItem("ProductBicycle"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("DiscountBicycle");
			localStorage.removeItem("ProductBicycle");
			localStorage.removeItem("UserPolicyHolderBicycle");
		}
		else
		{

		}

		if (localStorage.getItem("ProductMyHouse"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductMyHouse");
			localStorage.removeItem("UserPolicyHolderMyHouse");
		}
		else
		{

		}

		if (localStorage.getItem("ProductDamageOfBusinessPlace"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductDamageOfBusinessPlace");
			localStorage.removeItem("UserPolicyHolderDamageOfBusinessPlace");
		}
		else
		{

		}

		if (localStorage.getItem("ProductMotorcycle"))
		{
			/* Remove request model asri from localstorage */
			localStorage.removeItem("ProductGeneral");
			localStorage.removeItem("ProductMotorcycle");
			localStorage.removeItem("UserPolicyHolderMotorcycle");
		}
		else
		{

		}

		if(localStorage.getItem("ProductGeneral"))
		{
			/* Remove request model general from localstorage */
			localStorage.removeItem("ProductGeneral");
		}
		else
		{

		}

		//call service
		this.callSelectHeaderContentByAttributesForCustomer(this);
		this.callSelectProductContentByAttributesForCustomer(this);
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}

	getProductContentTypeForNavigate(productContentType: ENUM_PRODUCTCONTENT_TYPE)
	{
		if(productContentType === ENUM_PRODUCTCONTENT_TYPE.OtoYearly)
		{
			this.goToProductOtoYearly();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.AsriYearly)
		{
			this.goToProductAsriYearly();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.BicycleYearly)
		{
			this.goToProductBicycleYearly();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.PersonalAccidentYearly)
		{
			this.goToProductPersonalAccidentYearly();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.AsmikMyHouseYearly)
		{
			this.goToProductASMIKMyHouse();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.AskmikDamageOfBusinesPlaceYearly)
		{
			this.goToProductASMIKDamageOfBusinessPlace();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.AsmikMyMotorcycleYearly)
		{
			this.goToProductASMIKMyMotorcycle();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.OtoMonthly)
		{
			this.goToProductOtoMonthly();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.AsriMonthly)
		{
			this.goToProductAsriMonthly();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.PersonalAccidentMonthly)
		{
			this.goToProductPersonalAccidentMonthly();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.OtoDaily)
		{
			this.goToProductOtoDaily();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.PersonalAccidentDaily)
		{
			this.goToProductPersonalAccidentDaily();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.Shophouse)
		{
			this.goToProductShophouse();
		}
		else if(productContentType === ENUM_PRODUCTCONTENT_TYPE.Apartment)
		{
			this.goToProductApartment();
		}
		else
		{
		}
	}

	getImage(stringURL: string): string
	{
		const convertToBroadcastURL = new ConvertToBroadcastURL();
		if(stringURL != null || stringURL !== undefined || stringURL !== "")
		{
			return convertToBroadcastURL.transform(stringURL);
		}
		else
		{
			return "";
		}
	}

	//#endregion


	//#region WEB SERVICES

	public callSelectHeaderContentByAttributesForCustomer(componentCurrent: ProductListComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelHeaderContentRequest.DeviceType = ENUM_DEVICE_TYPE.Website;
		componentCurrent._modelHeaderContentRequest.HeaderType = ENUM_HEADER_TYPE.Product;

		componentCurrent._serviceHeaderContent.selectHeaderContentByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelHeaderContent.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelHeaderContent.listModelHeaderGallery !== undefined)
						{
							for (const modelHeaderContentGallery of componentCurrent._modelHeaderContent.listModelHeaderGallery)
							{
								modelHeaderContentGallery.URL = componentCurrent._pipeConvertToBroadcastURL.transform(modelHeaderContentGallery.URL);
								componentCurrent._stringImageMainHeader = modelHeaderContentGallery.URL;
								componentCurrent._stringImageMainHeaderMobile = modelHeaderContentGallery.URL;
							}
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHeaderContentByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelHeaderContentRequest);
	}

	public callSelectProductContentByAttributesForCustomer(componentCurrent: ProductListComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductContentRequest.DeviceType = ENUM_DEVICE_TYPE.Website;

		componentCurrent._serviceProductContent.selectProductContentByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._arrayModelProductContent = [];

						let arrayModelProductContentTemp: Array<ProductContentModel> = [];
						arrayModelProductContentTemp = JSON.parse(modelResponse.Data);

						let modelProductContent: ProductContentModel;
						for(const modelProductContentTemp of arrayModelProductContentTemp)
						{
							modelProductContent = new ProductContentModel();
							modelProductContent.setModelFromObject(modelProductContentTemp);
							componentCurrent._arrayModelProductContent.push(modelProductContent);

						}
					}
					else
					{
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductContentByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelProductContentRequest);
	}

	//#endregion


	//#region NAVIGATION

	goToProductOtoYearly()
	{
		this._router.navigate(["home", "product", "oto", "yearly"]);
	}

	goToProductAsriYearly()
	{
		this._router.navigate(["home", "product", "asri", "yearly"]);
	}

	goToProductPersonalAccidentYearly()
	{
		this._router.navigate(["home", "product", "personalaccident", "yearly"]);
	}

	goToProductBicycleYearly()
	{
		this._router.navigate(["home", "product", "bicycle", "yearly"]);
	}

	goToProductOtoMonthly()
	{
		this._router.navigate(["home", "product", "oto", "monthly"]);
	}

	goToProductAsriMonthly()
	{
		this._router.navigate(["home", "product", "asri", "monthly"]);
	}

	goToProductPersonalAccidentMonthly()
	{
		this._router.navigate(["home", "product", "personalaccident", "monthly"]);
	}

	goToProductOtoDaily()
	{
		this._router.navigate(["home", "product", "oto", "daily"]);
	}

	goToProductPersonalAccidentDaily()
	{
		this._router.navigate(["home", "product", "personalaccident", "daily"]);
	}

	goToProductASMIKMyHouse()
	{
		this._router.navigate(["home", "productasmik", "myhouse", "yearly"]);
	}

	goToProductASMIKDamageOfBusinessPlace()
	{
		this._router.navigate(["home", "productasmik", "damageofbusinessplace", "yearly"]);
	}

	goToProductASMIKMyMotorcycle()
	{
		this._router.navigate(["home", "productasmik", "mymotorcycle", "yearly"]);
	}

	goToProductShophouse()
	{
		this._router.navigate(["home", "product", "shophouse", "yearly"]);
	}

	goToProductApartment()
	{
		this._router.navigate(["home", "product", "apartment", "yearly"]);
	}

	//#endregion

}
