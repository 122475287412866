//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ProductPersonalAccidentModel } from "./productpersonalaccident.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class BeneficiariesPersonalAccidentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for beneficiaries, tight to product personal accident.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.	    		Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductPersonalAccidentID?: number;

	Disclaimer?: boolean;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* BENEFICIARIES - START */

	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	BirthDate?: Date;
	BirthPlace?: string;
	Gender?: ENUM_GENDER_TYPE;
	Telephone?: string;
	MobilePhone?: string;
	Email?: string;

	IdentificationNumber?: string;
	IdentificationType?: ENUM_IDENTIFICATION_TYPE;

	Address?: string;
	CountryID?: number;
	ProvinceID?: number;
	CityID?: number;
	SubDistrictID?: number;
	VillageID?: number;
	HamletCode?: string;
	NeighbourhoodCode?: string;
	PostalCodeID?: number;
	Latitude?: number;
	Longitude?: number;

	Occupation?: string;
	Hobby?: string;
	RelationshipID?: number;
	RelationshipName?: string;
	RelationshipCode?: string;

	/* BENEFICIARIES - END */

	/* NOT MAPPED - START */

	modelProductPersonalAccident?: ProductPersonalAccidentModel;

	stringBirthDate?: string;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region GETTER

	getName(): string
	{
		let stringName: string = "";

		if (this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			stringName += StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_DASH + this.FirstName;
		}

		if (this.MiddleName == null || this.MiddleName === undefined || this.MiddleName === "")
		{

		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_DASH + this.MiddleName;
		}

		if (this.LastName == null || this.LastName === undefined || this.LastName === "")
		{

		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_DASH + this.LastName;
		}

		return stringName;
	}

	//#endregion


	//#region VALIDATION

	validateCheckForUpdateBeneficiaries(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ahli Waris");

		const regExAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExAddress: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICFORADDRESS);
		const regExNumberic: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		if(this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			modelResponse.MessageContent = "Nama depan tidak boleh kosong.";
		}
		else if(this.FirstName !== undefined && !regExAphabet.test(this.FirstName))
		{
			modelResponse.MessageContent = "Format input Nama Depan mengandung karakter yang tidak diizinkan.";
			this.FirstName = undefined;
		}
		else if(this.MiddleName && (this.MiddleName == null || this.MiddleName === undefined || this.MiddleName === "") && (!regExAphabetSpace.test(this.MiddleName)))
		{
			modelResponse.MessageContent = "Nama tengah tidak sesuai format.";
		}
		else if(this.MiddleName !== undefined && !regExAphabet.test(this.MiddleName))
		{
			modelResponse.MessageContent = "Format input Nama Tengah mengandung karakter yang tidak diizinkan.";
			this.MiddleName = undefined;
		}
		else if(this.LastName && (this.LastName == null || this.LastName === undefined || this.LastName === "") && (!regExAphabetSpace.test(this.LastName)))
		{
			modelResponse.MessageContent = "Nama tengah tidak sesuai format.";
		}
		else if(this.LastName !== undefined && !regExAphabet.test(this.LastName))
		{
			modelResponse.MessageContent = "Format input Nama Belakang mengandung karakter yang tidak diizinkan.";
			this.LastName = undefined;
		}
		else if(this.RelationshipCode == null || this.RelationshipCode === undefined || this.RelationshipCode === "")
		{
			modelResponse.MessageContent = "Hubungan tidak boleh kosong.";
		}
		else if(this.Address == null || this.Address === undefined || this.Address === "")
		{
			modelResponse.MessageContent = "Alamat tidak boleh kosong.";
		}
		else if(this.Address !== undefined && !regExAddress.test(this.Address))
		{
			modelResponse.MessageContent = "Format input Alamat mengandung karakter yang tidak diizinkan.";
			this.Address = undefined;
		}
		else if(this.MobilePhone == null || this.MobilePhone === undefined || this.MobilePhone === "")
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh kosong.";
		}
		else if(this.MobilePhone !== undefined && !regExNumberic.test(this.MobilePhone))
		{
			modelResponse.MessageContent = "Format input Nomor Telepon mengandung karakter yang tidak diizinkan.";
			this.MobilePhone = undefined;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion