//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductApartmentModel } from "../models/productapartment.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductApartmentService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region CALCULATE

	calculatePremiumForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_CALCULATEPREMIUMFORCUSTOMER);
	}

	//#endregion


	//#region INSERT

	insertProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_INSERTPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region UPDATE

	updateInsuredForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_UPDATEINSUREDFORCUSTOMER);

	}

	//#endregion


	//#region UPLOAD

	uploadProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductApartment: ProductApartmentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductApartment), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_UPLOADPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region SELECT

	selectProductByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductApartment: ProductApartmentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductApartment), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_SELECRPRODUCTBYTOKENFORCUSTOMER);

	}

	//#endregion


	//#region SAVE

	saveProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_SAVEPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region SEND

	sendSPPAByEmailForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_SENDSPPABYEMAILFORCUSTOMER);

	}

	//#endregion


	//#region PAYMENT

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductApartment: ProductApartmentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductApartment), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_CONFIRMPAYMENT);

	}

	sharePaymentForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_SHAREPAYMENTFORCUSTOMER);
	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_GENERATEPAYMENTBRIVA);
	}

	generatePaymentBNI(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_GENERATEPAYMENTBNI);
	}

	generatePaymentBCA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_GENERATEPAYMENTBCA);
	}

	generatePaymentMandiri(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_GENERATEPAYMENTMANDIRI);
	}

	generatePaymentPermata(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_GENERATEPAYMENTPERMATA);
	}

	generatePaymentQRIS(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTAPARTMENT_GENERATEPAYMENTQRIS);
	}

	generatePaymentIndomart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTINDOMARET);
	}

	generatePaymentAlfamart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTALFAMART);
	}

	generatePaymentShopeePay(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTSHOPEEPAY);
	}

	generatePaymentCreditCard(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTCREDITCARD);
	}

	//#endregion
}

//#endregion
