<div id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
		<img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormVerticalContainer DivSiteContainer">
			<div class="DivProduct">
				<h2 class="FontSoftColor"> {{ _modelHeaderContent.TitleIDN}} </h2>
			</div>
		</div>
	</section>

	<section class="SectionSecondary" id="sectionMobileApplication">
        <div class="DivSiteContainer">
			<form>
				<div class="DivForm">
					<fieldset>
						<ng-container *ngFor="let modelMobileApplication of _arrayModelMobileApplication; let numberIndex = index">
							<div class=DivFormHorizontalContainer id="divProductWithDetail" *ngIf="numberIndex % 2 === 0">
								<div class="DivForm2Column">
									<div class="DivFormVerticalContainer">
										<img class="ImageFormDetail" [src]="showImageFromURL(modelMobileApplication)">
									</div>
								</div>
								<div class="DivForm2Column">
									<div class="DivTitle2Colour">
										<h2>{{ modelMobileApplication.TitleFirst }}</h2>
										<h2>{{ modelMobileApplication.TitleSecond }}</h2>
									</div>
									<p [innerHTML]="modelMobileApplication.Description"></p>
									<div class="DivFormHorizontalContainer">
										<img *ngIf="modelMobileApplication.URLGooglePlay" class="ImageDownload" (click)="goToDownloadFromGooglePlay(modelMobileApplication.URLGooglePlay)" src="../../../assets/logos/sakina/logo_googleplay.svg">
										<img *ngIf="modelMobileApplication.URLAppStore" class="ImageDownload" (click)="goToDownloadFromAppStore(modelMobileApplication.URLAppStore)" src="../../../assets/logos/sakina/logo_appstore.svg">
									</div>
								</div>
							</div>

							<div class=DivFormHorizontalContainer id="divProductWithDetailReverse" *ngIf="numberIndex % 2 !== 0">
								<div class="DivForm2Column">
									<div class="DivFormVerticalContainer">
										<img class="ImageFormDetail" [src]="showImageFromURL(modelMobileApplication)">
									</div>
								</div>
								<div class="DivForm2Column">
									<div class="DivTitle2Colour">
										<h2>{{ modelMobileApplication.TitleFirst }}</h2>
										<h2>{{ modelMobileApplication.TitleSecond }}</h2>
									</div>
									<p [innerHTML]="modelMobileApplication.Description"></p>
									<div class="DivFormHorizontalContainer">
										<img *ngIf="modelMobileApplication.URLGooglePlay" class="ImageDownload" (click)="goToDownloadFromGooglePlay(modelMobileApplication.URLGooglePlay)" src="../../../assets/logos/sakina/logo_googleplay.svg">
										<img *ngIf="modelMobileApplication.URLAppStore" class="ImageDownload" (click)="goToDownloadFromAppStore(modelMobileApplication.URLAppStore)" src="../../../assets/logos/sakina/logo_appstore.svg">
									</div>
								</div>
							</div>
						</ng-container>
                    </fieldset>
                </div>
            </form>
        </div>
    </section>
</div>
