//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { WebAddressConstant } from "src/app/constants/webaddress.constant";
import { RegexFunction } from "src/app/functions/regex.function";
import { EmailSubscriptionModel } from "src/app/models/emailsubscription.model";
import { ResponseModel } from "src/app/models/response.model";
import { EmailSubscriptionService } from "src/app/services/emailsubscription.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-footer",
		templateUrl: "./footer.component.html",
		styleUrls: ["./footer.component.sass"],
	}
)
export class FooterComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelEmailSubscription: EmailSubscriptionModel;

	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTUR
	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router, private _serviceEmailSubscription: EmailSubscriptionService)
	{
		super(routeActivated, location, serviceSession, router);

		//#region DECLARATION

		this._modelEmailSubscription = new EmailSubscriptionModel();
		this._functionRegex = new RegexFunction();

		//#endregion
	}

	//#endregion


	//#region ONINIT


	//#endregion


	//#region AFTERVIEWINIT


	//#endregion


	//#region FUNCTION

	public checkValidationEmailSubscription(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._modelEmailSubscription.Newsletter = true;
		const modelResponseRequest: ResponseModel = this._modelEmailSubscription.validateInsert();

		if (modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			this.callUpdateEmailSubscriptionByEmail(this);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => { });
		}
	}

	//#endregion


	//#region WEBSERVICE

	private callUpdateEmailSubscriptionByEmail(componentCurrent: this): void
	{

		this._serviceEmailSubscription.updateEmailSubscriptionByEmail
		({
			success(modelResponseService: ResponseModel): void
			{
				if (modelResponseService.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._modelEmailSubscription = new EmailSubscriptionModel();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { });
				}
				else
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { });
				}
			},
			fail(modelResponseService: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponseService, () => { componentCurrent.callUpdateEmailSubscriptionByEmail(componentCurrent); });
			},
			signOut(modelResponseService: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseService, () => { componentCurrent.signOut(); });
			}
		}, this._modelEmailSubscription);
	}

	//#endregion


	//#region NAVIGATION

	goToLink(stringURL: string): void
	{
		window.open(stringURL, "_blank");
	}

	public goToTermsAndCondition(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB+"/home"+"/termsandcondition", "_blank");
	}

	public goToFAQ(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB+"/home"+"/frequentyandquestions", "_blank");
	}

	public goToPrivacyPolicy(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB+"/home"+"/privacypolicy", "_blank");
	}

	public goToContactandLocation(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB+"/home"+"/about"+"/contactandlocation", "_blank");
	}

	public goToArticle(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB+"/home"+"/brinsupdate"+"/article", "_blank");
	}

	public goToPartner(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB+"/home"+"/about"+"/partnerandclient", "_blank");
	}
	//#endregion

}
