//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { BeneficiariesPersonalAccidentModel } from "./beneficiariespersonalaccident.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class ProductPersonalAccidentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product personal accident model, used for SPPA and policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;

	Disclaimer?: boolean;
	Note?: string;
	Token?: string;

	ReferenceNumber?: string;
	Status?: ENUM_SPPA_STATUS;

	/* POLICY DETAIL - END */

	/* INSURED - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	/* INSURED - END */

	/* INSURED - START */

	InsuredFirstName?: string;
	InsuredMiddleName?: string;
	InsuredLastName?: string;
	InsuredBirthDate?: Date;
	InsuredBirthPlace?: string;
	InsuredGender?: ENUM_GENDER_TYPE;
	InsuredTelephone?: string;
	InsuredMobilePhone?: string;
	InsuredEmail?: string;

	InsuredIdentificationNumber?: string;
	InsuredIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	InsuredAddress?: string;
	InsuredCountryID?: number;
	InsuredProvinceID?: number;
	InsuredCityID?: number;
	InsuredSubDistrictID?: number;
	InsuredVillageID?: number;
	InsuredHamletCode?: string;
	InsuredNeighbourhoodCode?: string;
	InsuredPostalID?: number;
	InsuredLatitude?: number;
	InsuredLongitude?: number;
	InsuredPostalCode?: string;
	InsuredPostalName?: string;
	InsuredProvinceCode?: string;
	InsuredProvinceName?: string;
	InsuredCityCode?: string;
	InsuredCityName?: string;

	InsuredOccupationID?: number;
	InsuredOccupationCode?: string;
	InsuredOccupationName?: string;

	InsuredHobby?: string;
	InsuranceOther?: boolean;
	InsuranceRejected?: boolean;
	DangerousSport?: boolean;
	DisturbedSenses?: boolean;
	TreatmentOrSurgery?: boolean;

	stringBirthDate?: string;

	/* INSURED - END */

	/* PREMIUM - START */

	PremiumTotalAmount?: number;
	SumInsuredDefault?: number;

	/* PREMIUN - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelBeneficiariesPersonalAccident?: Array<BeneficiariesPersonalAccidentModel>;
	listModelProductPersonalAccidentGallery?: Array<ProductBaseGalleryModel>;
	modelProductPersonalAccidentPayment?: ProductBaseGalleryModel;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region FUNCTION

	setPersonalAccident(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_YEARLY_PERSONALACCIDENT;
	}

	setPersonalAccidentDaily(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY;
	}

	setPersonalAccidentMonthly(): void
	{
		this.CoverageCode = StringConstant.STRING_VALUE_PACKAGE_MONTHLY_PERSONALACCIDENT;
		this.SumInsuredDefault = 10000000;
	}

	//#endregion


	//#region GETTER

	getInsuredName(): string
	{
		let stringName: string = "";

		if (this.InsuredFirstName == null || this.InsuredFirstName === undefined || this.InsuredFirstName === "")
		{
			stringName += StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_DASH + this.InsuredFirstName;
		}

		if (this.InsuredMiddleName == null || this.InsuredMiddleName === undefined || this.InsuredMiddleName === "")
		{

		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_DASH + this.InsuredMiddleName;
		}

		if (this.InsuredLastName == null || this.InsuredLastName === undefined || this.InsuredLastName === "")
		{

		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_DASH + this.InsuredLastName;
		}

		return stringName;
	}

	//#endregion


	//#region VALIDATION

	validateCheckForUpdateInsured(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Produk Diri");

		const regExAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAILWITHOUTEMOJI);
		const regExNumberic: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const regExAddress: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICFORADDRESS);

		if(this.InsuredFirstName == null || this.InsuredFirstName === undefined || this.InsuredFirstName === "")
		{
			modelResponse.MessageContent = "Nama depan tidak boleh kosong.";
		}
		else if(!regExAphabet.test(this.InsuredFirstName))
		{
			modelResponse.MessageContent = "Format input Nama Depan mengandung karakter yang tidak diizinkan.";
			this.InsuredFirstName = undefined;
		}

		else if(this.InsuredMiddleName && (this.InsuredMiddleName == null || this.InsuredMiddleName === undefined || this.InsuredMiddleName === "") && (!regExAphabetSpace.test(this.InsuredMiddleName)))
		{
			modelResponse.MessageContent = "Nama tengah tidak sesuai format.";
		}
		else if(this.InsuredMiddleName !== undefined && !regExAphabet.test(this.InsuredMiddleName))
		{
			modelResponse.MessageContent = "Format input Nama Tengah mengandung karakter yang tidak diizinkan.";
			this.InsuredMiddleName = undefined;
		}

		else if(this.InsuredLastName && (this.InsuredLastName == null || this.InsuredLastName === undefined || this.InsuredLastName === "") && (!regExAphabetSpace.test(this.InsuredLastName)))
		{
			modelResponse.MessageContent = "Nama belakang tidak sesuai format.";
		}
		else if(this.InsuredLastName !== undefined && !regExAphabet.test(this.InsuredLastName))
		{
			modelResponse.MessageContent = "Format input Nama Belakang mengandung karakter yang tidak diizinkan.";
			this.InsuredLastName = undefined;
		}

		else if(this.InsuredBirthPlace == null || this.InsuredBirthPlace === undefined || this.InsuredBirthPlace === "")
		{
			modelResponse.MessageContent = "Tempat lahir tidak boleh kosong.";
		}
		else if(this.InsuredBirthPlace !== undefined && !regExAphabet.test(this.InsuredBirthPlace))
		{
			modelResponse.MessageContent = "Format input Tempat Lahir mengandung karakter yang tidak diizinkan.";
			this.InsuredBirthPlace = undefined;
		}

		else if(this.InsuredBirthDate == null || this.InsuredBirthDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal lahir tidak boleh kosong.";
		}
		else if(this.InsuredGender == null || this.InsuredGender === undefined)
		{
			modelResponse.MessageContent = "Jenis kelamin tidak boleh kosong.";
		}
		else if(this.InsuredIdentificationNumber == null || this.InsuredIdentificationNumber === undefined)
		{
			modelResponse.MessageContent = "KTP tidak boleh kosong.";
		}
		else if(this.InsuredIdentificationNumber.length < RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH)
		{
			modelResponse.MessageContent = "KTP tidak boleh kurang dari "+RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH+" digit.";
		}
		else if(this.InsuredIdentificationNumber.length > RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH)
		{
			modelResponse.MessageContent = "KTP tidak boleh lebih dari "+RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH+" digit.";
		}
		else if(this.InsuredIdentificationNumber !== undefined && !regExNumberic.test(this.InsuredIdentificationNumber))
		{
			modelResponse.MessageContent = "Format input KTP mengandung karakter yang tidak diizinkan.";
			this.InsuredIdentificationNumber = undefined;
		}

		else if(this.InsuredOccupationCode == null || this.InsuredOccupationCode === undefined || this.InsuredOccupationCode === "")
		{
			modelResponse.MessageContent = "Pekerjaan tidak boleh kosong.";
		}
		else if(this.InsuredAddress == null || this.InsuredAddress === undefined || this.InsuredAddress === "")
		{
			modelResponse.MessageContent = "Alamat tidak boleh kosong.";
		}
		else if(this.InsuredAddress !== undefined && !regExAddress.test(this.InsuredAddress))
		{
			modelResponse.MessageContent = "Format input Alamat mengandung karakter yang tidak diizinkan.";
			this.InsuredAddress = undefined;
		}

		else if(this.InsuredPostalCode == null || this.InsuredPostalCode === undefined || this.InsuredPostalCode ==="")
		{
			modelResponse.MessageContent = "Kode pos tidak boleh kosong.";
		}
		else if(this.InsuredPostalCode !== undefined && !regExNumberic.test(this.InsuredPostalCode))
		{
			modelResponse.MessageContent = "Format input Kode Pos mengandung karakter yang tidak diizinkan.";
			this.InsuredPostalCode = undefined;
		}

		else if(this.InsuredEmail == null || this.InsuredEmail === undefined || this.InsuredEmail ==="")
		{
			modelResponse.MessageContent = "Email tidak boleh kosong.";
		}
		else if(!regExEmail.test(this.InsuredEmail))
		{
			modelResponse.MessageContent = "Email tidak sesuai format.";
		}
		else if(this.InsuredMobilePhone == null || this.InsuredMobilePhone === undefined || this.InsuredMobilePhone === "")
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh kosong";
		}
		else if(this.InsuredMobilePhone.length <= RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh kurang dari "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM+" digit.";
		}
		else if(this.InsuredMobilePhone.length >= RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Nomor telepon tidak boleh lebih dari "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM+" digit.";
		}
		else if(this.InsuredMobilePhone !== undefined && !regExNumberic.test(this.InsuredMobilePhone))
		{
			modelResponse.MessageContent = "Format input Nomor Telepon mengandung karakter yang tidak diizinkan.";
			this.InsuredMobilePhone = undefined;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}

		return modelResponse;
	}

	validateCheckForUpdateQuestionnaire(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Kuisioner");
		if((this.InsuranceOther == null || this.InsuranceOther === undefined) || (this.InsuranceRejected == null || this.InsuranceRejected === undefined) || (this.DangerousSport == null || this.DangerousSport === undefined) || (this.DisturbedSenses == null || this.DisturbedSenses === undefined) || this.TreatmentOrSurgery == null || this.TreatmentOrSurgery === undefined)
		{
			modelResponse.MessageContent = "Kuisioner harus diisi.";
		}
		else if(this.DangerousSport)
		{
			modelResponse.MessageContent = "Olahraga berbahaya tidak dalam cakupan asuransi sehingga proses tidak dapat dilanjutkan.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}
		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setProgressforDraf(): number
	{
		let numberProgress: number = 0;
		if(this.InsuredFirstName == null || this.InsuredFirstName === undefined || this.InsuredFirstName === "")
		{
			numberProgress = 20;
		}
		else if(this.listModelBeneficiariesPersonalAccident == null || this.listModelBeneficiariesPersonalAccident === undefined || this.listModelBeneficiariesPersonalAccident.length === 0)
		{
			numberProgress = 40;
		}
		else if(this.DangerousSport == null || this.DangerousSport === undefined)
		{
			numberProgress = 60;
		}
		else if(this.DangerousSport != null || this.DangerousSport !== undefined)
		{
			numberProgress = 80;
		}
		else
		{
			numberProgress = 90;
		}

		return numberProgress;
	}

	//#endregion

}

//#endregion