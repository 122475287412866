//#region CLASS

import { ENUM_DEVICE_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { LandingContentGalleryModel } from "./landingcontentgallery.model";
import { ResponseModel } from "./response.model";

export class LandingContentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for landing model.
		Author: Dimas Dandy Aryarajendra Suprapto.
		Created on : Wednesday, 10 May 2023. 					Updated on : .
		Created by : Dimas Dandy Aryarajendra Suprapto.			Updated by : .
		Version : 1.0:1
	*/

	ID?: number;
	Token?: string;
	Title?: string;
	Description?: string;
	URLVideo?: string;
	DeviceType?: ENUM_DEVICE_TYPE;

	listModelLandingGallery?: Array<LandingContentGalleryModel>;

	/* Attribute - END */


	//#region VALIDATE

	validateInsertUpdateLanding(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Social Media";

		if(this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = "Title tidak boleh kosong.";
		}
		else if(this.Description == null || this.Description === undefined || this.Description === "")
		{
			modelResponse.MessageContent = "Deskripsi tidak boleh kosong.";
		}
		else if(this.URLVideo == null || this.URLVideo === undefined || this.URLVideo === "")
		{
			modelResponse.MessageContent = "URL Video tidak boleh kosong.";
		}
		else if(this.listModelLandingGallery === undefined || this.listModelLandingGallery?.length === 0)
		{
			modelResponse.MessageContent = "Gambar tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setModelRequestInsertLandingContent(modelLandingRequest: LandingContentModel): LandingContentModel
	{
		const modelLanding = new LandingContentModel();

		modelLanding.Title = modelLandingRequest.Title;
		modelLanding.Description = modelLandingRequest.Description;
		modelLanding.URLVideo = modelLandingRequest.URLVideo;
		modelLanding.listModelLandingGallery = modelLandingRequest.listModelLandingGallery;

		return modelLanding;
	}

	setModelRequestUpdateLandingContent(modelLandingRequest: LandingContentModel): LandingContentModel
	{
		const modelLanding = new LandingContentModel();

		modelLanding.Token = modelLandingRequest.Token;
		modelLanding.Title = modelLandingRequest.Title;
		modelLanding.Description = modelLandingRequest.Description;
		modelLanding.URLVideo = modelLandingRequest.URLVideo;
		modelLanding.listModelLandingGallery = modelLandingRequest.listModelLandingGallery;

		return modelLanding;
	}

	//#endregion
}

//#endregion