<div id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
        <img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
        <div class="DivFormVerticalContainer DivSiteContainer">
            <div class="DivProduct">
                <h2 class="FontSoftColor"> {{ _modelHeaderContent.TitleIDN }} </h2>
            </div>
        </div>
	</section>

	<section class="SectionSecondary" id="sectionProductListContainer">
        <div class="DivSiteContainer">
            <h1>{{ _stringConstant.STRING_CONTENT_TITLE_SEEALLPRODUCT }}</h1>
            <h3>{{ _stringConstant.STRING_CONTENT_SUBTITTLE_YEARLY }}</h3>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
				<!-- Product ASRI, OTO, Bicycle, Personal Accident -->
				<ng-container *ngFor="let modelProductContent of _arrayModelProductContent">
					<div class="DivContentBox DivRelativeContainer" id="divContentProductList" *ngIf="
					modelProductContent.ProductType === _enumProductContentType.OtoYearly || modelProductContent.ProductType === _enumProductContentType.AsriYearly || modelProductContent.ProductType === _enumProductContentType.PersonalAccidentYearly || modelProductContent.ProductType === _enumProductContentType.BicycleYearly || modelProductContent.ProductType === _enumProductContentType.Shophouse || modelProductContent.ProductType === _enumProductContentType.Apartment">
						<div class="DivTitleContentHeader" *ngFor="let modelProductContentGallery of modelProductContent.listModelProductContentGallery">
							<img [src]="getImage(modelProductContentGallery.URL!)">
							<label>{{ modelProductContent.TitleIDN }}</label>
						</div>
						<p>{{ modelProductContent.DescriptionIDN }}</p>
						<button class="ButtonSubmit" (click)="getProductContentTypeForNavigate(modelProductContent.ProductType!)"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
					</div>
				</ng-container>
            </div>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
				<!-- Product ASMIK -->
				<ng-container *ngFor="let modelProductContent of _arrayModelProductContent">
					<div class="DivContentBox DivRelativeContainer" id="divContentProductList" *ngIf="
					modelProductContent.ProductType === _enumProductContentType.AsmikMyHouseYearly || modelProductContent.ProductType === _enumProductContentType.AskmikDamageOfBusinesPlaceYearly || modelProductContent.ProductType === _enumProductContentType.AsmikMyMotorcycleYearly">
						<div class="DivTitleContentHeader" *ngFor="let modelProductContentGallery of modelProductContent.listModelProductContentGallery">
							<img [src]="getImage(modelProductContentGallery.URL!)">
							<label>{{ modelProductContent.TitleIDN }}</label>
						</div>
						<p>{{ modelProductContent.DescriptionIDN }}</p>
						<button class="ButtonSubmit" (click)="getProductContentTypeForNavigate(modelProductContent.ProductType!)"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
					</div>
				</ng-container>
            </div>
        </div>
        <div class="DivSiteContainer">
            <h3>{{ _stringConstant.STRING_CONTENT_SUBTITTLE_MONTHLY }}</h3>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
				<ng-container *ngFor="let modelProductContent of _arrayModelProductContent">
					<div class="DivContentBox DivRelativeContainer" id="divContentProductList" *ngIf="modelProductContent.ProductPeriod === _enumPeriodType.Monthly">
						<div class="DivTitleContentHeader" *ngFor="let modelProductContentGallery of modelProductContent.listModelProductContentGallery">
							<img [src]="getImage(modelProductContentGallery.URL!)">
							<label>{{ modelProductContent.TitleIDN }}</label>
						</div>
						<p>{{ modelProductContent.DescriptionIDN }}</p>
						<button class="ButtonSubmit" (click)="getProductContentTypeForNavigate(modelProductContent.ProductType!)"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
					</div>
				</ng-container>
            </div>
        </div>
        <div class="DivSiteContainer">
            <h3>{{ _stringConstant.STRING_CONTENT_SUBTITTLE_DAILY }}</h3>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
				<ng-container *ngFor="let modelProductContent of _arrayModelProductContent">
					<div class="DivContentBox DivRelativeContainer" id="divContentProductList" *ngIf="modelProductContent.ProductPeriod === _enumPeriodType.Daily">
						<div class="DivTitleContentHeader" *ngFor="let modelProductContentGallery of modelProductContent.listModelProductContentGallery">
							<img [src]="getImage(modelProductContentGallery.URL!)">
							<label>{{ modelProductContent.TitleIDN }}</label>
						</div>
						<p>{{ modelProductContent.DescriptionIDN }}</p>
						<img class="ImageLabel" src="../../../assets/logos/sakina/logo_greensurance_new.svg">
						<button class="ButtonSubmit" (click)="getProductContentTypeForNavigate(modelProductContent.ProductType!)"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
					</div>
				</ng-container>
            </div>
        </div>
    </section>
</div>
