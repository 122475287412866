//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { ENUM_GENDER_TYPE, ENUM_HTTP_STATUS, ENUM_PRODUCT_LOCATIONINFORMATION, ENUM_PRODUCT_OWNERSHIPINFORMATION, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { BeneficiariesDamageOfBusinessPlaceModel } from "src/app/models/beneficiariesdamageofbusinessplace.model";
import { BeneficiariesMyHouseModel } from "src/app/models/beneficiariesmyhouse.model";
import { BeneficiariesMyMotorcycleModel } from "src/app/models/beneficiariesmymotorcycle.model";
import { BeneficiariesPersonalAccidentModel } from "src/app/models/beneficiariespersonalaccident.model";
import { ProductApartmentModel } from "src/app/models/productapartment.model";
import { ProductAsriModel } from "src/app/models/productasri.model";
import { ProductBicycleModel } from "src/app/models/productbicycle.model";
import { ProductDamageOfBusinessPlaceModel } from "src/app/models/productdamageofbusinessplace.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ProductMyHouseModel } from "src/app/models/productmyhouse.model";
import { ProductMyMotorcycleModel } from "src/app/models/productmymotorcycle.model";
import { ProductOtoModel } from "src/app/models/productoto.model";
import { ProductPersonalAccidentModel } from "src/app/models/productpersonalaccident.model";
import { ProductShophouseModel } from "src/app/models/productshophouse.model";
import { ResponseModel } from "src/app/models/response.model";
import { ProductApartmentService } from "src/app/services/productapartment.service";
import { ProductasriService } from "src/app/services/productasri.service";
import { ProductBicycleService } from "src/app/services/productbicycle.service";
import { ProductDamageOfBusinessPlaceService } from "src/app/services/productdamageofbusinessplace.service";
import { ProductGeneralService } from "src/app/services/productgeneral.service";
import { ProductMyHouseService } from "src/app/services/productmyhouse.service";
import { ProductMyMotorcycleService } from "src/app/services/productmymotorcycle.service";
import { ProductotoService } from "src/app/services/productoto.service";
import { ProductpersonalaccidentService } from "src/app/services/productpersonalaccident.service";
import { ProductShophouseService } from "src/app/services/productshophouse.service";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-checkout",
		templateUrl: "./checkout.component.html",
		styleUrls: ["./checkout.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class CheckoutComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;
	public _stringConstant = StringConstant;
	public _stringToken: string;
	public _stringTokenURL: string;
	public _stringPaymentMethod: string;
	public _stringPaymentMethodSelected: string;
	public _stringProductCode: string;
	public _stringProductASRIYearly: string = StringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY;
	public _stringProductASRIMonthly: string = StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLY;
	public _stringProductOTOYearly: string = StringConstant.STRING_VALUE_PRODUCT_OTO;
	public _stringProductOTOMonthlyDaily: string = StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY;
	public _stringProductPersonalAccident: string = StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT;
	public _stringProductBicycle: string = StringConstant.STRING_VALUE_PRODUCT_BICYCLE;
	public _stringProductDamageOfBusinessPlace: string = StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE;
	public _stringProductMyHouse: string = StringConstant.STRING_VALUE_PRODUCT_MYHOUSE;
	public _stringProductMyMotorcycle: string = StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE;
	public _stringProductShophouse: string = StringConstant.STRING_VALUE_PRODUCT_SHOPHOUSE;
	public _stringProductApartment: string = StringConstant.STRING_VALUE_PRODUCT_APARTMENT;
	public _stringBRIVA: string = StringConstant.STRING_VALUE_PAYMENT_BRIVA;
	public _stringBNI: string = StringConstant.STRING_VALUE_PAYMENT_BNI;
	public _stringBCA: string = StringConstant.STRING_VALUE_PAYMENT_BCA;
	public _stringPermata: string = StringConstant.STRING_VALUE_PAYMENT_PERMATA;
	public _stringMandiri: string = StringConstant.STRING_VALUE_PAYMENT_MANDIRI;
	public _stringQRIS: string = StringConstant.STRING_VALUE_PAYMENT_QRIS;
	public _stringIndomart: string = StringConstant.STRING_VALUE_PAYMENT_INDOMART;
	public _stringAlfamart: string = StringConstant.STRING_VALUE_PAYMENT_ALFAMART;
	public _stringCoverageCodeOTOMonthly1 = StringConstant.STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_COMPREHENSIVE;
	public _stringCoverageCodeOTOMonthly2 = StringConstant.STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_TLO;
	public _stringCoverageCodeOTODaily = StringConstant.STRING_VALUE_PACKAGE_DAILY_OTO;
	public _stringCoverageCodeDIRIMonthly = StringConstant.STRING_VALUE_PACKAGE_MONTHLY_PERSONALACCIDENT;
	public _stringCoverageCodeDIRIDaily = StringConstant.STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY;

	public _enumGenderType = ENUM_GENDER_TYPE;
	public _enumProductOwnershipInformation = ENUM_PRODUCT_OWNERSHIPINFORMATION;
	public _enumProductLocationInformation = ENUM_PRODUCT_LOCATIONINFORMATION;

	public _booleanDetail: boolean;
	public _booleanValidationVirtualAccount: boolean;

	public _modelProductGeneral: ProductGeneralModel;
	public _modelProductGeneralPayment: ProductGeneralModel;
	public _modelProductAsriRequest: ProductAsriModel;
	public _modelProductOtoRequest: ProductOtoModel;
	public _modelProductPersonalAccidentRequest: ProductPersonalAccidentModel;
	public _modelProductBicycleRequest: ProductBicycleModel;
	public _modelProductPersonalDamageOfBusinessPlace: ProductDamageOfBusinessPlaceModel;
	public _modelProductMyHouseRequest: ProductMyHouseModel;
	public _modelProductMyMotorcycleRequest: ProductMyMotorcycleModel;
	public _modelProductShophouseRequest: ProductShophouseModel;
	public _modelProductApartmentRequest: ProductApartmentModel;
	public _modelBeneficiariesPersonalAccident: BeneficiariesPersonalAccidentModel;
	public _modelBeneficiariesMyMotorcycle: BeneficiariesMyMotorcycleModel;

	public _arrayModelBeneficiariesPersonalAccident: Array<BeneficiariesPersonalAccidentModel>;
	public _arrayModelBeneficiariesDamageOfBusinessPlace: Array<BeneficiariesDamageOfBusinessPlaceModel>;
	public _arrayModelBeneficiariesMyHouse: Array<BeneficiariesMyHouseModel>;
	public _arrayModelBeneficiariesMyMotorcycle: Array<BeneficiariesMyMotorcycleModel>;

	//#endregion


	//#region CONSTRUCTUR

	// eslint-disable-next-line max-len
	constructor(serviceSession: SessionService, router: Router, private _serviceProductAsri: ProductasriService, private _serviceProductOto: ProductotoService, private _serviceProductPersonalAccident: ProductpersonalaccidentService, private _serviceProductDamageOfBusinessPlace: ProductDamageOfBusinessPlaceService, private _serviceProductMyHouse: ProductMyHouseService, private _serviceProductMyMotorcycle: ProductMyMotorcycleService, private _serviceProductBicycle: ProductBicycleService, private _serviceProductGeneral: ProductGeneralService, private _serviceProductShophouse: ProductShophouseService, private _serviceProductApartment: ProductApartmentService)
	{
		super(serviceSession, router);
		this._stringImageMainHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_checkout_insurtech.png");
		this._stringImageMainHeaderMobile = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_checkout_insurtech_mobile.jpg");
		this._modelProductGeneral = new ProductGeneralModel();
		this._modelProductGeneralPayment = new ProductGeneralModel();
		this._modelProductPersonalDamageOfBusinessPlace = new ProductDamageOfBusinessPlaceModel();
		this._modelProductAsriRequest = new ProductAsriModel();
		this._modelProductOtoRequest = new ProductOtoModel();
		this._modelProductPersonalAccidentRequest = new ProductPersonalAccidentModel();
		this._modelProductBicycleRequest = new ProductBicycleModel();
		this._modelProductMyHouseRequest = new ProductMyHouseModel();
		this._modelProductMyMotorcycleRequest = new ProductMyMotorcycleModel();
		this._modelProductShophouseRequest = new ProductShophouseModel();
		this._modelProductApartmentRequest = new ProductApartmentModel();
		this._modelBeneficiariesPersonalAccident = new BeneficiariesPersonalAccidentModel();
		this._modelBeneficiariesMyMotorcycle = new BeneficiariesMyMotorcycleModel();
		this._booleanDetail = false;
		this._stringPaymentMethod = "briva";
		this._stringPaymentMethodSelected = "BRIVA";
		this._stringToken = "";
		this._stringTokenURL = "";
		this._stringProductCode = "";
		this._booleanValidationVirtualAccount = false;
		this._arrayModelBeneficiariesPersonalAccident = [];
		this._arrayModelBeneficiariesDamageOfBusinessPlace = [];
		this._arrayModelBeneficiariesMyHouse = [];
		this._arrayModelBeneficiariesMyMotorcycle = [];
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		const modelResponse: ResponseModel = this._modelUserSignIn.checkSessionSignin();
		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			this._stringTokenURL = this.getTokenFromURLParamter();
			// this._stringProductCode = this._stringTokenURL?.slice(this._stringTokenURL.length - NumberConstant.NUMBER_VALUE_PRODUCT_CODE_LENGTH);
			// this._stringToken = this._stringTokenURL?.slice(0, (this._stringTokenURL.length - NumberConstant.NUMBER_VALUE_PRODUCT_CODE_LENGTH));

			this.callSelectProductGeneralByCustomer();
			// this.checkProductCodeforSelectProduct();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse , () => { this.signIn(); });
		}
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region WEB SERVICES

	private callSelectProductGeneralByCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(3);
		const modelProductGeneral: ProductGeneralModel = new ProductGeneralModel();
		modelProductGeneral.cleanForGeneratePayment();
		modelProductGeneral.Token = componentCurrent._stringTokenURL;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductGeneral.selectProductGeneralByCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);

							componentCurrent.checkProductCode();
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductGeneralByCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelProductGeneral);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductAsriByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductAsriRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductAsri();
							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductAsriByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductAsriRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductOtoByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductOtoRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductOto();
							componentCurrent.validateAccountForPayment();

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductOtoByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductOtoRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductPersonalAccidentByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductPersonalAccidentRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelBeneficiariesPersonalAccident = new BeneficiariesPersonalAccidentModel();

							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductPersonalAccident();
							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if(componentCurrent._modelProductGeneral.modelProductPersonalAccident?.listModelBeneficiariesPersonalAccident !== undefined && componentCurrent._modelProductGeneral.modelProductPersonalAccident?.listModelBeneficiariesPersonalAccident.length !== 0)
							{
								componentCurrent._arrayModelBeneficiariesPersonalAccident = componentCurrent._modelProductGeneral.modelProductPersonalAccident?.listModelBeneficiariesPersonalAccident;
							}

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductPersonalAccidentByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductPersonalAccidentRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductBicycleByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductBicycleRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelBeneficiariesPersonalAccident = new BeneficiariesPersonalAccidentModel();

							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductBicycle();
							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}

							// if(componentCurrent._modelProductGeneral.modelProductPersonalAccident?.listModelBeneficiariesPersonalAccident !== undefined && componentCurrent._modelProductGeneral.modelProductPersonalAccident?.listModelBeneficiariesPersonalAccident.length !== 0)
							// {
							// 	componentCurrent._arrayModelBeneficiariesPersonalAccident = componentCurrent._modelProductGeneral.modelProductPersonalAccident?.listModelBeneficiariesPersonalAccident;
							// }
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductBicycleByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductBicycleRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductShophouseByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductShophouseRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductShophouse();
							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductShophouseByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductShophouseRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductApartmentByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductApartmentRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductApartment();
							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductApartmentByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductApartmentRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductDamageOfBusinessPlaceByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductPersonalDamageOfBusinessPlace.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelBeneficiariesPersonalAccident = new BeneficiariesPersonalAccidentModel();

							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductDamageOfBusinessPlace();
							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if(componentCurrent._modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace !== undefined && componentCurrent._modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace.length !== 0)
							{
								componentCurrent._arrayModelBeneficiariesDamageOfBusinessPlace = componentCurrent._modelProductGeneral.modelProductDamageOfBusinessPlace?.listModelBeneficiariesDamageOfBusinessPlace;
							}

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductDamageOfBusinessPlaceByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductPersonalDamageOfBusinessPlace);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductMyHouseByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductMyHouseRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelBeneficiariesPersonalAccident = new BeneficiariesPersonalAccidentModel();

							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductMyHouse();
							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if(componentCurrent._modelProductGeneral.modelProductMyHouse?.listModelBeneficiariesMyHouse !== undefined && componentCurrent._modelProductGeneral.modelProductMyHouse?.listModelBeneficiariesMyHouse.length !== 0)
							{
								componentCurrent._arrayModelBeneficiariesMyHouse = componentCurrent._modelProductGeneral.modelProductMyHouse?.listModelBeneficiariesMyHouse;
							}

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductMyHouseByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductMyHouseRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callSelectProductMyMotorcycleByTokenForCustomer(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductMyMotorcycleRequest.Token = componentCurrent._stringToken;

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.selectProductByTokenForCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelBeneficiariesMyMotorcycle = new BeneficiariesMyMotorcycleModel();

							componentCurrent._modelProductGeneral = new ProductGeneralModel();
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);
							componentCurrent._modelProductGeneral.setModelProductMyMotorcycle();

							componentCurrent.validateAccountForPayment();
							componentCurrent._functionUserInterface.updateLoadingProgress();

							if(componentCurrent._modelProductGeneral.modelProductMyMotorcycle?.listModelBeneficiariesMyMotorcycle !== undefined && componentCurrent._modelProductGeneral.modelProductMyMotorcycle?.listModelBeneficiariesMyMotorcycle.length !== 0)
							{
								componentCurrent._arrayModelBeneficiariesMyMotorcycle = componentCurrent._modelProductGeneral.modelProductMyMotorcycle?.listModelBeneficiariesMyMotorcycle;
							}

							if (componentCurrent._modelProductGeneral.AdministrationAmount !== undefined && componentCurrent._modelProductGeneral.StampAmount !== undefined)
							{
								componentCurrent._modelProductGeneral.PremiumTotalAdministrationAmount = componentCurrent._modelProductGeneral.AdministrationAmount + componentCurrent._modelProductGeneral.StampAmount;
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductMyHouseByTokenForCustomer(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductMyMotorcycleRequest);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentBRIVAASRI(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVAASRI(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVAOTO(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVAOTO(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVAPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVAPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVABicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVABicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVAShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVAShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVAApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVAApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVADamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVADamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVAMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVAMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBRIVAMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentBRIVA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBRIVAMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentBNIASRI(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIASRI(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIOTO(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIOTO(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIBicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIBicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIDamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIDamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBNIMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentBNI
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBNIMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentMandiriASRI(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriASRI(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriOTO(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriOTO(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriBicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriBicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriDamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriDamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentMandiriMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentMandiri
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentMandiriMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentBCAASRI(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCAASRI(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCAOTO(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCAOTO(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCAPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCAPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCABicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCABicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCADamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCADamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCAMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCAMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCAMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCAMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCAShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCAShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentBCAApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentBCA
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentBCAApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentPermataASRI(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataASRI(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataOTO(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataOTO(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataBicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataBicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataDamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataDamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentPermataMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentPermata
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentPermataMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentQRISASRI(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISASRI(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISOTO(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISOTO(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISBicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISBicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISDamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISDamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentQRISMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentQRIS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentQRISMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentAlfamartAsri(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartAsri(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartOto(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartOto(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartDamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartDamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartBicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartBicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentAlfamartMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentAlfamart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentAlfamartMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//-----------------------------------------------------------------

	private callGeneratePaymentIndomartAsri(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductAsri.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartAsri(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartOto(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductOto.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartOto(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartPersonalAccident(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductPersonalAccident.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartPersonalAccident(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartBicycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductBicycle.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartBicycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartShophouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductShophouse.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartShophouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartApartment(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductApartment.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartApartment(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartDamageOfBusinessPlace(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductDamageOfBusinessPlace.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartDamageOfBusinessPlace(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartMyHouse(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyHouse.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartMyHouse(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	private callGeneratePaymentIndomartMyMotorcycle(): void
	{
		const componentCurrent: CheckoutComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelProductGeneralPayment = new ProductGeneralModel();
		componentCurrent._modelProductGeneralPayment.Token = componentCurrent._modelProductGeneral.Token;
		componentCurrent._modelProductGeneralPayment.cleanForGeneratePayment();

		if (this._modelUserSignIn.getName() !== null && this._modelUserSignIn.getName() !== undefined && this._modelUserSignIn.getName() !== "")
		{
			this._serviceProductMyMotorcycle.generatePaymentIndomart
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.HTTPResponseCode === ENUM_HTTP_STATUS.Ok)
					{
						componentCurrent.goToPayment();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callGeneratePaymentIndomartMyMotorcycle(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralPayment);
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Pengguna tidak ditemukan!";
			modelResponse.MessageContent = "Untuk melanjutkan ke langkah selanjutnya, Anda harus login terlebih dahulu!";
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["/signin"]); });
		}
	}

	//#endregion


	//#region FUNCTION

	public generatePayment(): void
	{
		if ((this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductASRIYearly) ||
		((this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOM)
		|| (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOMEARTHQUAKE)
		|| (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_ASRICUSTOM_COVERAGECUSTOMTERORISMSABOTAGE)))
		{
			this.callGeneratePaymentBRIVAASRI();
		}
		else if ((this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductOTOYearly) || (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductOTOMonthlyDaily))
		{
			this.callGeneratePaymentBRIVAOTO();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductPersonalAccident)
		{
			this.callGeneratePaymentBRIVAPersonalAccident();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductBicycle)
		{
			this.callGeneratePaymentBRIVABicycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentBRIVAShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentBRIVAApartment();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductDamageOfBusinessPlace)
		{
			this.callGeneratePaymentBRIVADamageOfBusinessPlace();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductMyHouse)
		{
			this.callGeneratePaymentBRIVAMyHouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductMyMotorcycle)
		{
			this.callGeneratePaymentBRIVAMyMotorcycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse && this._modelProductGeneral.modelProductShophouse.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentBRIVAShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringBRIVA && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment && this._modelProductGeneral.modelProductApartment.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentBRIVAApartment();
		}
		else if ((this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductASRIYearly) ||
		((this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOM)
		|| (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOMEARTHQUAKE)
		|| (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_ASRICUSTOM_COVERAGECUSTOMTERORISMSABOTAGE)))
		{
			this.callGeneratePaymentBNIASRI();
		}
		else if ((this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductOTOYearly) || ( this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductOTOMonthlyDaily))
		{
			this.callGeneratePaymentBNIOTO();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductPersonalAccident)
		{
			this.callGeneratePaymentBNIPersonalAccident();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductBicycle)
		{
			this.callGeneratePaymentBNIBicycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentBNIShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentBNIApartment();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductDamageOfBusinessPlace)
		{
			this.callGeneratePaymentBNIDamageOfBusinessPlace();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductMyHouse)
		{
			this.callGeneratePaymentBNIMyHouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductMyMotorcycle)
		{
			this.callGeneratePaymentBNIMyMotorcycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse && this._modelProductGeneral.modelProductShophouse.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentBNIShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringBNI && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment && this._modelProductGeneral.modelProductApartment.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentBNIApartment();
		}
		else if ((this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductASRIYearly) ||
		((this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOM)
		|| (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOMEARTHQUAKE)
		|| (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_ASRICUSTOM_COVERAGECUSTOMTERORISMSABOTAGE)))
		{
			this.callGeneratePaymentMandiriASRI();
		}
		else if ((this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductOTOYearly) || ( this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductOTOMonthlyDaily))
		{
			this.callGeneratePaymentMandiriOTO();
		}
		else if (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductPersonalAccident)
		{
			this.callGeneratePaymentMandiriPersonalAccident();
		}
		else if (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductBicycle)
		{
			this.callGeneratePaymentMandiriBicycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentMandiriShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentMandiriApartment();
		}
		else if (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductDamageOfBusinessPlace)
		{
			this.callGeneratePaymentMandiriDamageOfBusinessPlace();
		}
		else if (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductMyHouse)
		{
			this.callGeneratePaymentMandiriMyHouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringMandiri && this._stringProductCode === this._stringProductMyMotorcycle)
		{
			this.callGeneratePaymentMandiriMyMotorcycle();
		}
		else if ((this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductASRIYearly) ||
		((this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOM)
		|| (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOMEARTHQUAKE)
		|| (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_ASRICUSTOM_COVERAGECUSTOMTERORISMSABOTAGE)))
		{
			this.callGeneratePaymentPermataASRI();
		}
		else if ((this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductOTOYearly) || ( this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductOTOMonthlyDaily))
		{
			this.callGeneratePaymentPermataOTO();
		}
		else if (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductPersonalAccident)
		{
			this.callGeneratePaymentPermataPersonalAccident();
		}
		else if (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductBicycle)
		{
			this.callGeneratePaymentPermataBicycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentPermataShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentPermataApartment();
		}
		else if (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductDamageOfBusinessPlace)
		{
			this.callGeneratePaymentPermataDamageOfBusinessPlace();
		}
		else if (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductMyHouse)
		{
			this.callGeneratePaymentPermataMyHouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringPermata && this._stringProductCode === this._stringProductMyMotorcycle)
		{
			this.callGeneratePaymentPermataMyMotorcycle();
		}
		else if ((this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductASRIYearly) ||
		((this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOM)
		|| (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOMEARTHQUAKE)
		|| (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_ASRICUSTOM_COVERAGECUSTOMTERORISMSABOTAGE)))
		{
			this.callGeneratePaymentQRISASRI();
		}
		else if ((this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductOTOYearly) || ( this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductOTOMonthlyDaily))
		{
			this.callGeneratePaymentQRISOTO();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductPersonalAccident)
		{
			this.callGeneratePaymentQRISPersonalAccident();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductBicycle)
		{
			this.callGeneratePaymentQRISBicycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentQRISShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentQRISApartment();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductDamageOfBusinessPlace)
		{
			this.callGeneratePaymentQRISDamageOfBusinessPlace();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductMyHouse)
		{
			this.callGeneratePaymentQRISMyHouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductMyMotorcycle)
		{
			this.callGeneratePaymentQRISMyMotorcycle();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse && this._modelProductGeneral.modelProductShophouse.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callGeneratePaymentQRISShophouse();
		}
		else if (this._stringPaymentMethodSelected === this._stringQRIS && this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment && this._modelProductGeneral.modelProductApartment.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callGeneratePaymentQRISApartment();
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.setReturnTypePaymentNotFound();
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
		}

	}

	private checkProductCode(): void
	{
		if(this._modelProductGeneral && this._modelProductGeneral.ProductCode)
		{
			this._stringProductCode = this._modelProductGeneral.ProductCode;

			if(this._modelProductGeneral.modelProductOto && this._modelProductGeneral.modelProductOto.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductOto.Token;
			}
			else if(this._modelProductGeneral.modelProductAsri && this._modelProductGeneral.modelProductAsri.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductAsri.Token;
			}
			else if(this._modelProductGeneral.modelProductPersonalAccident && this._modelProductGeneral.modelProductPersonalAccident.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductPersonalAccident.Token;
			}
			else if(this._modelProductGeneral.modelProductBicycle && this._modelProductGeneral.modelProductBicycle.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductBicycle.Token;
			}
			else if(this._modelProductGeneral.modelProductShophouse && this._modelProductGeneral.modelProductShophouse.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductShophouse.Token;
			}
			else if(this._modelProductGeneral.modelProductApartment && this._modelProductGeneral.modelProductApartment.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductApartment.Token;
			}
			else if(this._modelProductGeneral.modelProductDamageOfBusinessPlace && this._modelProductGeneral.modelProductDamageOfBusinessPlace.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductDamageOfBusinessPlace.Token;
			}
			else if(this._modelProductGeneral.modelProductMyMotorcycle && this._modelProductGeneral.modelProductMyMotorcycle.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductMyMotorcycle.Token;
			}
			else if(this._modelProductGeneral.modelProductMyHouse && this._modelProductGeneral.modelProductMyHouse.Token)
			{
				this._stringToken = this._modelProductGeneral.modelProductMyHouse.Token;
			}
			else
			{

			}
		}
		else
		{

		}

		if(this._stringProductCode != null || this._stringProductCode !== undefined || this._stringProductCode !== "")
		{
			this._functionUserInterface.updateLoadingProgress();
			this.checkProductCodeforSelectProduct();
		}
	}

	private checkProductCodeforSelectProduct(): void
	{

		if (this._stringProductCode === this._stringProductASRIYearly ||
			(this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLY)
			&& (this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLYCUSTOMPSAGBI)
			&& (this._stringProductCode === this._stringProductASRIMonthly && this._modelProductGeneral.modelProductAsri?.CoverageCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLYCUSTOMTEROBMC))
		{
			this.callSelectProductAsriByTokenForCustomer();
		}
		else if (this._stringProductCode === this._stringProductOTOYearly || this._stringProductCode === this._stringProductOTOMonthlyDaily)
		{
			this.callSelectProductOtoByTokenForCustomer();
		}
		else if (this._stringProductCode === this._stringProductPersonalAccident)
		{
			this.callSelectProductPersonalAccidentByTokenForCustomer();
		}
		else if (this._stringProductCode === this._stringProductBicycle)
		{
			this.callSelectProductBicycleByTokenForCustomer();
		}
		else if (this._stringProductCode === this._stringProductShophouse && this._modelProductGeneral.modelProductShophouse?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_SHOPHOUSE)
		{
			this.callSelectProductShophouseByTokenForCustomer();
		}
		else if (this._stringProductCode === this._stringProductApartment && this._modelProductGeneral.modelProductApartment?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_APARTMENT)
		{
			this.callSelectProductApartmentByTokenForCustomer();
		}
		else if (this._stringProductCode === this._stringProductDamageOfBusinessPlace)
		{
			this.callSelectProductDamageOfBusinessPlaceByTokenForCustomer();
		}
		else if (this._stringProductCode === this._stringProductMyHouse)
		{
			this.callSelectProductMyHouseByTokenForCustomer();
		}
		else if(this._stringProductCode === this._stringProductMyMotorcycle)
		{
			this.callSelectProductMyMotorcycleByTokenForCustomer();
		}
		else
		{

		}
	}

	private validateAccountForPayment(): void
	{
		if(this._modelProductGeneral && this._modelProductGeneral.modelVirtualAccount && this._modelProductGeneral.modelThirdPartyPayment)
		{
			this._booleanValidationVirtualAccount = false;
		}
		else
		{
			this._booleanValidationVirtualAccount = true;
		}
	}

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}

	toggleDetail(): void
	{
		this._booleanDetail = !this._booleanDetail;
	}

	toggleClassPaymentMethod(stringSelected): void
	{
		this._stringPaymentMethod = stringSelected;
		if(this._stringPaymentMethod === "briva")
		{
			this.toggleClassPaymentSelectedMethod(this._stringBRIVA);
		}
		else if(this._stringPaymentMethod === "ewallet")
		{
			this.toggleClassPaymentSelectedMethod(this._stringQRIS);
		}
		else if(this._stringPaymentMethod === "atm")
		{
			this.toggleClassPaymentSelectedMethod(this._stringBNI);
		}
		else if(this._stringPaymentMethod === "retail")
		{
			this.toggleClassPaymentSelectedMethod(this._stringAlfamart);
		}
	}

	toggleClassPaymentSelectedMethod(stringSelected): void
	{
		this._stringPaymentMethodSelected = stringSelected;
	}

	getTokenFromURLParamter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];
		return stringToken;
	}

	//#endregion


	//#region NAVIGATION

	goToPayment()
	{
		this._router.navigate(["home", "product", "payment", this._modelProductGeneral.Token]);
	}

	//#endregion

}
