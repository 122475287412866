//#region CLASS

import { ENUM_DEVICE_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { MobileApplicationGalleryModel } from "./mobileapplicationgallery.model";
import { ResponseModel } from "./response.model";

export class MobileApplicationModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for mobile application center model.
		Author: Dimas Dandy Aryarajendra Suprapto.
		Created on : Firday, 5 May 2023. 					Updated on : .
		Created by : Dimas Dandy Aryarajendra Suprapto.		Updated by : .
		Version : 1.0:1
	*/

	ID?: number;
	Token?: string;
	Priority?: number;
	TitleFirst?: string;
	TitleSecond?: string;
	Description?: string;
	URLGooglePlay?: string;
	URLAppStore?: string;
	DeviceType?: ENUM_DEVICE_TYPE;

	listModelMobileApplicationGallery?: Array<MobileApplicationGalleryModel>;

	/* Attribute - END */


	// //#region VALIDATE

	validateInsertUpdateMobileApplication(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Social Media";

		if(this.TitleFirst == null || this.TitleFirst === undefined || this.TitleFirst === "" || this.TitleSecond == null || this.TitleSecond === undefined || this.TitleSecond === "")
		{
			modelResponse.MessageContent = "Title tidak boleh kosong.";
		}
		else if(this.Description == null || this.Description === undefined || this.Description === "")
		{
			modelResponse.MessageContent = "Konten tidak boleh kosong.";
		}
		else if(this.DeviceType == null || this.DeviceType === undefined)
		{
			modelResponse.MessageContent = "Platform tidak boleh kosong.";
		}
		else if(this.Priority == null || this.Priority === undefined)
		{
			modelResponse.MessageContent = "Prioritas tidak boleh kosong.";
		}
		else if(this.Token !== undefined && this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else if(this.listModelMobileApplicationGallery === undefined || this.listModelMobileApplicationGallery?.length === 0)
		{
			modelResponse.MessageContent = "Gambar tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	// //#endregion


	// //#region SETTER

	setModelRequestInsertMobileApplication(modelMobileApplicationRequest: MobileApplicationModel): MobileApplicationModel
	{
		const modelMobileApplication = new MobileApplicationModel();

		modelMobileApplication.Priority = modelMobileApplicationRequest.Priority;
		modelMobileApplication.TitleFirst = modelMobileApplicationRequest.TitleFirst;
		modelMobileApplication.TitleSecond = modelMobileApplicationRequest.TitleSecond;
		modelMobileApplication.Description = modelMobileApplicationRequest.Description;
		modelMobileApplication.DeviceType = modelMobileApplicationRequest.DeviceType;
		modelMobileApplication.URLGooglePlay = modelMobileApplicationRequest.URLGooglePlay;
		modelMobileApplication.URLAppStore = modelMobileApplicationRequest.URLAppStore;
		modelMobileApplication.listModelMobileApplicationGallery = modelMobileApplicationRequest.listModelMobileApplicationGallery;

		return modelMobileApplication;
	}

	setModelRequestUpdateMobileApplication(modelMobileApplicationRequest: MobileApplicationModel): MobileApplicationModel
	{
		const modelMobileApplication = new MobileApplicationModel();

		modelMobileApplication.Token = modelMobileApplicationRequest.Token;
		modelMobileApplication.Priority = modelMobileApplicationRequest.Priority;
		modelMobileApplication.TitleFirst = modelMobileApplicationRequest.TitleFirst;
		modelMobileApplication.TitleSecond = modelMobileApplicationRequest.TitleSecond;
		modelMobileApplication.Description = modelMobileApplicationRequest.Description;
		modelMobileApplication.DeviceType = modelMobileApplicationRequest.DeviceType;
		modelMobileApplication.URLGooglePlay = modelMobileApplicationRequest.URLGooglePlay;
		modelMobileApplication.URLAppStore = modelMobileApplicationRequest.URLAppStore;
		modelMobileApplication.listModelMobileApplicationGallery = modelMobileApplicationRequest.listModelMobileApplicationGallery;

		return modelMobileApplication;
	}

	// //#endregion
}

//#endregion