//#region CLASS

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { PartnerGalleryModel } from "./partnergallery.model";
import { ResponseModel } from "./response.model";

export class PartnerModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for partner model.
		Author: Dimas Dandy Aryarajendra Suprapto.
		Created on : Firday, 5 May 2023. 					Updated on : .
		Created by : Dimas Dandy Aryarajendra Suprapto.		Updated by : .
		Version : 1.0:1
	*/

	ID?: number;
	Token?: string;
	Name?: string;

	listModelPartnerGallery?: Array<PartnerGalleryModel>;

	/* Attribute - END */


	//#region VALIDATE

	validateInsertUpdatePartner(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Social Media";

		if(this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setModelRequestInsertPartner(modelPartnerRequest: PartnerModel): PartnerModel
	{
		const modelPartner = new PartnerModel();

		modelPartner.Name = modelPartnerRequest.Name;
		modelPartner.listmodelPartnerGallery = modelPartnerRequest.listmodelPartnerGallery;

		return modelPartner;
	}

	setModelRequestUpdatePartner(modelPartnerRequest: PartnerModel): PartnerModel
	{
		const modelPartner = new PartnerModel();

		modelPartner.Token = modelPartnerRequest.Token;
		modelPartner.Name = modelPartnerRequest.Name;
		modelPartner.listmodelPartnerGallery = modelPartnerRequest.listmodelPartnerGallery;

		return modelPartner;
	}

	//#endregion
}

//#endregion