<div class="DivPageContainer" id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
        <img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormVerticalContainer DivSiteContainer">
            <div class="DivProduct DivTitleBottom">
                <h2 class="FontSoftColor">Checkout</h2>
            </div>
        </div>
	</section>

	<section class="SectionSecondary" id="sectionCheckoutContainer">
		<div class="DivFormHorizontalContainer DivSiteContainer">
			<h3>Detail</h3>
			<form>
				<div class="DivForm DivContentBoxBig">
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="spanModelProductGeneralPremiumAmount">Premi</label>
								<span id="spanModelProductGeneralPremiumAmount" name="spanModelProductGeneralPremiumAmount">Rp {{ _modelProductGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</span>

								<ng-container *ngIf="_modelProductGeneral.AdministrationAmount !== 0">
									<label for="spanModelProductGeneralAdministrationAmount">Biaya Administrasi</label>
									<span id="spanModelProductGeneralAdministrationAmount" name="spanModelProductGeneralAdministrationAmount">Rp {{ _modelProductGeneral.PremiumTotalAdministrationAmount | currencyValue | convertEmptyToDash }}</span>
								</ng-container>
							</div>

							<div class="DivForm2Column">
								<ng-container *ngIf="_modelProductGeneral.DiscountAmount !== 0">
									<label for="spanModelProductGeneralDiscountAmount">Diskon</label>
									<span id="spanModelProductGeneralDiscountAmount" name="spanModelProductGeneralDiscountAmount">Rp {{ _modelProductGeneral.DiscountAmount | currencyValue | convertEmptyToDash }}</span>
								</ng-container>
							</div>
						</div>
					</fieldset>
				</div>

				<a class="AClear ADetail" (click)="toggleDetail();">Lihat detail transaksi →</a>
			</form>

			<ng-container *ngIf="(_booleanDetail && _stringProductCode === _stringProductASRIYearly) || (_booleanDetail && _stringProductCode === _stringProductASRIMonthly)">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod !== null && this._modelProductGeneral.PolicyPeriod !== undefined">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">ASRI</span>

										<label for="spanModelProductAsriCoverageName">Paket</label>
										<span id="spanModelProductAsriCoverageName" name="spanModelProductAsriCoverageName">{{ this._modelProductGeneral.PolicyPeriod | convertEmptyToDash }} bulan</span>
									</ng-container>

									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod === null && this._modelProductGeneral.PolicyPeriod === undefined">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName }}</span>

										<label for="spanModelProductAsriCoverageName">Paket</label>
										<span id="spanModelProductAsriCoverageName" name="spanModelProductAsriCoverageName">{{ this._modelProductGeneral.modelProductOto!.CoverageName | convertStringToLowerCase | convertEmptyToDash}}</span>
									</ng-container>

									<label for="selectWhistleblowingIdentity">Harga Bangunan</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">Rp. {{ _modelProductGeneral.modelProductAsri?.BuildingPrice | currencyValue | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Harga Kelengkapan</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">Rp. {{ _modelProductGeneral.modelProductAsri?.InteriorPrice | currencyValue | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Alamat Tertanggung</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductAsri?.BuildingAddress | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Kode Pos</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductAsri?.BuildingPostalCode | convertEmptyToDash }}</span>

									<label for="selectWhistleblowingIdentity">Provinsi</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductAsri?.BuildingProvinceName | convertEmptyToDash }}</span>
								</div>
	
								<div class="DivForm2Column">
									<label for="selectWhistleblowingIdentity">Kota</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductAsri?.BuildingCityName | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Nama</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.getName() | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Address</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.PolicyholderAddress | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Email</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.PolicyholderEmail | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Nomor HP</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.PolicyholderMobilePhone | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Kode Promo</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.DiscountCode | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>

			<ng-container *ngIf="(_booleanDetail && _stringProductCode === _stringProductOTOYearly) || (_booleanDetail && _stringProductCode === _stringProductOTOMonthlyDaily)">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<ng-container *ngIf="(this._modelProductGeneral.PolicyPeriod !== null && this._modelProductGeneral.PolicyPeriod !== undefined && this._modelProductGeneral.modelProductOto!.CoverageCode === _stringCoverageCodeOTOMonthly1) || (this._modelProductGeneral.PolicyPeriod !== null && this._modelProductGeneral.PolicyPeriod !== undefined && this._modelProductGeneral.modelProductOto!.CoverageCode === _stringCoverageCodeOTOMonthly2)">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">OTO</span>

										<label for="spanModelProductOtoCoverageName">Paket</label>
										<span id="spanModelProductOtoCoverageName" name="spanModelProductOtoCoverageName">{{ this._modelProductGeneral.PolicyPeriod | convertEmptyToDash }} bulan</span>
									</ng-container>

									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod !== null && this._modelProductGeneral.PolicyPeriod !== undefined && this._modelProductGeneral.modelProductOto!.CoverageCode === _stringCoverageCodeOTODaily">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">OTO</span>

										<label for="spanModelProductOtoCoverageName">Paket</label>
										<span id="spanModelProductOtoCoverageName" name="spanModelProductOtoCoverageName">{{ this._modelProductGeneral.PolicyPeriod | convertEmptyToDash }} hari</span>
									</ng-container>

									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod === null && this._modelProductGeneral.PolicyPeriod === undefined">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName }}</span>

										<label for="spanModelProductOtoCoverageName">Paket</label>
										<span id="spanModelProductOtoCoverageName" name="spanModelProductOtoCoverageName">{{ this._modelProductGeneral.modelProductOto!.CoverageName | convertStringToLowerCase | convertEmptyToDash}}</span>
									</ng-container>

									<label for="spanModelProductOtoVehiclePrice">Harga Kendaraan</label>
									<span id="spanModelProductOtoVehiclePrice" name="spanModelProductOtoVehiclePrice">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.modelProductOto!.VehiclePrice | currencyValue | convertEmptyToDash }}</span>

									<label for="spanModelProductOtoEquipmentNonStandard">Perlengkapan Non Standar</label>
									<span id="spanModelProductOtoEquipmentNonStandardNote" name="spanModelProductOtoEquipmentNonStandardNote">{{ this._modelProductGeneral.modelProductOto!.EquipmentNonStandardNote | convertEmptyToDash}}</span>
	
									<label for="spanModelProductOtoEquipmentNonStandardPrice">Jumlah Harga Perlengkapan Non Standar</label>
									<span id="spanModelProductOtoEquipmentNonStandardPrice" name="spanModelProductOtoEquipmentNonStandardPrice">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.modelProductOto!.EquipmentNonStandardPrice | currencyValue | convertEmptyToDash }}</span>
	
									<label for="spanModelProductOtoVehicleBrandName">Merek Kendaraan</label>
									<span id="spanModelProductOtoVehicleBrandName" name="spanModelProductOtoVehicleBrandName">{{ this._modelProductGeneral.modelProductOto!.VehicleBrandName | convertEmptyToDash }}</span>
	
									<label for="spanModelProductOtoVehicleModelName">Model Kendaraan</label>
									<span id="spanModelProductOtoVehicleModelName" name="spanModelProductOtoVehicleModelName">{{ this._modelProductGeneral.modelProductOto!.VehicleModelName | convertEmptyToDash }}</span>
	
									<label for="spanModelProductOtoVehicleSubBrandName">Sub Model Kendaraan</label>
									<span id="spanModelProductOtoVehicleSubBrandName" name="spanModelProductOtoVehicleSubBrandName">{{ this._modelProductGeneral.modelProductOto!.VehicleSubModelName | convertEmptyToDash }}</span>
								</div>
	
								<div class="DivForm2Column">
									<label for="spanModelProductOtoVehicleColorName">Warna Kendaraan</label>
									<span id="spanModelProductOtoVehicleColorName" name="spanModelProductOtoVehicleColorName">{{ this._modelProductGeneral.modelProductOto!.VehicleColorName | convertEmptyToDash }}</span>
	
									<label for="spanModelProductOtoVehicleLocationName">Area Penggunaan</label>
									<span id="spanModelProductOtoVehicleLocationName" name="spanModelProductOtoVehicleLocationName">{{ this._modelProductGeneral.modelProductOto!.VehicleLocationName | convertEmptyToDash }}</span>
	
									<label for="spanModelProductOtoVehicleManufactureYearName">Tahun Produksi</label>
									<span id="spanModelProductOtoVehicleManufactureYearName" name="spanModelProductOtoVehicleManufactureYearName">{{ this._modelProductGeneral.modelProductOto!.VehicleManufactureYearCode | convertEmptyToDash }}</span>

									<label for="spanModelProductOtoVehicleRegionName">Plat Kendaraan</label>
									<ng-container *ngIf="this._modelProductGeneral.modelProductOto!.LicensePlatePrefixName === undefined">
										<span id="spanModelProductOtoVehicleRegionName" name="spanModelProductGeneralProductOtoVehicleRegionName">-</span>
									</ng-container>
									<ng-container *ngIf="this._modelProductGeneral.modelProductOto!.LicensePlatePrefixName !== undefined">
										<span *ngIf="this._modelProductGeneral.modelProductOto!.VehicleRegionName !== undefined" id="spanModelProductOtoVehicleRegionName" name="spanModelProductGeneralProductOtoVehicleRegionName">{{ this._modelProductGeneral.modelProductOto!.LicensePlatePrefixName | convertEmptyToDash }} {{ this._modelProductGeneral.modelProductOto!.LicensePlateInfix | convertEmptyToDash }} {{ this._modelProductGeneral.modelProductOto!.LicensePlateSuffix | convertStringToUpperCase | convertEmptyToDash }}</span>
									</ng-container>
	
									<label for="spanModelProductOtoVehicleChasisNumber">Nomor Rangka</label>
									<span id="spanModelProductOtoVehicleChasisNumber" name="spanModelProductOtoVehicleChasisNumber">{{ this._modelProductGeneral.modelProductOto!.VehicleChasisNumber | convertEmptyToDash }}</span>

									<label for="spanModelProductOtoVehicleEngineNumber">Nomor Mesin</label>
									<span id="spanModelProductOtoVehicleEngineNumber" name="spanModelProductOtoVehicleEngineNumber">{{ this._modelProductGeneral.modelProductOto!.VehicleEngineNumber | convertEmptyToDash }}</span>
	
									<label for="spanModelProductGeneralDiscountCode">Kode Promo</label>
									<span id="spanModelProductGeneralDiscountCode" name="spanModelProductGeneralDiscountCode">{{ this._modelProductGeneral.DiscountCode | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>

			<ng-container *ngIf="_booleanDetail && _stringProductCode === _stringProductBicycle">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<label for="spanModelProductGeneralProductName">Produk</label>
									<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName | convertEmptyToDash }}</span>

									<label for="spanModelProductPersonalAccidentCoverageName">Paket</label>
									<span id="spanModelProductPersonalAccidentCoverageName" name="spanModelProductPersonalAccidentCoverageName">{{ this._modelProductGeneral.modelProductBicycle!.CoverageName | lowercase| convertEmptyToDash }}</span>

									<label for="spanModelProductPersonalAccidentPremiumAmount">Premi</label>
									<span id="spanModelProductPersonalAccidentPremiumAmount" name="spanModelProductPersonalAccidentPremiumAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicyclePrice">Harga Sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicyclePrice" name="spanModelProductGeneralProductBicycleBicyclePrice">{{ this._modelProductGeneral.modelProductBicycle!.BicyclePrice | currencyValue | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicycleBrandName">Merek Sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicycleBrandName" name="spanModelProductGeneralProductBicycleBicycleBrandName">{{ this._modelProductGeneral.modelProductBicycle!.BicycleBrandName | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicycleTypeName">Tipe Sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicycleTypeName" name="spanModelProductGeneralProductBicycleBicycleTypeName">{{ this._modelProductGeneral.modelProductBicycle!.BicycleTypeName | convertEmptyToDash }}</span>
								</div>
								<div class="DivForm2Column">

									<label for="spanModelProductGeneralProductBicycleBicycleModelName">Model Sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicycleModelName" name="spanModelProductGeneralProductBicycleBicycleModelName">{{ this._modelProductGeneral.modelProductBicycle!.BicycleModelName | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicycleBrandSerie">Seri Sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicycleBrandSerie" name="spanModelProductGeneralProductBicycleBicycleBrandSerie">{{ this._modelProductGeneral.modelProductBicycle!.BicycleSerie | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicycleColorName">Warna Sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicycleColorName" name="spanModelProductGeneralProductBicycleBicycleColorName">{{ this._modelProductGeneral.modelProductBicycle!.BicycleColorName | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicycleFrameNumber">Nomor Rangka Sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicycleFrameNumber" name="spanModelProductGeneralProductBicycleBicycleFrameNumber">{{ this._modelProductGeneral.modelProductBicycle!.BicycleFrameNumber | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicycleProductionYearName">Tahun produksi sepeda</label>
									<span id="spanModelProductGeneralProductBicycleBicycleProductionYearName" name="spanModelProductGeneralProductBicycleBicycleProductionYearName">{{ this._modelProductGeneral.modelProductBicycle!.BicycleProductionYearName | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralProductBicycleBicycleInvoice">Nomor faktur</label>
									<span id="spanModelProductGeneralProductBicycleBicycleInvoice" name="spanModelProductGeneralProductBicycleBicycleInvoice">{{ this._modelProductGeneral.modelProductBicycle!.BicycleInvoice | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>

			<ng-container *ngIf="_booleanDetail && _stringProductCode === _stringProductPersonalAccident">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod !== null && this._modelProductGeneral.PolicyPeriod !== undefined && this._modelProductGeneral.modelProductPersonalAccident!.CoverageCode === _stringCoverageCodeDIRIMonthly">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">DIRI bulanan</span>

										<label for="spanmodelProductPersonalAccidentCoverageName">Paket</label>
										<span id="spanmodelProductPersonalAccidentCoverageName" name="spanmodelProductPersonalAccidentCoverageName">{{ this._modelProductGeneral.PolicyPeriod | convertEmptyToDash }} bulan</span>
									</ng-container>

									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod !== null && this._modelProductGeneral.PolicyPeriod !== undefined && this._modelProductGeneral.modelProductPersonalAccident!.CoverageCode === _stringCoverageCodeDIRIDaily">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">DIRI harian</span>

										<label for="spanmodelProductPersonalAccidentCoverageName">Paket</label>
										<span id="spanmodelProductPersonalAccidentCoverageName" name="spanmodelProductPersonalAccidentCoverageName">{{ this._modelProductGeneral.PolicyPeriod | convertEmptyToDash }} hari</span>
									</ng-container>

									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod === null && this._modelProductGeneral.PolicyPeriod === undefined">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName }}</span>

										<label for="spanmodelProductPersonalAccidentCoverageName">Paket</label>
										<span id="spanmodelProductPersonalAccidentCoverageName" name="spanmodelProductPersonalAccidentCoverageName">{{ this._modelProductGeneral.modelProductOto!.CoverageName | convertStringToLowerCase | convertEmptyToDash}}</span>
									</ng-container>

									<label for="spanModelProductPersonalAccidentInsuredName">Nama</label>
									<span id="spanModelProductPersonalAccidentInsuredName" name="spanModelProductPersonalAccidentInsuredName">{{ this._modelProductGeneral.modelProductPersonalAccident!.getInsuredName() | convertEmptyToDash }}</span>

									<label for="spanModelProductPersonalAccidentInsuredBirthDate">Tanggal Lahir</label>
									<span id="spanModelProductPersonalAccidentInsuredBirthDate" name="spanModelProductPersonalAccidentInsuredBirthDate">{{ this._modelProductGeneral.modelProductPersonalAccident!.InsuredBirthDate | convertToDateShort | convertEmptyToDash}}</span>

									<label for="spanModelProductPersonalAccidentInsuredGender">Jenis Kelamin</label>
									<span id="spanModelProductPersonalAccidentInsuredGender" name="spanModelProductPersonalAccidentInsuredGender" *ngIf="this._modelProductGeneral.modelProductPersonalAccident!.InsuredGender === this. _enumGenderType.Male" >Laki laki</span>
									<span id="spanModelProductPersonalAccidentInsuredGender" name="spanModelProductPersonalAccidentInsuredGender" *ngIf="this._modelProductGeneral.modelProductPersonalAccident!.InsuredGender === this. _enumGenderType.Female">Perempuan</span>

									<label for="spanModelProductPersonalAccidentInsuredIdentificationNumber">KTP</label>
									<span id="spanModelProductPersonalAccidentInsuredIdentificationNumber" name="spanModelProductPersonalAccidentInsuredIdentificationNumber">{{ this._modelProductGeneral.modelProductPersonalAccident!.InsuredIdentificationNumber  | convertEmptyToDash}}</span>
								</div>

								<div class="DivForm2Column">
									<label for="spanModelProductPersonalAccidentInsuredOccupationName">Perkerjaan</label>
									<span id="spanModelProductPersonalAccidentInsuredOccupationName" name="spanModelProductPersonalAccidentInsuredOccupationName">{{ this._modelProductGeneral.modelProductPersonalAccident!.InsuredOccupationName | convertEmptyToDash }}</span>

									<label for="spanModelProductPersonalAccidentInsuredAddress">Alamat</label>
									<span id="spanModelProductPersonalAccidentInsuredAddress" name="spanModelProductPersonalAccidentInsuredAddress">{{ this._modelProductGeneral.modelProductPersonalAccident!.InsuredAddress | convertEmptyToDash }}</span>

									<label for="spanModelProductPersonalAccidentInsuredEmail">Email</label>
									<span id="spanModelProductPersonalAccidentInsuredEmail" name="spanModelProductPersonalAccidentInsuredEmail">{{ this._modelProductGeneral.modelProductPersonalAccident!.InsuredEmail | convertEmptyToDash }}</span>

									<label for="spanModelProductPersonalAccidentInsuredMobilePhone">Nomor Telepon</label>
									<span id="spanModelProductPersonalAccidentInsuredMobilePhone" name="spanModelProductPersonalAccidentInsuredMobilePhone">{{ this._modelProductGeneral.modelProductPersonalAccident!.InsuredMobilePhone | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralDiscountCode">Kode Promo</label>
									<span id="spanModelProductGeneralDiscountCode" name="spanModelProductGeneralDiscountCode">{{ this._modelProductGeneral.DiscountCode | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>
				</form>

				<h3>Ahli Waris</h3>
				<form>
					<div class="DivForm DivContentBoxBig" *ngFor="let modelBeneficiariesPersonalAccident of this._arrayModelBeneficiariesPersonalAccident" >
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<label for="spanModelBeneficiariesPersonalAccidentName">Nama</label>
									<span id="spanModelBeneficiariesPersonalAccidentName" name="spanModelBeneficiariesPersonalAccidentName">{{ modelBeneficiariesPersonalAccident.FirstName | convertEmptyToDash }}</span>

									<label for="spanModelBeneficiariesPersonalAccidentRelationship">Hubungan</label>
									<span id="spanModelBeneficiariesPersonalAccidentRelationship" name="spanModelBeneficiariesPersonalAccidentRelationship">{{ modelBeneficiariesPersonalAccident.RelationshipName | convertEmptyToDash }}</span>
								</div>
								<div class="DivForm2Column">
									<label for="spanModelBeneficiariesPersonalAccidentAddress">Alamat</label>
									<span id="spanModelBeneficiariesPersonalAccidentAddress" name="spanModelBeneficiariesPersonalAccidentAddress">{{ modelBeneficiariesPersonalAccident.Address | convertEmptyToDash }}</span>

									<label for="spanModelBeneficiariesPersonalAccidentMobilePhone">Nomor Telepon</label>
									<span id="spanModelBeneficiariesPersonalAccidentMobilePhone" name="spanModelBeneficiariesPersonalAccidentMobilePhone">{{ modelBeneficiariesPersonalAccident.MobilePhone | convertEmptyToDash }}</span>

								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>

			<ng-container *ngIf="_booleanDetail && _stringProductCode === _stringProductShophouse">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod !== null && this._modelProductGeneral.PolicyPeriod !== undefined">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">RUKO</span>

										<label for="spanModelProductShophouseCoverageName">Paket</label>
										<span id="spanModelProductShophouseCoverageName" name="spanModelProductShophouseCoverageName">{{ this._modelProductGeneral.PolicyPeriod | convertEmptyToDash }} bulan</span>
									</ng-container>

									<ng-container *ngIf="this._modelProductGeneral.PolicyPeriod === null && this._modelProductGeneral.PolicyPeriod === undefined">
										<label for="spanModelProductGeneralProductName">Produk</label>
										<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName }}</span>

										<label for="spanModelProductShophouseCoverageName">Paket</label>
										<span id="spanModelProductShophouseCoverageName" name="spanModelProductShophouseCoverageName">{{ this._modelProductGeneral.modelProductOto!.CoverageName | convertStringToLowerCase | convertEmptyToDash}}</span>
									</ng-container>

									<label for="selectWhistleblowingIdentity">Harga Bangunan</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">Rp. {{ _modelProductGeneral.modelProductShophouse?.BuildingPrice | currencyValue | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Harga Kelengkapan</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">Rp. {{ _modelProductGeneral.modelProductShophouse?.InteriorPrice | currencyValue | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Alamat Tertanggung</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductShophouse?.BuildingAddress | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Kode Pos</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductShophouse?.BuildingPostalCode | convertEmptyToDash }}</span>

									<label for="selectWhistleblowingIdentity">Provinsi</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductShophouse?.BuildingProvinceName | convertEmptyToDash }}</span>
								</div>
	
								<div class="DivForm2Column">
									<label for="selectWhistleblowingIdentity">Kota</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.modelProductShophouse?.BuildingCityName | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Nama</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.getName() | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Address</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.PolicyholderAddress | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Email</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.PolicyholderEmail | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Nomor HP</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.PolicyholderMobilePhone | convertEmptyToDash }}</span>
	
									<label for="selectWhistleblowingIdentity">Kode Promo</label>
									<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{ _modelProductGeneral.DiscountCode | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>

			<ng-container *ngIf="_booleanDetail && _stringProductCode === _stringProductDamageOfBusinessPlace">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<label for="spanModelProductGeneralProductName">Produk</label>
									<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName }}</span>

									<label for="spanModelProductGeneralPremiumAmount">Premi</label>
									<span id="spanModelProductGeneralPremiumAmount" name="spanModelProductGeneralPremiumAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</span>

									<label for="spanModelProductDamageOfBusinessPlaceSumInsuredDefault">Nilai Santunan</label>
									<span id="spanModelProductDamageOfBusinessPlaceSumInsuredDefault" name="spanModelProductDamageOfBusinessPlaceSumInsuredDefault">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.modelProductDamageOfBusinessPlace!.CompensationValue | currencyValue | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralPolicyStartDate">Tanggal Mulai Asuransi</label>
									<span id="spanModelProductGeneralPolicyStartDate" name="spanModelProductGeneralPolicyStartDate">{{ this._modelProductGeneral.PolicyStartDate | convertToDateShort | convertEmptyToDash}}</span>

									<label for="spanModelProductDamageOfBusinessPlaceInsuredPostalCode">Kode Pos</label>
									<span id="spanModelProductDamageOfBusinessPlaceInsuredPostalCode" name="spanModelProductDamageOfBusinessPlaceInsuredPostalCode">{{ this._modelProductGeneral.modelProductDamageOfBusinessPlace!.BuildingPostalCode | convertEmptyToDash}}</span>

									<label for="spanModelProductDamageOfBusinessPlaceBuildingProvinceName">Provinsi</label>
									<span id="spanModelProductDamageOfBusinessPlaceBuildingProvinceName" name="spanModelProductDamageOfBusinessPlaceBuildingProvinceName">{{ this._modelProductGeneral.modelProductDamageOfBusinessPlace!.BuildingProvinceName | convertEmptyToDash}}</span>
								</div>

								<div class="DivForm2Column">
									<label for="spanModelProductDamageOfBusinessPlaceBuildingCityName">Provinsi</label>
									<span id="spanModelProductDamageOfBusinessPlaceBuildingCityName" name="spanModelProductDamageOfBusinessPlaceBuildingCityName">{{ this._modelProductGeneral.modelProductDamageOfBusinessPlace!.BuildingCityName | convertEmptyToDash}}</span>

									<label for="spanModelProductDamageOfBusinessPlaceBuildingPostalName">Provinsi</label>
									<span id="spanModelProductDamageOfBusinessPlaceBuildingPostalName" name="spanModelProductDamageOfBusinessPlaceBuildingPostalName">{{ this._modelProductGeneral.modelProductDamageOfBusinessPlace!.BuildingPostalName | convertEmptyToDash}}</span>

									<label for="spanModelProductDamageOfBusinessPlaceBuildingAddress">Alamat Tertanggung</label>
									<span id="spanModelProductDamageOfBusinessPlaceBuildingAddress" name="spanModelProductDamageOfBusinessPlaceBuildingAddress">{{ this._modelProductGeneral.modelProductDamageOfBusinessPlace!.BuildingAddress | convertEmptyToDash}}</span>

									<label for="spanModelProductDamageOfBusinessPlaceOwnershipInformation">Informasi Kepemilikan</label>
									<span id="spanModelProductDamageOfBusinessPlaceOwnershipInformation" name="spanModelProductDamageOfBusinessOwnershipInformation" *ngIf="this._modelProductGeneral.modelProductDamageOfBusinessPlace!.OwnershipInformation === _enumProductOwnershipInformation.Owner">Milik Sendiri</span>
									<span id="spanModelProductDamageOfBusinessPlaceOwnershipInformation" name="spanModelProductDamageOfBusinessOwnershipInformation" *ngIf="this._modelProductGeneral.modelProductDamageOfBusinessPlace!.OwnershipInformation === _enumProductOwnershipInformation.Tenant">Sewa</span>

									<label for="spanModelProductDamageOfBusinessPlaceLocationInformation">Informasi Lokasi</label>
									<span id="spanModelProductDamageOfBusinessPlaceLocationInformation" name="spanModelProductDamageOfBusinessPlaceLocationInformation" *ngIf="this._modelProductGeneral.modelProductDamageOfBusinessPlace!.LocationInformation === _enumProductLocationInformation.MoreOneHundredMetersFromMarket">Lebih dari 100 meter dari pasar</span>
									<span id="spanModelProductDamageOfBusinessPlaceLocationInformation" name="spanModelProductDamageOfBusinessPlaceLocationInformation" *ngIf="this._modelProductGeneral.modelProductDamageOfBusinessPlace!.LocationInformation === _enumProductLocationInformation.LessOneHundredMetersFromMarket">Kurang dari 100 meter dari pasar</span>

								</div>
							</div>
						</fieldset>
					</div>
				</form>

				<h3>Ahli Waris</h3>
				<form>
					<div class="DivForm DivContentBoxBig" *ngFor="let modelBeneficiariesDamageOfBusinessPlace of this._arrayModelBeneficiariesDamageOfBusinessPlace" >
                    <fieldset>
                        <div class=DivFormHorizontalContainer>
                            <div class="DivForm2Column">
                                <label for="spanModelBeneficiariesDamageOfBusinessPlaceFirstName">Nama</label>
                                <span id="spanModelBeneficiariesDamageOfBusinessPlaceFirstName" name="spanModelBeneficiariesDamageOfBusinessPlaceFirstName">{{ modelBeneficiariesDamageOfBusinessPlace.FirstName | convertEmptyToDash }}</span>

                                <label for="spanModelProductGeneralBirthDate">Tanggal Lahir</label>
                                <span id="spanModelProductGeneralBirthDate" name="spanModelProductGeneralBirthDate">{{ modelBeneficiariesDamageOfBusinessPlace.BirthDate | convertToDateShort | convertEmptyToDash}}</span>
                            </div>
                            <div class="DivForm2Column">
                                <label for="spanModelBeneficiariesDamageOfBusinessPlaceMobilePhone">Nomor Telepon</label>
                                <span id="spanModelBeneficiariesDamageOfBusinessPlaceMobilePhone" name="spanModelBeneficiariesDamageOfBusinessPlaceMobilePhone">{{ modelBeneficiariesDamageOfBusinessPlace.MobilePhone | convertEmptyToDash }}</span>


                                <label for="spanModelBeneficiariesDamageOfBusinessPlaceRelationship">Hubungan</label>
                                <span id="spanModelBeneficiariesDamageOfBusinessPlaceRelationship" name="spanModelBeneficiariesDamageOfBusinessPlaceRelationship">{{ modelBeneficiariesDamageOfBusinessPlace.RelationshipName | convertEmptyToDash }}</span>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </form>
			</ng-container>

			<ng-container *ngIf="_booleanDetail && _stringProductCode === _stringProductMyHouse">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<label for="spanModelProductGeneralProductName">Produk</label>
									<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName }}</span>
	
									<label for="spanModelProductGeneralPremiumAmount">Premi</label>
									<span id="spanModelProductGeneralPremiumAmount" name="spanModelProductGeneralPremiumAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</span>
	
									<label for="spanModelProductMyHouseSumInsuredDefault">Nilai Santunan Kebakaran</label>
									<span id="spanModelProductMyHouseSumInsuredDefault" name="spanModelProductMyHouseSumInsuredDefault">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} 15.000.000</span>
	
									<label for="spanModelProductMyHouseSumInsuredDiedDefault">Nilai Santunan Meninggal Dunia</label>
									<span id="spanModelProductMyHouseSumInsuredDiedDefault" name="spanModelProductMyHouseSumInsuredDiedDefault">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} 5.000.000</span>
	
									<label for="spanModelProductGeneralPolicyStartDate">Tanggal Mulai Asuransi</label>
									<span id="spanModelProductGeneralPolicyStartDate" name="spanModelProductGeneralPolicyStartDate">{{ this._modelProductGeneral.PolicyStartDate | convertToDateShort | convertEmptyToDash}}</span>
	
									<label for="spanModelProductMyHouseInsuredPostalCode">Kode Pos</label>
									<span id="spanModelProductMyHouseInsuredPostalCode" name="spanModelProductMyHouseInsuredPostalCode">{{ this._modelProductGeneral.modelProductMyHouse!.BuildingPostalCode | convertEmptyToDash}}</span>
	
									<label for="spanModelProductMyHouseBuildingProvinceName">Provinsi</label>
									<span id="spanModelProductMyHouseBuildingProvinceName" name="spanModelProductMyHouseBuildingProvinceName">{{ this._modelProductGeneral.modelProductMyHouse!.BuildingProvinceName | convertEmptyToDash}}</span>
	
								</div>
								<div class="DivForm2Column">
									<label for="spanModelProductMyHouseBuildingCityName">Kota</label>
									<span id="spanModelProductMyHouseBuildingCityName" name="spanModelProductMyHouseBuildingCityName">{{ this._modelProductGeneral.modelProductMyHouse!.BuildingCityName | convertEmptyToDash}}</span>
	
									<label for="spanModelProductMyHouseBuildingPostalName">Lokasi</label>
									<span id="spanModelProductMyHouseBuildingPostalName" name="spanModelProductMyHouseBuildingPostalName">{{ this._modelProductGeneral.modelProductMyHouse!.BuildingPostalName | convertEmptyToDash}}</span>
	
									<label for="spanModelProductMyHouseBuildingAddress">Alamat Tertanggung</label>
									<span id="spanModelProductMyHouseBuildingAddress" name="spanModelProductMyHouseBuildingAddress">{{ this._modelProductGeneral.modelProductMyHouse!.BuildingAddress | convertEmptyToDash}}</span>
	
									<label for="spanModelProductMyHouseOwnershipInformation">Informasi Kepemilikan</label>
									<span id="spanModelProductMyHouseOwnershipInformation" name="spanModelProductDamageOfBusinessOwnershipInformation" *ngIf="this._modelProductGeneral.modelProductMyHouse!.OwnershipInformation === _enumProductOwnershipInformation.Owner">Milik Sendiri</span>
									<span id="spanModelProductMyHouseOwnershipInformation" name="spanModelProductDamageOfBusinessOwnershipInformation" *ngIf="this._modelProductGeneral.modelProductMyHouse!.OwnershipInformation === _enumProductOwnershipInformation.Tenant">Sewa</span>
	
									<label for="spanModelProductMyHouseLocationInformation">Informasi Lokasi</label>
									<span id="spanModelProductMyHouseLocationInformation" name="spanModelProductMyHouseLocationInformation" *ngIf="this._modelProductGeneral.modelProductMyHouse!.LocationInformation === _enumProductLocationInformation.MoreOneHundredMetersFromMarket">Lebih dari 100 meter dari pasar</span>
									<span id="spanModelProductMyHouseLocationInformation" name="spanModelProductMyHouseLocationInformation" *ngIf="this._modelProductGeneral.modelProductMyHouse!.LocationInformation === _enumProductLocationInformation.LessOneHundredMetersFromMarket">Kurang dari 100 meter dari pasar</span>

								</div>
							</div>
						</fieldset>
					</div>
				</form>
	
				<h3>Ahli Waris</h3>
				<form>
					<div class="DivForm DivContentBoxBig" *ngFor="let modelBeneficiariesMyHouse of this._arrayModelBeneficiariesMyHouse" >
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<label for="spanModelBeneficiariesMyHouseFirstName">Nama</label>
									<span id="spanModelBeneficiariesMyHouseFirstName" name="spanModelBeneficiariesMyHouseFirstName">{{ modelBeneficiariesMyHouse.FirstName | convertEmptyToDash }}</span>
	
									<label for="spanModelProductGeneralBirthDate">Tanggal Lahir</label>
									<span id="spanModelProductGeneralBirthDate" name="spanModelProductGeneralBirthDate">{{ modelBeneficiariesMyHouse.BirthDate | convertToDateShort | convertEmptyToDash}}</span>
								</div>
								<div class="DivForm2Column">
									<label for="spanModelBeneficiariesMyHouseMobilePhone">Nomor Telepon</label>
									<span id="spanModelBeneficiariesMyHouseMobilePhone" name="spanModelBeneficiariesMyHouseMobilePhone">{{ modelBeneficiariesMyHouse.MobilePhone | convertEmptyToDash }}</span>
	
	
									<label for="spanModelBeneficiariesMyHouseRelationship">Hubungan</label>
									<span id="spanModelBeneficiariesMyHouseRelationship" name="spanModelBeneficiariesMyHouseRelationship">{{ modelBeneficiariesMyHouse.RelationshipName | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>

			<ng-container *ngIf="_booleanDetail && _stringProductCode === _stringProductMyMotorcycle">
				<h3>Rangkuman</h3>
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<label for="spanModelProductGeneralProductName">Produk</label>
									<span id="spanModelProductGeneralProductName" name="spanModelProductGeneralProductName">{{ this._modelProductGeneral.ProductName }}</span>

									<label for="spanModelProductGeneralPremiumAmount">Premi</label>
									<span id="spanModelProductGeneralPremiumAmount" name="spanModelProductGeneralPremiumAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcycleSumInsuredDefault">Santunan Kerugian Motor</label>
									<span id="spanModelProductMyMotorcycleSumInsuredDefault" name="spanModelProductMyMotorcycleSumInsuredDefault">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.modelProductMyMotorcycle!.PartialLossVehicle | convertEmptyToDash }} </span>

									<label for="spanModelProductMyMotorcycleSumInsuredDefault">Santunan Kecelakaan Diri Pengendara Motor</label>
									<span id="spanModelProductMyMotorcycleSumInsuredDefault" name="spanModelProductMyMotorcycleSumInsuredDefault">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.modelProductMyMotorcycle!.PersonalAccidentDriver | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcycleSumInsuredDefault">Santunan Tanggung Jawab Pihak Ketiga</label>
									<span id="spanModelProductMyMotorcycleSumInsuredDefault" name="spanModelProductMyMotorcycleSumInsuredDefault">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }} {{ this._modelProductGeneral.modelProductMyMotorcycle!.ThirdPartyLiability | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralPolicyStartDate">Tanggal Mulai Asuransi</label>
									<span id="spanModelProductGeneralPolicyStartDate" name="spanModelProductGeneralPolicyStartDate">{{ this._modelProductGeneral.PolicyStartDate | convertToDateShort | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcyclePostalCode">Kode Pos</label>
									<span id="spanModelProductMyMotorcyclePostalCode" name="spanModelProductMyMotorcyclePostalCode">{{ this._modelProductGeneral.modelProductMyMotorcycle!.PostalCode | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcycleVehicleLocationName">Area Penggunaan</label>
									<span id="spanModelProductMyMotorcycleVehicleLocationName" name="spanModelProductMyMotorcycleVehicleLocationName">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleLocationName | convertEmptyToDash }}</span>

								</div>
								<div class="DivForm2Column">
									<label for="spanModelProductMyMotorcycleVehicleBrandName">Merek Kendaraan</label>
									<span id="spanModelProductMyMotorcycleVehicleBrandName" name="spanModelProductMyMotorcycleVehicleBrandName">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleBrandName | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcycleVehicleModelName">Model Kendaraan</label>
									<span id="spanModelProductMyMotorcycleVehicleModelName" name="spanModelProductMyMotorcycleVehicleModelName">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleModelName | convertEmptyToDash }}</span>

									<ng-container *ngIf="this._modelProductGeneral.modelProductMyMotorcycle!.VehicleSubModelCode">
										<label for="spanModelProductMyMotorcycleVehicleSubModelName">Sub Model Kendaraan</label>
										<span id="spanModelProductMyMotorcycleVehicleSubModelName" name="spanModelProductMyMotorcycleVehicleSubModelName">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleSubModelName | convertEmptyToDash }}</span>
									</ng-container>

									<label for="spanModelProductMyMotorcycleVehicleManufactureYearName">Tahun Produksi</label>
									<span id="spanModelProductMyMotorcycleVehicleManufactureYearName" name="spanModelProductMyMotorcycleVehicleManufactureYearName">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleManufactureYearName | convertEmptyToDash}}</span>

									<label for="spanModelProductMyMotorcycleLicensePlate">Plat Kendaraan</label>
									<span id="spanModelProductMyMotorcycleLicensePlate" name="spanModelProductMyMotorcycleLicensePlate">{{ this._modelProductGeneral.modelProductMyMotorcycle!.LicensePlatePrefixCode | convertEmptyToDash }} {{ this._modelProductGeneral.modelProductMyMotorcycle!.LicensePlateInfix | convertEmptyToDash }} {{ this._modelProductGeneral.modelProductMyMotorcycle!.LicensePlateSuffix | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcycleVehicleChasisNumber">Nomor Rangka</label>
									<span id="spanModelProductMyMotorcycleVehicleChasisNumber" name="spanModelProductMyMotorcycleVehicleChasisNumber">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleChasisNumber | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcycleVehicleEngineNumber">Nomor Mesin</label>
									<span id="spanModelProductMyMotorcycleVehicleEngineNumber" name="spanModelProductMyMotorcycleVehicleEngineNumber">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleEngineNumber | convertEmptyToDash }}</span>

									<label for="spanModelProductMyMotorcycleVehicleColorName">Warna Kendaraan</label>
									<span id="spanModelProductMyMotorcycleVehicleColorName" name="spanModelProductMyMotorcycleVehicleColorName">{{ this._modelProductGeneral.modelProductMyMotorcycle!.VehicleColorName | convertEmptyToDash }}</span>

								</div>
							</div>
						</fieldset>
					</div>
				</form>

				<h3>Ahli Waris</h3>
				<form>
					<div class="DivForm DivContentBoxBig" *ngFor="let modelBeneficiariesMyMotorcycle of this._arrayModelBeneficiariesMyMotorcycle" >
						<fieldset>
							<div class=DivFormHorizontalContainer>
								<div class="DivForm2Column">
									<label for="spanModelBeneficiariesMyMotorcycleFirstName">Nama</label>
									<span id="spanModelBeneficiariesMyMotorcycleFirstName" name="spanModelBeneficiariesMyMotorcycleFirstName">{{ modelBeneficiariesMyMotorcycle.FirstName | convertEmptyToDash }}</span>

									<label for="spanModelProductGeneralBirthDate">Tanggal Lahir</label>
									<span id="spanModelProductGeneralBirthDate" name="spanModelProductGeneralBirthDate">{{ modelBeneficiariesMyMotorcycle.BirthDate | convertToDateShort | convertEmptyToDash}}</span>
								</div>
								<div class="DivForm2Column">
									<label for="spanModelBeneficiariesMyMotorcycleMobilePhone">Nomor Telepon</label>
									<span id="spanModelBeneficiariesMyMotorcycleMobilePhone" name="spanModelBeneficiariesMyMotorcycleMobilePhone">{{ modelBeneficiariesMyMotorcycle.MobilePhone | convertEmptyToDash }}</span>


									<label for="spanModelBeneficiariesMyMotorcycleRelationship">Hubungan</label>
									<span id="spanModelBeneficiariesMyMotorcycleRelationship" name="spanModelBeneficiariesMyMotorcycleRelationship">{{ modelBeneficiariesMyMotorcycle.RelationshipName | convertEmptyToDash }}</span>
								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>

			<ng-container *ngIf="_booleanValidationVirtualAccount">
				<h3>Metode Pembayaran</h3>
				<form>
					<div class="DivForm PaddingLR0">
						<fieldset>
							<div id="divPaymentMethod" class="DivFormHorizontalContainer AlignStart">
								<div class="DivForm2Column WidthAuto">
									<div class="DivContentSmall">
										<div class="DivListContentShort" [class.DivListContentShortActive]="_stringPaymentMethod === 'briva'" (click)="toggleClassPaymentMethod('briva')">
											<img src="../../../assets/logos/sakina/logo_brimo.svg" alt="Logo BRIMO">
											<label>BRImo</label>
										</div>
	
										<div class="DivListContentShort" [class.DivListContentShortActive]="_stringPaymentMethod === 'ewallet'" (click)="toggleClassPaymentMethod('ewallet')">
											<img src="../../../assets/icons/sakina/icon_ewallet.svg" alt="Logo Ewallet">
											<label>QRIS</label>
										</div>
	
										<div class="DivListContentShort" [class.DivListContentShortActive]="_stringPaymentMethod === 'atm'" (click)="toggleClassPaymentMethod('atm')">
											<img src="../../../assets/icons/sakina/icon_atm.svg" alt="Logo ATM/Bank Transfer">
											<label>ATM/Transfer Bank</label>
										</div>
	
										<div class="DivListContentShort" [class.DivListContentShortActive]="_stringPaymentMethod === 'retail'" (click)="toggleClassPaymentMethod('retail')">
											<img src="../../../assets/icons/sakina/logo_alfagroup.svg" alt="Logo ATM/Bank Transfer">
											<label>Retail</label>
										</div>
									</div>
								</div>
	
								<div class="DivForm2Column PaddingLeftBorder">
									<div class="DivListContentLong"  [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringBRIVA" (click)="toggleClassPaymentSelectedMethod(_stringBRIVA)" *ngIf="_stringPaymentMethod === 'briva'">
										<img src="../../../assets/logos/sakina/logo_brimo.svg" alt="Logo BRIVA">
										<p>Hanya menerima pembayaran melalui BRI</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
	
									<div class="DivListContentLong" [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringBNI" (click)="toggleClassPaymentSelectedMethod(_stringBNI)" *ngIf="_stringPaymentMethod === 'atm'">
										<img src="../../../assets/icons/sakina/icon_bni.svg" alt="Logo BNI">
										<p>Hanya menerima pembayaran melalui BNI</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
	
									<div class="DivListContentLong" [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringPermata" (click)="toggleClassPaymentSelectedMethod(_stringPermata)" *ngIf="_stringPaymentMethod === 'atm'">
										<img src="../../../assets/icons/sakina/icon_permata bank.svg" alt="Logo Permata Bank">
										<p>Hanya menerima pembayaran melalui Bank Permata</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
	
									<div class="DivListContentLong" [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringMandiri" (click)="toggleClassPaymentSelectedMethod(_stringMandiri)" *ngIf="_stringPaymentMethod === 'atm'">
										<img src="../../../assets/icons/sakina/icon_mandiri.svg" alt="Logo Mandiri">
										<p>Hanya menerima pembayaran melalui Mandiri</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
	
									<!-- <div class="DivListContentLong" [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringBCA" (click)="toggleClassPaymentSelectedMethod(_stringBCA)" *ngIf="_stringPaymentMethod === 'atm'"> -->
									<div class="DivListContentLong DivListContentDisable" *ngIf="_stringPaymentMethod === 'atm'">
										<img src="../../../assets/icons/sakina/icon_bca.svg" alt="Logo BCA">
										<p>Hanya menerima pembayaran melalui BCA</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
	
									<div class="DivListContentLong" [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringQRIS" (click)="toggleClassPaymentSelectedMethod(_stringQRIS)" *ngIf="_stringPaymentMethod === 'ewallet'">
										<img src="../../../assets/icons/sakina/logo_qris.svg" alt="Logo QRIS">
										<p>Hanya menerima pembayaran melalui QRIS</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
	
									<!-- <div class="DivListContentLong" [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringAlfamart" (click)="toggleClassPaymentSelectedMethod(_stringAlfamart)" *ngIf="_stringPaymentMethod === 'retail'"> -->
									<div class="DivListContentLong DivListContentDisable" *ngIf="_stringPaymentMethod === 'retail'">
										<img src="../../../assets/icons/sakina/logo_alfagroupp.svg" alt="Logo QRIS">
										<p>Hanya menerima pembayaran melalui Alfagroup</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
	
									<!-- <div class="DivListContentLong" [class.DivListContentLongActive]="_stringPaymentMethodSelected === _stringIndomart" (click)="toggleClassPaymentSelectedMethod(_stringIndomart)" *ngIf="_stringPaymentMethod === 'retail'"> -->
									<div class="DivListContentLong DivListContentDisable" *ngIf="_stringPaymentMethod === 'retail'">
										<img src="../../../assets/icons/sakina/logo_indomaret.svg" alt="Logo QRIS">
										<p>Hanya menerima pembayaran melalui Indomart</p>
										<p>Pembayaran lebih mudah dan di cek otomatis</p>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</form>
	
				<form>
					<div class="DivForm DivContentBoxBig">
						<fieldset>
							<div class="DivFormHorizontalContainer AlignCenter">
								<div class="DivForm2Column">
									<label for="spanModelProductGeneralPremiumTotalAmount" class="Margin0Padding0">Total</label>
									<span id="spanModelProductGeneralPremiumTotalAmount" name="spanModelProductGeneralPremiumTotalAmount" class="SpanCheckoutAmount">Rp {{ _modelProductGeneral.PremiumTotalAmount | currencyValue | convertEmptyToDash }}</span>
								</div>
	
								<div class="DivForm2Column DivCheckoutContainer">
									<input type="button" name="buttonSubmit" value="Lanjutkan Ke Pembayaran" (click)="generatePayment();">
								</div>
							</div>
						</fieldset>
					</div>
				</form>
			</ng-container>
		</div>
	</section>

</div>