//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class BeneficiariesMyMotorcycleModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product my motorcycle model, used for beneficiearies.
		Author: Eka Saputra.
		Created on : Thursday, 02 June 2022. 				Updated on : Thursday, 02 June 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	/* Attribute - START */

	ID?: number;
	ProductMyMotorcycleID?: number;
	Disclaimer?: boolean;
	Note?: string;
	Token?: string;

	/* DETAIL - START */

	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	BirthDate?: Date;
	BirthPlace?: string;
	Gender?: ENUM_GENDER_TYPE;
	Telephone?: string;
	MobilePhone?: string;
	MobilePhonePrefixCode?: string;
	Email?: string;
	IdentificationNumber?: string;
	IdentificationType?: ENUM_IDENTIFICATION_TYPE;
	Address?: string;
	CountryID?: number;
	ProvinceID?: number;
	CityID?: number;
	SubDistrictID?: number;
	VillageID?: number;
	HamletCode?: string;
	NeighbourhoodCode?: string;
	PostalID?: number;

	Latitude?: number;
	Longitude?: number;

	Occupation?: string;
	Hobby?: string;
	RelationshipID?: number;
	RelationshipCode?: string;
	RelationshipName?: string;

	/* Attribute - END */

	//#region VALIDATION

	validateForBeneficiariesMyMotorcycle(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Produk Motorku";

		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI);

		if (this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			modelResponse.MessageContent = "Nama ahli waris tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.FirstName))
		{
			modelResponse.MessageContent = "Format input mengandung karakter yang tidak diizinkan.";
			this.FirstName = undefined;
		}
		else if (this.MiddleName !== undefined && !regularExpression.test(this.MiddleName))
		{
			modelResponse.MessageContent = "Format input mengandung karakter yang tidak diizinkan.";
			this.MiddleName = undefined;
		}
		else if (this.LastName !== undefined && !regularExpression.test(this.LastName))
		{
			modelResponse.MessageContent = "Format input mengandung karakter yang tidak diizinkan.";
			this.LastName = undefined;
		}

		else if(this.BirthDate == null || this.BirthDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal lahir ahli waris tidak boleh kosong.";
		}
		else if(this.MobilePhone == null || this.MobilePhone === undefined || this.MobilePhone === "")
		{
			modelResponse.MessageContent = "Nomor telepon ahli waris tidak boleh kosong.";
		}
		else if(this.MobilePhone.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Nomor telepon minimal "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM+" digit.";
		}
		else if(this.MobilePhone.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Nomor telepon maksimal "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM+" digit.";
		}
		else if (!regularExpression.test(this.MobilePhone))
		{
			modelResponse.MessageContent = "Format input mengandung karakter yang tidak diizinkan.";
			this.MobilePhone = undefined;
		}

		else if(this.RelationshipCode == null || this.RelationshipCode === undefined || this.RelationshipCode === "" && this.RelationshipName == null || this.RelationshipName === undefined || this.RelationshipName === "")
		{
			modelResponse.MessageContent = "Hubungan ahli waris tidak boleh kosong.";
		}
		else if (!regularExpression.test(this.RelationshipCode))
		{
			modelResponse.MessageContent = "Format input mengandung karakter yang tidak diizinkan.";
			this.RelationshipCode = undefined;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
		}
		return modelResponse;
	}

	//#endregion'


	//#region SETTER

	setForBeneficiariesMyMotorcycle(modelBeneficiariesMyMotorcycle: BeneficiariesMyMotorcycleModel): void
	{
		this.FirstName = modelBeneficiariesMyMotorcycle.FirstName;
		this.BirthDate = modelBeneficiariesMyMotorcycle.BirthDate;
		this.RelationshipCode = modelBeneficiariesMyMotorcycle.RelationshipCode;
		this.RelationshipName = modelBeneficiariesMyMotorcycle.RelationshipName;
		if(modelBeneficiariesMyMotorcycle == null || modelBeneficiariesMyMotorcycle === undefined)
		{

		}
		else
		{
			if(modelBeneficiariesMyMotorcycle.MobilePhone === undefined)
			{

			}
			else
			{
				this.MobilePhone = (modelBeneficiariesMyMotorcycle.MobilePhonePrefixCode +""+ modelBeneficiariesMyMotorcycle.MobilePhone);
			}
		}
	}

	//#endregion

}
//#endregion