//#region CLASS

import { ENUM_DEVICE_TYPE, ENUM_PERIOD_TYPE, ENUM_PRODUCTCONTENT_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ProductContentGalleryModel } from "./productcontentgallery.model";

export class ProductContentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product content.
		Author: Dimas Dandy Aryarajendra Suprapto.
		Created on : Monday, 29 May 2023. 					Updated on : Wednesday, 6 January 2021.
		Created by : Dimas Dandy Aryarajendra Suprapto.		Updated by : Dimas Dandy Aryarajendra Suprapto.
		Version : 1.0:2.
	*/

	ID?: number;
	Token?: string;
	TitleIDN?: string;
	TitleEN?: string;
	DescriptionIDN?: number;
	DescriptionEN?: number;
	ProductPeriod?: ENUM_PERIOD_TYPE;
	ProductType?: ENUM_PRODUCTCONTENT_TYPE;
	DeviceType?: ENUM_DEVICE_TYPE;

	listModelProductContentGallery?: Array<ProductContentGalleryModel>;

	/* Attribute - END */
}

//#endregion