//#region IMPORT

import { Component, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { ENUM_DEVICE_TYPE, ENUM_HEADER_TYPE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ContentModel } from "src/app/models/content.model";
import { HeaderContentModel } from "src/app/models/headercontent.model";
import { PartnerModel } from "src/app/models/partner.model";
import { PartnerGalleryModel } from "src/app/models/partnergallery.model";
import { ResponseModel } from "src/app/models/response.model";
import { HeaderService } from "src/app/services/header.service";
import { PartnerService } from "src/app/services/partner.service";
import { SessionService } from "src/app/services/session.service";
import PartnerCustomer from "src/assets/data/partner.json";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-partner",
		templateUrl: "./partner.component.html",
		styleUrls: ["./partner.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class PartnerComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelTable: TableModel;
	public _modelPartner: PartnerModel;
	public _modelPartnerRequest: PartnerModel;
	public _modelHeaderContent: HeaderContentModel;
	public _modelHeaderContentRequest: HeaderContentModel;

	public _stringConstant = StringConstant;
	public _numberConstant = NumberConstant;

	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;

	public _numberIndexPartner: number;
	public _numberIndexPartnerCurrent: number;

	public _arrayModelPartnerCustomer: Array<ContentModel>;
	public _arrayModelPartner: Array<PartnerModel>;
	public _arrayModelPartnerGallery: Array<PartnerGalleryModel>;

	public _intervalIDSection1: any;
	public _intervalIDSection2: any;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router, private _elementRef: ElementRef, private _servicePartner: PartnerService, private _serviceHeaderContent: HeaderService)
	{
		super(serviceSession, router);

		this._modelTable = new TableModel();
		this._modelPartner = new PartnerModel();
		this._modelPartnerRequest = new PartnerModel();
		this._modelHeaderContent = new HeaderContentModel();
		this._modelHeaderContentRequest = new HeaderContentModel();

		// this._stringImageMainHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_mitra_insurtech.jpg");
		// this._stringImageMainHeaderMobile = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_mitra_insurtech_mobile.jpg");
		this._stringImageMainHeader = "";
		this._stringImageMainHeaderMobile = "";

		this._numberIndexPartner = 2;
		this._numberIndexPartnerCurrent = 4;

		this._arrayModelPartnerCustomer = PartnerCustomer;
		this._arrayModelPartner = [];
		this._arrayModelPartnerGallery = [];

		this.setIntervalPartner();
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		this.callSelectHeaderContentByAttributesForCustomer(this);
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region ONDESTROY

	ngOnDestroy(): void
	{
		clearInterval(this._intervalIDSection1);
	}

	//#endregion


	//#region FUNCTION

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}

	setIntervalPartner(): void
	{
		this._intervalIDSection1 = setInterval(() => { this.showContentNext(); }, this._numberConstant.NUMBER_INTERVAL_FOUR_SECONDS);
	}

	showContentNext(): void
	{
		this._numberIndexPartnerCurrent += NumberConstant.NUMBER_COMPARE_VALUE_ONE;

		if (this._numberIndexPartnerCurrent > this._arrayModelPartner.length - NumberConstant.NUMBER_COMPARE_VALUE_ONE && this._numberIndexPartnerCurrent > this._arrayModelPartnerGallery.length - NumberConstant.NUMBER_COMPARE_VALUE_ONE)
		{
			this._numberIndexPartnerCurrent = 0;
		}
		else
		{

		}

		const stringHTML = "<label for=\"inputImageGallery"+ this._numberIndexPartnerCurrent +"\" id=\"labelImageGallery"+ this._numberIndexPartnerCurrent +"\" class=\"LabelImageNonActive LabelPreAnimation\"><img src=\""+ this._arrayModelPartnerGallery[this._numberIndexPartnerCurrent].URL +"\" alt=\"\"><p>"+ this._arrayModelPartner[this._numberIndexPartnerCurrent].Name +"</p></label>";

		const elementLabelNonActive = this._elementRef.nativeElement.querySelectorAll("label.LabelImageNonActive");
		const elementSectionLabelContainer = this._elementRef.nativeElement.querySelector("#sectionImageGallery");

		elementSectionLabelContainer.insertAdjacentHTML("beforeend", stringHTML);

		setTimeout(function()
		{
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let numberIndex = 0; numberIndex < elementLabelNonActive.length; numberIndex++)
			{
				elementLabelNonActive[numberIndex].classList.remove("LabelPreAnimation");
			}
		},200);

		elementSectionLabelContainer.removeChild(elementSectionLabelContainer.firstElementChild);
		elementLabelNonActive[2].classList.remove("LabelImageActive");
		elementLabelNonActive[3].classList.add("LabelImageActive");
	}

	showContentPrevious(): void
	{
		this._numberIndexPartnerCurrent -= NumberConstant.NUMBER_COMPARE_VALUE_ONE;

		if (this._numberIndexPartnerCurrent < 0)
		{
			// this._numberIndexPartnerCurrent = this._arrayModelPartner.length - NumberConstant.NUMBER_COMPARE_VALUE_ONE;
			this._numberIndexPartnerCurrent = this._arrayModelPartnerGallery.length - NumberConstant.NUMBER_COMPARE_VALUE_ONE;
		}
		else
		{

		}

		const stringHTML = "<label for=\"inputImageGallery"+ this._numberIndexPartnerCurrent +"\" id=\"labelImageGallery"+ this._numberIndexPartnerCurrent +"\" class=\"LabelImageNonActive\"><img src=\""+ this._arrayModelPartnerGallery[this._numberIndexPartnerCurrent].URL +"\" alt=\"\"><p>"+ this._arrayModelPartner[this._numberIndexPartnerCurrent].Name +"</p></label>";

		const elementLabelNonActive = this._elementRef.nativeElement.querySelectorAll("label.LabelImageNonActive");
		const elementSectionLabelContainer = this._elementRef.nativeElement.querySelector("#sectionImageGallery");

		elementSectionLabelContainer.insertAdjacentHTML("afterbegin", stringHTML);
		elementSectionLabelContainer.removeChild(elementSectionLabelContainer.lastElementChild);
		elementLabelNonActive[2].classList.remove("LabelImageActive");
		elementLabelNonActive[1].classList.add("LabelImageActive");

		elementLabelNonActive[this._numberIndexPartner].scrollIntoView({behavior: "smooth", inline: "center", block: "center"});
	}

	showImageFromURL(modelPartner: PartnerModel): string
	{
		if((modelPartner.listModelPartnerGallery != null || modelPartner.listModelPartnerGallery !== undefined) && modelPartner.listModelPartnerGallery?.length !== 0)
		{
			const modelPartnerGalleryModel: PartnerGalleryModel = modelPartner.listModelPartnerGallery[0];
			if(modelPartnerGalleryModel.URL)
			{
				return this._pipeConvertToBroadcastURL.transform(modelPartnerGalleryModel.URL);
			}
			else
			{
				return this._stringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return this._stringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	//#endregion


	//#region WEB SERVICES

	public callSelectHeaderContentByAttributesForCustomer(componentCurrent: PartnerComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelHeaderContentRequest.DeviceType = ENUM_DEVICE_TYPE.Website;
		componentCurrent._modelHeaderContentRequest.HeaderType = ENUM_HEADER_TYPE.Mitra;

		componentCurrent._serviceHeaderContent.selectHeaderContentByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelHeaderContent.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelHeaderContent.listModelHeaderGallery !== undefined)
						{
							for (const modelHeaderContentGallery of componentCurrent._modelHeaderContent.listModelHeaderGallery)
							{
								modelHeaderContentGallery.URL = componentCurrent._pipeConvertToBroadcastURL.transform(modelHeaderContentGallery.URL);
								componentCurrent._stringImageMainHeader = modelHeaderContentGallery.URL;
								componentCurrent._stringImageMainHeaderMobile = modelHeaderContentGallery.URL;
							}
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent.callSelectPartnerByAttributes(componentCurrent);
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHeaderContentByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelHeaderContentRequest);
	}


	public callSelectPartnerByAttributes(componentCurrent: PartnerComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._servicePartner.selectPartnerByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._arrayModelPartner = [];

						let arrayModelPartnerTemp: Array<PartnerModel> = [];
						arrayModelPartnerTemp = JSON.parse(modelResponse.Data);
						componentCurrent._modelTable.Result = undefined;

						let modelPartner: PartnerModel;
						for(const modelPartnerTemp of arrayModelPartnerTemp)
						{
							modelPartner = new PartnerModel();
							modelPartner.setModelFromObject(modelPartnerTemp);
							componentCurrent._arrayModelPartner.push(modelPartner);

							if (modelPartner.listModelPartnerGallery !== undefined)
							{
								for(const modelPartnerGalleryTemp of modelPartner.listModelPartnerGallery)
								{
									modelPartnerGalleryTemp.URL = componentCurrent._pipeConvertToBroadcastURL.transform(modelPartnerGalleryTemp.URL);
									componentCurrent._arrayModelPartnerGallery.push(modelPartnerGalleryTemp);
								}

							}
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPartnerByAttributes(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPartnerRequest);
	}

	//#endregion


	//#region NAVIGATION

	//#endregion

}
