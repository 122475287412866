//#region IMPORT

import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgSelectModule } from "@ng-select/ng-select";
import { QuicklinkModule } from "ngx-quicklink";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { NgxMaskModule } from "ngx-mask";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { TableControlIncludeMiniComponent } from "./components/includes/tablecontrolmini.include/tablecontrolmini.include.component";
import { AppComponent } from "./components/root/app.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { HomeComponent } from "./components/home/home.component";
import { PwaService } from "./services/pwa.service";
import { TableControlIncludePageComponent } from "./components/includes/tablecontrolpage.include/tablecontrolpage.include.component";
import { LandingComponent } from "./components/landing/landing.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ProductAsriMonthlyComponent } from "./components/product/productasri/productasrimonthly/productasrimonthly.component";
import { ProductAsriYearlyComponent } from "./components/product/productasri/productasriyearly/productasriyearly.component";
import { HorizontalstepperIncludeComponent } from "./components/includes/horizontalstepper.include/horizontalstepper.include.component";
import { PaymentComponent } from "./components/payment/payment.component";
import { CheckoutComponent } from "./components/checkout/checkout.component";
import { SigninComponent } from "./components/signin/signin.component";
import { ProductOtoYearlyDetailComponent } from "./components/product/productoto/productotoyearly/productotoyearlydetail/productotoyearlydetail.component";
import { ProductOtoMonthlyDetailComponent } from "./components/product/productoto/productotomonthly/productotomonthlydetail/productotomonthlydetail.component";
import { ProductOtoDailyPackageComponent } from "./components/product/productoto/productotodaily/productotodailypackage/productotodailypackage.component";
import { ProductOtoDailyDetailComponent } from "./components/product/productoto/productotodaily/productotodailydetail/productotodailydetail.component";
import { AgreementComponent } from "./components/agreement/agreement.component";
import { ProductPersonalAccidentDailyDetailComponent } from "./components/product/productpersonalaccident/productpersonalaccidentdaily/productpersonalaccidentdailydetail/productpersonalaccidentdailydetail.component";
import { ProductPersonalAccidentYearlyDetailComponent } from "./components/product/productpersonalaccident/productpersonalaccidentyearly/productpersonalaccidentyearlydetail/productpersonalaccidentyearlydetail.component";
import { ProductPersonalAccidentYearlyPackageComponent } from "./components/product/productpersonalaccident/productpersonalaccidentyearly/productpersonalaccidentyearlypackage/productpersonalaccidentyearlypackage.component";
import { ProductPersonalAccidentMonthlyDetailComponent } from "./components/product/productpersonalaccident/productpersonalaccidentmonthly/productpersonalaccidentmonthlydetail/productpersonalaccidentmonthlydetail.component";
import { ProductASMIKDamageOfBusinessPlaceYearlyComponent } from "./components/productasmik/productasmikdamageofbusinessplace/productasmikdamageofbusinessplaceyearly/productasmikdamageofbusinessplaceyearly.component";
import { ProductASMIKMyHouseYearlyComponent } from "./components/productasmik/productasmikmyhouse/productasmikmyhouseyearly/productasmikmyhouseyearly.component";
import { ProductASMIKMyMotorcycleYearlyComponent } from "./components/productasmik/productasmikmymotorcycle/productasmikmymotorcycleyearly/productasmikmymotorcycleyearly.component";
import { ProductBicycleYearlyDetailComponent } from "./components/product/productbicycle/productbicycleyearly/productbicycleyearly.component";
import { ProductListComponent } from "./components/productlist/productlist.component";
import { MobileApplicationComponent } from "./components/mobileapplication/mobileapplication.component";
import { CartComponent } from "./components/cart/cart.component";
import { PromotionListComponent } from "./components/promotion/promotionlist/promotionlist.component";
import { PromotionDetailComponent } from "./components/promotion/promotiondetail/promotiondetail.component";
import { PartnerComponent } from "./components/partner/partner.component";
import { PolicylistComponent } from "./components/policy/policylist/policylist.component";
import { PolicydetailComponent } from "./components/policy/policydetail/policydetail.component";
import { ProductShophouseYearlyComponent } from "./components/product/productshophouse/productshophouseyearly/productshophouseyearly.component";
import { ProductApartmentYearlyComponent } from "./components/product/productapartment/productapartmentyearly/productapartmentyearly.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			TableControlIncludeComponent,
			TableControlIncludeMiniComponent,
			TableControlIncludePageComponent,
			LandingComponent,
			FooterComponent,
			ProductAsriMonthlyComponent,
			ProductAsriYearlyComponent,
			ProductOtoYearlyDetailComponent,
			ProductOtoDailyPackageComponent,
			ProductOtoDailyDetailComponent,
			ProductOtoMonthlyDetailComponent,
			HorizontalstepperIncludeComponent,
			PaymentComponent,
			CheckoutComponent,
			AgreementComponent,
			ProductPersonalAccidentYearlyPackageComponent,
			ProductPersonalAccidentDailyDetailComponent,
			ProductPersonalAccidentYearlyDetailComponent,
			ProductPersonalAccidentMonthlyDetailComponent,
			ProductBicycleYearlyDetailComponent,
			ProductShophouseYearlyComponent,
			ProductApartmentYearlyComponent,
			ProductASMIKDamageOfBusinessPlaceYearlyComponent,
			ProductASMIKMyHouseYearlyComponent,
			ProductASMIKMyMotorcycleYearlyComponent,
			ProductListComponent,
			MobileApplicationComponent,
			CartComponent,
			PromotionListComponent,
			PromotionDetailComponent,
			PartnerComponent,
			PolicylistComponent,
			PolicydetailComponent
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			ReactiveFormsModule,
			PipeModule,
			BrowserAnimationsModule,
			MatNativeDateModule,
			MatInputModule,
			MatSliderModule,
			MatDatepickerModule,
			MatCheckboxModule,
			MatCardModule,
			MatProgressBarModule,
			MatProgressSpinnerModule,
			ReactiveFormsModule,
			GoogleMapsModule,
			NgSelectModule,
			QuicklinkModule,
			NgxMaskModule.forRoot(),
			ServiceWorkerModule.register("ngsw-worker.js",
				{
					enabled: environment.serviceWorker,
					// Register the ServiceWorker as soon as the app is stable
					// or after 30 seconds (whichever comes first).
					registrationStrategy: "registerWhenStable:30000"
				}
			),
		],
		providers:
		[
			CurrencyPipe,
			DecimalPipe,
			GoogleMap,
			QuicklinkModule,
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			}
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion