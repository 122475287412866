//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { ENUM_DEVICE_TYPE, ENUM_HEADER_TYPE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { HeaderContentModel } from "src/app/models/headercontent.model";
import { MobileApplicationModel } from "src/app/models/mobileapplication.model";
import { MobileApplicationGalleryModel } from "src/app/models/mobileapplicationgallery.model";
import { ResponseModel } from "src/app/models/response.model";
import { HeaderService } from "src/app/services/header.service";
import { MobileapplicationService } from "src/app/services/mobileapplication.service";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-mobileapplication",
		templateUrl: "./mobileapplication.component.html",
		styleUrls: ["./mobileapplication.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class MobileApplicationComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelMobileApplication: MobileApplicationModel;
	public _modelMobileApplicationRequest: MobileApplicationModel;
	public _modelHeaderContent: HeaderContentModel;
	public _modelHeaderContentRequest: HeaderContentModel;

	public _arrayModelMobileApplication: Array<MobileApplicationModel>;

	public _stringConstant = StringConstant;
	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router, private _serviceMobileApplication: MobileapplicationService, private _serviceHeaderContent: HeaderService)
	{
		super(serviceSession, router);

		this._modelMobileApplication = new MobileApplicationModel();
		this._modelMobileApplicationRequest = new MobileApplicationModel();
		this._modelHeaderContent = new HeaderContentModel();
		this._modelHeaderContentRequest = new HeaderContentModel();

		this._arrayModelMobileApplication = [];

		// this._stringImageMainHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_aplikasi_insurtech.jpg");
		// this._stringImageMainHeaderMobile = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/insurtech/header_aplikasi_insurtech_mobile.jpg");
		this._stringImageMainHeader = "";
		this._stringImageMainHeaderMobile = "";
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		this.callSelectHeaderContentByAttributesForCustomer(this);
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}

	showImageFromURL(modelMobileApplication: MobileApplicationModel): string
	{
		if((modelMobileApplication.listModelMobileApplicationGallery != null || modelMobileApplication.listModelMobileApplicationGallery !== undefined) && modelMobileApplication.listModelMobileApplicationGallery?.length !== 0)
		{

			const modelMobileApplicationGalleryModel: MobileApplicationGalleryModel = modelMobileApplication.listModelMobileApplicationGallery[0];
			if(modelMobileApplicationGalleryModel.URL)
			{
				return this._pipeConvertToBroadcastURL.transform(modelMobileApplicationGalleryModel.URL);
			}
			else
			{
				return this._stringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return this._stringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	//#endregion


	//#region WEB SERVICES

	public callSelectHeaderContentByAttributesForCustomer(componentCurrent: MobileApplicationComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelHeaderContentRequest.DeviceType = ENUM_DEVICE_TYPE.Website;
		componentCurrent._modelHeaderContentRequest.HeaderType = ENUM_HEADER_TYPE.MobileApplication;

		componentCurrent._serviceHeaderContent.selectHeaderContentByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelHeaderContent.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelHeaderContent.listModelHeaderGallery !== undefined)
						{
							for (const modelHeaderContentGallery of componentCurrent._modelHeaderContent.listModelHeaderGallery)
							{
								modelHeaderContentGallery.URL = componentCurrent._pipeConvertToBroadcastURL.transform(modelHeaderContentGallery.URL);
								componentCurrent._stringImageMainHeader = modelHeaderContentGallery.URL;
								componentCurrent._stringImageMainHeaderMobile = modelHeaderContentGallery.URL;
							}
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent.callSelectMobileApplicationByAttributesForCustomer(componentCurrent);
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHeaderContentByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelHeaderContentRequest);
	}

	public callSelectMobileApplicationByAttributesForCustomer(componentCurrent: MobileApplicationComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._serviceMobileApplication.selectMobileApplicationByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._arrayModelMobileApplication = [];

						let arrayModelMobileApplicationTemp: Array<MobileApplicationModel> = [];
						arrayModelMobileApplicationTemp = JSON.parse(modelResponse.Data);

						let modelMobileApplication: MobileApplicationModel;
						for(const modelMobileApplicationTemp of arrayModelMobileApplicationTemp)
						{
							modelMobileApplication = new MobileApplicationModel();
							modelMobileApplication.setModelFromObject(modelMobileApplicationTemp);
							componentCurrent._arrayModelMobileApplication.push(modelMobileApplication);
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectMobileApplicationByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelMobileApplicationRequest);
	}

	//#endregion


	//#region NAVIGATION

	goToDownloadFromGooglePlay(stringURL: string | undefined)
	{
		window.open(stringURL, this._stringConstant.STRING_WINDOWOPEN_TARGET_BLANK);
	}

	goToDownloadFromAppStore(stringURL: string | undefined)
	{
		window.open(stringURL, this._stringConstant.STRING_WINDOWOPEN_TARGET_BLANK);
	}

	//#endregion

}
